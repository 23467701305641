import React, { useState } from "react";
import Select from "react-select";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { ArchiveIcon, CalendarIcon, CameraIcon, CheckIcon, ClipboardCheckIcon, ClockIcon, EyeIcon, PaperClipIcon, PlusIcon, SelectorIcon, ShareIcon, TagIcon, UserGroupIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Modal, Button, InputGroup, Image, Badge, FloatingLabel, Card } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from "recoil";
import { collaborazioneDisponibilitaCurrentItemAtom, dateDisponibilitaCurrentItemAtom, disponibilitaCurrentItemAtom, disponibilitaRefreshIncrementAtom, disponibilitaSearchAtom, myDisponibilitaDataAtom, showDisponibilitaDetailModalAtom, showUtilityDetailModalAtom, showUtilityDetailModalAtomCampLocation, tipoUtilityAtom, tipoUtilityAtomCamp, utilityItemAtom, utilityItemAtomCamp, utilityItemAtomCampLocation } from "data/atom";
import { annoGestioneSelector, listaAnagraficheDisponibilitaSelector, listaBusDisponibilitaSelector, listaCollaborazioniSelector, listaDateDisponibilitaSelector, listaDisciplineAltroDisponibilitaSelector, listaDisciplineMedicheDisponibilitaSelector, listaDisciplineSportiveDisponibilitaSelector, listaDisciplineTematicheDisponibilitaSelector, listaRuoliDisponibilitaSelector, listaTipoDisciplinaDisponibilitaSelector, listaTreniDisponibilitaSelector, locationSelectSelector } from "data/selectors";
import { actionUtilityDisponibilita } from "data/integrations";


export const CercaAnagraficaModal = (props) => {
    const [show, setShowDefault] = useRecoilState(showDisponibilitaDetailModalAtom);
    const [itemModal, setItemModal] = useRecoilState(disponibilitaCurrentItemAtom);
    const [itemModalDate, setItemModalDate] = useRecoilState(dateDisponibilitaCurrentItemAtom);
    const [itemModalCollaborazione, setItemModalCollaborazione] = useRecoilState(collaborazioneDisponibilitaCurrentItemAtom);
    const [itemModalRuolo, setItemModalRuolo] = useState(undefined);
    const [itemModalTipoDisciplina, setItemModalTipoDisciplina] = useState(undefined);
    const [itemModalDiscipline, setItemModalDiscipline] = useState(undefined);
    const [itemModalTrasportoBus, setItemModalTrasportoBus] = useState(undefined);
    const [itemModalTrasportoTreno, setItemModalTrasportoTreno] = useState(undefined);

    const anagraficheList = useRecoilValue(listaAnagraficheDisponibilitaSelector);
    const dateDisponibilitaList = useRecoilValue(listaDateDisponibilitaSelector);
    const listaCollaborazioni = useRecoilValue(listaCollaborazioniSelector);
    const annoGestione = useRecoilValue(annoGestioneSelector);
    const listaRuoli = useRecoilValue(listaRuoliDisponibilitaSelector);
    const listaTipoDiscipline = useRecoilValue(listaTipoDisciplinaDisponibilitaSelector);
    const listaDisciplineSportive = useRecoilValue(listaDisciplineSportiveDisponibilitaSelector);
    const listaDisciplineTematiche = useRecoilValue(listaDisciplineTematicheDisponibilitaSelector);
    const listaDisciplineSanitarie = useRecoilValue(listaDisciplineMedicheDisponibilitaSelector);
    const listaDisciplineNeutre = useRecoilValue(listaDisciplineAltroDisponibilitaSelector);
    const listaBus = useRecoilValue(listaBusDisponibilitaSelector);
    const listaTreni = useRecoilValue(listaTreniDisponibilitaSelector);

    const [disponibilitaRefresh, setDisponibilitaRefresh] = useRecoilState(disponibilitaRefreshIncrementAtom);

    const [searchValue, setSearchValue] = useRecoilState(disponibilitaSearchAtom);
    const [myDataAtom, setMyDataAtom] = useRecoilState(myDisponibilitaDataAtom);

    const onConfirm = async () => {
        var myObj = {};
        myObj['action'] = 'add_item';
        myObj['subject'] = 'disponibilita';
        var obj = {};
        obj['id_anagrafica'] = itemModal.value;
        obj['nominativo'] = itemModal.label;
        obj['collaborazione'] = itemModalCollaborazione.value;
        obj['ruolo'] = itemModalRuolo.value;
        obj['disciplina_tipo'] = itemModalTipoDisciplina.value;
        obj['disciplina'] = itemModalDiscipline.map(u => u.value).join(',');
        obj['trasporto_bus'] = itemModalTrasportoBus.value;
        obj['trasporto_treno'] = itemModalTrasportoTreno.value;
        obj['date_disponibilita'] = itemModalDate;
        myObj['obj'] = obj;

        await actionUtilityDisponibilita(myObj);
        var refs = disponibilitaRefresh;
        refs++;
        setDisponibilitaRefresh(refs);
        setMyDataAtom(undefined);
        setItemModal(undefined);
        setItemModalDate(undefined);
        setItemModalCollaborazione(undefined);
        setItemModalRuolo(undefined);
        setItemModalTipoDisciplina(undefined);
        setItemModalDiscipline(undefined);
        setItemModalTrasportoBus(undefined);
        setItemModalTrasportoTreno(undefined);
        setSearchValue("");
        setShowDefault(false);
    }

    const handleClose = () => {
        setItemModal(undefined);
        setItemModal(undefined);
        setItemModalDate(undefined);
        setItemModalCollaborazione(undefined);
        setItemModalRuolo(undefined);
        setItemModalTipoDisciplina(undefined);
        setItemModalDiscipline(undefined);
        setItemModalTrasportoBus(undefined);
        setItemModalTrasportoTreno(undefined);
        setShowDefault(false);
    }

    return (
        <Modal as={Modal.Dialog} centered show={show} onHide={handleClose} size="lg">
            <Modal.Header>
                <Modal.Title className="h6">Seleziona Anagrafica per Disponibilità</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
                <Card as="form" border="0" className="shadow">
                    <Card.Body>
                        <Row className="justify-content-center">
                            <Col xs={12} lg={12}>
                                <Form.Group className="mb-4">
                                    <Form.Label>Cerca Nominativo</Form.Label>
                                    <Select options={anagraficheList}
                                        onChange={(selectedOption) => {
                                            setItemModalDate(undefined);
                                            setItemModalCollaborazione(undefined);
                                            setItemModal(selectedOption);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xs={12} lg={12}>
                                {(itemModal !== undefined) && (dateDisponibilitaList.length > 0) ?

                                    <Form.Group className="mb-4">
                                        <Form.Label>Seleziona Periodo</Form.Label>
                                        <Select options={dateDisponibilitaList} isMulti
                                            onChange={(selectedOptions) => {
                                                if (selectedOptions.length > 0) {
                                                    setItemModalDate(selectedOptions);
                                                }
                                                else {
                                                    setItemModalDate(undefined);
                                                }
                                            }}
                                        />
                                    </Form.Group>
                                    :
                                    (itemModal !== undefined) ?
                                        <p><Badge bg="warning" text="dark" className="me-1" pill={true}>!</Badge>
                                            Tutte le date sono gi&agrave; state selzionate per questo nominativo</p>
                                        :
                                        <span>&nbsp;</span>
                                }
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xs={12} lg={6}>
                                <Form.Group className="mb-4" >
                                    <Form.Label>Seleziona Anno Inizio Collaborazione</Form.Label>
                                    <Select options={listaCollaborazioni}
                                        onChange={(selectedOption) => {
                                            setItemModalCollaborazione(selectedOption);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Group className="mb-4" >
                                    <Form.Label>Seleziona Ruolo</Form.Label>
                                    <Select options={listaRuoli}
                                        onChange={(selectedOption) => {
                                            setItemModalRuolo(selectedOption);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xs={12} lg={4}>
                                <Form.Group className="mb-4" >
                                    <Form.Label>Seleziona Tipo Disciplina</Form.Label>
                                    <Select options={listaTipoDiscipline}
                                        onChange={(selectedOption) => {
                                            setItemModalDiscipline(undefined);
                                            setItemModalTipoDisciplina(selectedOption);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={8}>
                                {
                                    (itemModalTipoDisciplina !== undefined && itemModalTipoDisciplina.value === 'Sportiva') ?
                                        <Form.Group className="mb-4" >
                                            <Form.Label>Seleziona Discipline Sportive</Form.Label>
                                            <Select options={listaDisciplineSportive} isMulti
                                                onChange={(selectedOptions) => {
                                                    if (selectedOptions.length > 0) {
                                                        setItemModalDiscipline(selectedOptions);
                                                    }
                                                    else {
                                                        setItemModalDiscipline([{ label: 'Non selezionato', value: 'Non selezionato' }]);
                                                    }
                                                }}
                                            />
                                        </Form.Group>
                                        :
                                        (itemModalTipoDisciplina !== undefined && itemModalTipoDisciplina.value === 'Tematica') ?
                                            <Form.Group className="mb-4" >
                                                <Form.Label>Seleziona Discipline Tematiche</Form.Label>
                                                <Select options={listaDisciplineTematiche} isMulti
                                                    onChange={(selectedOptions) => {
                                                        if (selectedOptions.length > 0) {
                                                            setItemModalDiscipline(selectedOptions);
                                                        }
                                                        else {
                                                            setItemModalDiscipline([{ label: 'Non selezionato', value: 'Non selezionato' }]);
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                            :
                                            (itemModalTipoDisciplina !== undefined && itemModalTipoDisciplina.value === 'Sanitaria') ?
                                                <Form.Group className="mb-4" >
                                                    <Form.Label>Seleziona Discipline Tematiche</Form.Label>
                                                    <Select options={listaDisciplineSanitarie} isMulti
                                                        onChange={(selectedOptions) => {
                                                            if (selectedOptions.length > 0) {
                                                                setItemModalDiscipline(selectedOptions);
                                                            }
                                                            else {
                                                                setItemModalDiscipline([{ label: 'Non selezionato', value: 'Non selezionato' }]);
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                                :
                                                (itemModalTipoDisciplina !== undefined && itemModalTipoDisciplina.value === 'Neutra') ?
                                                    <Form.Group className="mb-4" >
                                                        <Form.Label>Seleziona Discipline Tematiche</Form.Label>
                                                        <Select options={listaDisciplineNeutre} isMulti
                                                            onChange={(selectedOptions) => {
                                                                if (selectedOptions.length > 0) {
                                                                    setItemModalDiscipline(selectedOptions);
                                                                }
                                                                else {
                                                                    setItemModalDiscipline([{ label: 'Non selezionato', value: 'Non selezionato' }]);
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    :
                                                    null
                                }
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xs={12} lg={6}>
                                <Form.Group className="mb-4" >
                                    <Form.Label>Seleziona Trasporto Bus</Form.Label>
                                    <Select options={listaBus}
                                        onChange={(selectedOption) => {
                                            setItemModalTrasportoBus(selectedOption);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Group className="mb-4" >
                                    <Form.Label>Seleziona Trasporto treno</Form.Label>
                                    <Select options={listaTreni}
                                        onChange={(selectedOption) => {
                                            setItemModalTrasportoTreno(selectedOption);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" className="me-2" onClick={onConfirm}
                    disabled={itemModal === undefined || itemModalDate === undefined || itemModalCollaborazione === undefined || itemModalRuolo === undefined
                        || itemModalTipoDisciplina === undefined || itemModalDiscipline === undefined || itemModalTrasportoBus === undefined || itemModalTrasportoTreno === undefined}
                >
                    Seleziona
                </Button>
            </Modal.Footer>
        </Modal >
    );
};