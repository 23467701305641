import React from "react";
import Select from "react-select";
import 'moment/locale/it';
import { Col, Row, Form, Modal, Button } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from "recoil";
import { selectedLocationTrasportoTrenoAtom, showUtilityDetailModalAtomLocationTrasportoTreno, utilityItemAtomLocationTrasporto } from "data/atom";
import { listaTreniLocationSelector } from "data/selectors";
import { estraiCampByData } from "data/integrations";


export const DetailsLocationTreno = (props) => {
    const [item, setItem] = useRecoilState(utilityItemAtomLocationTrasporto);
    const [show, setShowDefault] = useRecoilState(showUtilityDetailModalAtomLocationTrasportoTreno);
    const [trasportoLocation, setTrasportoLocation] = useRecoilState(selectedLocationTrasportoTrenoAtom);

    const trasportiSelector = useRecoilValue(listaTreniLocationSelector);

    const { edit = false, title } = props;

    const onConfirm = () => {

        const payload = JSON.parse(JSON.stringify(item));

        return props.onUpdateLocationTreno && props.onUpdateLocationTreno(payload);

    }


    const handleClose = () => setShowDefault(false);


    return (
        <Modal as={Modal.Dialog} centered show={show} onHide={handleClose} size="lg">
            <Modal.Header>
                <Modal.Title className="h6">{title}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Form className="modal-content">
                <Modal.Body>
                    {(item['trasporto'] !== undefined) ?
                        <Row className="justify-content-center">
                            <Col xs={12} lg={12}>
                                <Form.Group className="mb-4">
                                    <Form.Label>Seleziona una Stazione</Form.Label>
                                    <Select options={trasportiSelector} isMulti
                                        value={(item['trasporto'] !== undefined) ? item['trasporto'] : []}
                                        onChange={(selectedOption) => {
                                            let objTemp = JSON.parse(JSON.stringify(item));
                                            if (objTemp['trasporto'] === undefined) {
                                                objTemp['trasporto'] = [];
                                            }
                                            objTemp['trasporto'] = selectedOption;
                                            setTrasportoLocation(selectedOption.value);
                                            setItem(objTemp);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        :
                        null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="me-2" onClick={onConfirm}
                        disabled={item['id_location'] === undefined || item['trasporto'] === undefined}
                    >
                        Salva
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};