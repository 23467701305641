
import React, { useState } from "react";
import { ArrowLeftIcon, ArrowNarrowDownIcon, ArrowNarrowUpIcon, ArrowRightIcon, CheckCircleIcon, ChevronDownIcon, ChevronUpIcon, ClockIcon, DotsHorizontalIcon, ExternalLinkIcon, EyeIcon, FolderOpenIcon, InformationCircleIcon, MailIcon, PencilAltIcon, QuestionMarkCircleIcon, ShieldExclamationIcon, TrashIcon, UserRemoveIcon, UsersIcon, XCircleIcon } from "@heroicons/react/solid";
import { Col, Row, Nav, Card, Form, Image, Button, Table, Dropdown, ProgressBar, Pagination, Tooltip, FormCheck, ButtonGroup, OverlayTrigger, Badge } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from "recoil";
import Swal from 'sweetalert2';
import { eliminaConferme, estraiAnagrafiche, estraiCampByData, estraiCampByLocationAndData, estraiDisponibilita, generaPDF } from "data/integrations";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { Routes } from "routes";
import { confermeSelector, listaBusConfermeSelector, listaBusDisponibilitaSelector, listaConfermeSelector, listaDisponibilitaSelector, listaLocationSelector, listaRuoliConfermeSelector, listaRuoliDisponibilitaSelector, listaStatiConfermeSelector, listaTrasportoTipoAndataConfermeSelector, listaTrasportoTipoConfermeSelector, listaTrasportoTipoRitornoConfermeSelector, listaTreniConfermeSelector, listaTreniDisponibilitaSelector, loginUserSelector } from "data/selectors";
import Select from "react-select";
import { anagraficheCurrentItemAtom, confermeNoteModalItemAtom, confermeOrderItemAtom, confermeOrderItemTypeAtom, confermeRefreshIncrementAtom, confermeRicercaObjAtom, confermeSearchAtom, confermeStatoTypeAtom, currentPageConfermeAtom, disponibilitaDettaglioModalAtom, disponibilitaNoteModalItemAtom, formAnagraficaProvenienza, myConfermeDataAtom, rowsPerPageConfermeAtom, showDisponibilitaModalAtom, showNoteConfermeModalAtom, showNoteDisponibilitaModalAtom } from "data/atom";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import { DetailsDisponibilitaModal } from "pages/disponibilita/DetailsDisponibilita";
import { NoteConfermeModal } from "./NoteConferme";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary me-3',
        cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));


export const TableConferme = (props) => {
    const { mydata = [], allSelected } = props;
    const [bulkOption, setBulkOption] = useState('delete_user');
    const [statoOption, setStatoOption] = useRecoilState(confermeStatoTypeAtom);
    const [orderOption, setOrderOption] = useRecoilState(confermeOrderItemAtom);
    const [orderOptionType, setOrderOptionType] = useRecoilState(confermeOrderItemTypeAtom);
    const disabledBulkMenu = mydata.filter(u => u.isSelected).length === 0;
    const myUsers = useRecoilValue(listaConfermeSelector).filter(c => (statoOption === "T") ? true : (parseInt(c.stato) === parseInt(statoOption)));
    const [currentPage, setCurrentPage] = useRecoilState(currentPageConfermeAtom);
    let [rowsPerPage, setRowsPerPage] = useRecoilState(rowsPerPageConfermeAtom);
    const [confermeRefresh, setConfermeRefresh] = useRecoilState(confermeRefreshIncrementAtom);
    const [myDataAtom, setMyDataAtom] = useRecoilState(myConfermeDataAtom);
    const [searchValue, setSearchValue] = useRecoilState(confermeSearchAtom);
    const [provenienza, setProvenienza] = useRecoilState(formAnagraficaProvenienza);
    const [itemModalAnagrafica, setItemModalAnagrafica] = useRecoilState(anagraficheCurrentItemAtom);
    const [show, setShowDefault] = useRecoilState(showDisponibilitaModalAtom);
    const [item, setItem] = useRecoilState(disponibilitaDettaglioModalAtom);

    const listaRuoli = useRecoilValue(listaRuoliConfermeSelector);
    const listaStati = useRecoilValue(listaStatiConfermeSelector);
    const listaTipoTrasportoAndata = useRecoilValue(listaTrasportoTipoAndataConfermeSelector);
    const listaTipoTrasportoRitorno = useRecoilValue(listaTrasportoTipoRitornoConfermeSelector);
    const listaBus = useRecoilValue(listaBusConfermeSelector);
    const listaTreni = useRecoilValue(listaTreniConfermeSelector);

    const listaLocation = useRecoilValue(listaLocationSelector);

    const [itemSearch, setItemSearch] = useRecoilState(confermeRicercaObjAtom);

    const [showNote, setShowNoteModal] = useRecoilState(showNoteConfermeModalAtom);
    const [itemNoteModal, setItemNoteModal] = useRecoilState(confermeNoteModalItemAtom);
    const [myLoginUser, setLoginUser] = useRecoilState(loginUserSelector);
    let [users, setUsers] = useRecoilState(confermeSelector);

    function quantiUsers() {
        if (searchValue.length === 0) {
            var quanti = myUsers.length;
            return quanti;
        }
        else {
            var newUsers = myUsers.filter(u => (u.nominativo.toLowerCase().includes(searchValue.toLowerCase())));
            return newUsers.length;
        }
    }

    const onUpdateItem = (obj) => {
        props.onUpdateItem && props.onUpdateItem(obj);
    };

    const selectUser = (id) => {
        props.selectUser && props.selectUser(id);
    };

    const selectAllUsers = () => {
        props.selectAllUsers && props.selectAllUsers();
    };

    const bulkActionChange = (e) => {
        const newOption = e.target.value;
        setBulkOption(newOption);
    }

    const orderActionChange = (e) => {
        const newOption = e.target.value;
        setOrderOption(newOption);
    }

    const applyBulkAction = async () => {
        if (bulkOption === "delete_user") deleteUsers();
        if (bulkOption === "genera_pdf") {
            var elems = mydata.filter(u => u.isSelected);
            //verifica id_anag siano identici
            var ok = true;
            if (elems.length > 1) {
                for (var i = 1; i < elems.length; i++) {
                    if (elems[i]['id_anagrafica'] !== elems[i - 1]['id_anagrafica']) {
                        ok = false;
                        break;
                    }
                }
            }
            if (!ok) {
                await SwalWithBootstrapButtons.fire('Generazione PDF Conferme', "Impossibile generare il PDF poichè sono stati selezionati nominativi diversi.", 'warning');
                return;
            }
            var ids = elems.map(u => u.id).join(',');
            window.open('/backend/creaPdfConferme.php?ids=' + ids, '_blank');
        }
    }

    const deleteUsers = (ids) => {
        props.deleteUsers && props.deleteUsers(ids)
    }



    const TableRow = (props) => {
        const { id, nominativo, id_anagrafica, id_disponibilita, date_disponibilita, isSelected, stato, ruolo, registrazione_crm, registrazione_tabella, rimborso, compenso, rimborso_quota, andata_tipo_trasporto, ritorno_tipo_trasporto, andata_trasporto, ritorno_trasporto, trasporto_bus, trasporto_treno, autonomo_testo, andata_responsabile_trasporto, ritorno_responsabile_trasporto, location, camp, ruolo_dettaglio, note, collaborazione } = props;
        const [compensoText, setCompensoText] = useState(compenso);
        const [ruoloDettaglioText, setRuoloDettaglioText] = useState(ruolo_dettaglio);
        const [rimborsoText, setRimborsoText] = useState(rimborso_quota);
        const locationsList = () => {
            var list = [];
            list.push({ value: 'Non selezionato', label: 'Non selezionato', id_location: -1 });

            for (let i = 0; i < listaLocation.length; i++) {
                var presente = false;
                for (let j = 0; j < listaLocation[i].date_location.length; j++) {
                    var d = listaLocation[i].date_location[j].inizio + ' - ' + listaLocation[i].date_location[j].fine
                    if (d === date_disponibilita) {
                        if (list.length > 0) {
                            presente = list.filter(l => l.value === listaLocation[i].desrizione).length > 0;
                            if (!presente) {
                                list.push({ value: listaLocation[i].descrizione, label: listaLocation[i].descrizione, id_location: listaLocation[i].id });
                            }
                        }
                        else {
                            list.push({ value: listaLocation[i].descrizione, label: listaLocation[i].descrizione, id_location: listaLocation[i].id });
                        }
                    }
                }
            }
            return list;
        };

        const campList = () => {
            var list = [];
            list.push({ value: 'Non selezionato', label: 'Non selezionato', id_camp: -1 });

            if (location !== 'Non selezionato') {
                var myLocation = listaLocation.filter(l => l.descrizione === location)[0];
                for (let j = 0; j < myLocation.camp_location.length; j++) {
                    if (myLocation.camp_location[j].data_camp === date_disponibilita) {
                        list.push({ value: myLocation.camp_location[j].descrizione, label: myLocation.camp_location[j].descrizione, id_camp: myLocation.camp_location[j].id });
                    }
                }
            }
            return list;
        };

        const autonomoList = () => {
            var list = [];
            list.push({ value: 'Non selezionato', label: 'Non selezionato', id_camp: -1 });

            if (autonomo_testo !== ' ' && autonomo_testo !== '' && autonomo_testo !== undefined) {
                list.push({ value: autonomo_testo, label: autonomo_testo });
            }
            return list;
        };

        const tooltip_anagrafica = (
            <Tooltip id="tooltip">
                <strong>Clicca per visualizzare la scheda anagrafica di {nominativo}</strong>
            </Tooltip>
        );

        const tooltip_disponibilita = (
            <Tooltip id="tooltip">
                <strong>Clicca per visualizzare il dettaglio della disponibilit&agrave; di {nominativo} per {date_disponibilita}</strong>
            </Tooltip>
        );

        return (
            <tr className="border-bottom"
                disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}>
                <td>
                    <FormCheck type="checkbox" className="dashboard-check">
                        <FormCheck.Input id={`user-${id}`} checked={isSelected === true} onChange={() => selectUser(id)} />
                        <FormCheck.Label htmlFor={`user-${id}`} />
                    </FormCheck>
                </td>
                <td>

                    <Dropdown drop="down" as={ButtonGroup} className="me-2 mb-2">
                        {
                            (stato === 0) ?
                                <Button variant="yellow">
                                    <QuestionMarkCircleIcon className="icon icon-xs" />
                                </Button>
                                :
                                (stato === 1) ?
                                    <Button variant="success">
                                        <CheckCircleIcon className="icon icon-xs" />
                                    </Button>
                                    :
                                    (stato === 2) ?
                                        <Button variant="dark">
                                            <TrashIcon className="icon icon-xs" />
                                        </Button>
                                        :
                                        (stato === 3) ?
                                            <Button variant="orange">
                                                <ClockIcon className="icon icon-xs" />
                                            </Button>
                                            :
                                            null
                        }

                        <Dropdown.Toggle split variant={(stato === 0) ? "yellow" : (stato === 1) ? "success" : (stato === 2) ? "dark" : (stato === 3) ? "orange" : "primary"}>
                            <ChevronDownIcon className="icon icon-xs" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                            isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                        >
                            <Dropdown.Item
                                isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                style={{ "backgroundColor": "#FFD700" }} onClick={() => {
                                    var obj = {
                                        item_id: id,
                                        key: 'stato',
                                        value: 0
                                    }
                                    onUpdateItem(obj);
                                }}><QuestionMarkCircleIcon className="icon icon-xs" /> Proposto</Dropdown.Item>
                            <Dropdown.Item
                                isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                style={{ "backgroundColor": "#10B981" }} onClick={() => {
                                    var obj = {
                                        item_id: id,
                                        key: 'stato',
                                        value: 1
                                    }
                                    onUpdateItem(obj);
                                }}><CheckCircleIcon className="icon icon-xs" /> Accettato</Dropdown.Item>
                            <Dropdown.Item
                                isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                style={{ "backgroundColor": "#111827", color: "white" }} onClick={() => {
                                    var obj = {
                                        item_id: id,
                                        key: 'stato',
                                        value: 2
                                    }
                                    onUpdateItem(obj);
                                }}><TrashIcon className="icon icon-xs" /> Rifiutato</Dropdown.Item>
                            <Dropdown.Item
                                isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                style={{ "backgroundColor": "#FBA918" }} onClick={() => {
                                    var obj = {
                                        item_id: id,
                                        key: 'stato',
                                        value: 3
                                    }
                                    onUpdateItem(obj);
                                }}><ClockIcon className="icon icon-xs" /> Stand-By</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
                <td>
                    <span className="fw-normal">{nominativo}</span>
                    {(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                        <OverlayTrigger placement="top" overlay={tooltip_anagrafica}>
                            <Button
                                disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                as={Link} to={Routes.FormAnagrafica.path} variant="soft" size="xs" onClick={async () => {
                                    var myItemModal = {};
                                    var mydataAnag = await estraiAnagrafiche();
                                    mydataAnag = mydataAnag.obj;
                                    myItemModal = mydataAnag.filter(u => u.id === id_anagrafica)[0];
                                    setProvenienza("C");
                                    setItemModalAnagrafica(myItemModal);
                                }} >
                                <UsersIcon variant="info" className="icon icon-xs" style={{ "cursor": "pointer" }} />
                            </Button>
                        </OverlayTrigger>
                        :
                        null
                    }
                    <OverlayTrigger placement="top" overlay={tooltip_disponibilita}>
                        <Button variant="soft" size="xs" onClick={async () => {
                            var myItemModal = {};
                            var mydataDisp = await estraiDisponibilita();
                            mydataDisp = mydataDisp.obj;
                            myItemModal = mydataDisp.filter(u => u.id === id_disponibilita)[0];
                            setItem(myItemModal);
                            setShowDefault(true);
                        }}>
                            <InformationCircleIcon variant="info" className="icon icon-xs" style={{ "cursor": "pointer" }} />
                        </Button>
                    </OverlayTrigger>
                </td>
                <td>
                    <span className="fw-normal">{collaborazione}</span>
                </td>
                <td>
                    <span className="fw-normal">{date_disponibilita}</span>
                </td>
                <td>
                    <Form.Group className="m-0" style={{ width: `${(8 * "Non selezionato".length) + 100}px` }}
                        disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                    >
                        <Select options={locationsList()}
                            isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            defaultValue={{ label: location, value: location }}
                            onChange={(selectedOption) => {
                                console.log(selectedOption);
                                var obj = {
                                    item_id: id,
                                    key: 'location',
                                    value: selectedOption.value
                                }
                                onUpdateItem(obj);
                            }}
                        />
                    </Form.Group>
                </td>
                <td>
                    {
                        (location !== 'Non selezionato') ?
                            <Form.Group className="m-0" style={{ width: `${(8 * "Non selezionato".length) + 100}px` }}
                                disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            >
                                <Select options={campList()}
                                    isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                    defaultValue={{ label: camp, value: camp }}
                                    onChange={(selectedOption) => {
                                        console.log(selectedOption);
                                        var obj = {
                                            item_id: id,
                                            key: 'camp',
                                            value: selectedOption.value
                                        }
                                        onUpdateItem(obj);
                                    }}
                                />
                            </Form.Group>
                            :
                            <span>&nbsp;</span>
                    }
                </td>
                <td>
                    <Form.Group className="mb-3"
                        disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                    >
                        <Form.Check
                            disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            type="switch"
                            id={"switchCrm_" + id}
                            htmlFor={"switchCrm_" + id}
                            checked={(registrazione_crm === 'S')}
                            label={(registrazione_crm === 'S') ? 'Reg. CRM' : 'No CRM'}
                            onChange={(e) => {
                                var obj = {
                                    item_id: id,
                                    key: 'registrazione_crm',
                                    value: 'N'
                                }
                                if (e.target.checked) {
                                    obj.value = 'S';
                                }
                                else {
                                    obj.value = 'N';
                                }

                                onUpdateItem(obj);
                            }}
                        />
                        <Form.Check
                            disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            type="switch"
                            id={"switchTabella_" + id}
                            htmlFor={"switchtabella_" + id}
                            checked={(registrazione_tabella === 'S')}
                            label={(registrazione_tabella === 'S') ? 'Reg. Tabella' : 'No Tabella'}
                            onChange={(e) => {
                                var obj = {
                                    item_id: id,
                                    key: 'registrazione_tabella',
                                    value: 'N'
                                }
                                if (e.target.checked) {
                                    obj.value = 'S';
                                }
                                else {
                                    obj.value = 'N';
                                }

                                onUpdateItem(obj);
                            }}
                        />
                    </Form.Group>
                </td>
                <td>
                    <Form.Group className="m-0" style={{ width: `${(8 * ruolo.length) + 100}px` }}
                        disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                    >
                        <Select options={listaRuoli}
                            isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            defaultValue={{ label: ruolo, value: ruolo }}
                            onChange={(selectedOption) => {
                                var obj = {
                                    item_id: id,
                                    key: 'ruolo',
                                    value: selectedOption.value
                                }
                                onUpdateItem(obj);
                            }}
                        />
                    </Form.Group>
                    <Form.Group id="ruolo_dettaglio" style={{ minWidth: `${(8 * "ruolo_dettaglio_conferma".length) + 50}px` }}
                        disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                    >
                        <Form.Control
                            disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            type="text"
                            value={ruoloDettaglioText}
                            onChange={(e) => {
                                setRuoloDettaglioText(e.target.value);
                            }}
                            onBlur={(e) => {
                                var obj = {
                                    item_id: id,
                                    key: 'ruolo_dettaglio',
                                    value: ruoloDettaglioText
                                }
                                onUpdateItem(obj);
                            }} />
                    </Form.Group>
                </td>
                <td>
                    <Form.Group id="compenso_testo" className="mb-4" style={{ width: `${(8 * "1000".length) + 50}px` }}
                        disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                    >
                        <Form.Control
                            type="number"
                            disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            value={compensoText}
                            onChange={(e) => {
                                setCompensoText(e.target.value);
                            }}
                            onBlur={(e) => {
                                var obj = {
                                    item_id: id,
                                    key: 'compenso',
                                    value: compensoText
                                }
                                onUpdateItem(obj);
                            }} />
                    </Form.Group>
                </td>
                <td>
                    <Form.Group className="mb-3"
                        disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                    >
                        <Form.Check
                            disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            type="switch"
                            id={"switchRimborso_" + id}
                            htmlFor={"switchRimborso_" + id}
                            checked={(rimborso !== 'N')}
                            onChange={(e) => {
                                var obj = {
                                    item_id: id,
                                    key: 'rimborso',
                                    value: 'N'
                                }
                                if (e.target.checked) {
                                    obj.value = 'S';
                                }
                                else {
                                    obj.value = 'N';
                                }

                                onUpdateItem(obj);
                            }}
                        />
                    </Form.Group>
                    {
                        (rimborso === 'S') ?
                            <Form.Group id="rimborso_testo" style={{ width: `${(8 * "1000".length) + 50}px` }}
                                disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            >
                                <Form.Control
                                    disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                    type="number"
                                    value={rimborsoText}
                                    onChange={(e) => {
                                        setRimborsoText(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        var obj = {
                                            item_id: id,
                                            key: 'rimborso_quota',
                                            value: rimborsoText
                                        }
                                        onUpdateItem(obj);
                                    }} />
                            </Form.Group>
                            :
                            <span>&nbsp;</span>
                    }
                </td>
                <td>
                    <Form.Group className="m-0" style={{ width: `${(8 * andata_tipo_trasporto.length) + 100}px` }}
                        disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                    >
                        <Select options={listaTipoTrasportoAndata}
                            isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            defaultValue={{ label: andata_tipo_trasporto, value: andata_tipo_trasporto }}
                            onChange={(selectedOption) => {
                                var obj = {
                                    item_id: id,
                                    key: 'andata_tipo_trasporto',
                                    value: selectedOption.value,
                                    bus: (trasporto_bus === '' || trasporto_bus === undefined) ? 'Non selezionato' : trasporto_bus,
                                    treno: (trasporto_treno === '' || trasporto_treno === undefined) ? 'Non selezionato' : trasporto_treno,
                                    autonomo: (autonomo_testo === '' || autonomo_testo === ' ' || autonomo_testo === undefined) ? 'Non selezionato' : autonomo_testo
                                }
                                onUpdateItem(obj);
                            }}
                        />
                    </Form.Group>
                    {
                        (andata_tipo_trasporto === 'Bus') ?
                            <Form.Group className="m-0" style={{ width: `${(8 * andata_trasporto.length) + 100}px` }}
                                disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            >
                                <Select options={listaBus}
                                    isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                    defaultValue={{ label: trasporto_bus, value: trasporto_bus }}
                                    value={{ label: andata_trasporto, value: andata_trasporto }}
                                    onChange={(selectedOption) => {
                                        var obj = {
                                            item_id: id,
                                            key: 'andata_trasporto',
                                            value: selectedOption.value
                                        }
                                        onUpdateItem(obj);
                                    }}
                                />
                            </Form.Group>
                            :
                            (andata_tipo_trasporto === 'Treno') ?
                                <Form.Group className="m-0" style={{ width: `${(8 * andata_trasporto.length) + 100}px` }}
                                    disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                >
                                    <Select options={listaTreni}
                                        isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                        defaultValue={{ label: trasporto_treno, value: trasporto_treno }}
                                        value={{ label: andata_trasporto, value: andata_trasporto }}
                                        onChange={(selectedOption) => {
                                            var obj = {
                                                item_id: id,
                                                key: 'andata_trasporto',
                                                value: selectedOption.value
                                            }
                                            onUpdateItem(obj);
                                        }}
                                    />
                                </Form.Group>
                                :
                                (andata_tipo_trasporto === 'Autonomo') ?
                                    <Form.Group className="m-0" style={{ width: `${(8 * andata_trasporto.length) + 100}px` }}
                                        disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                    >
                                        <Select options={autonomoList()}
                                            isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                            defaultValue={{ label: autonomo_testo, value: autonomo_testo }}
                                            value={{ label: andata_trasporto, value: andata_trasporto }}
                                            onChange={(selectedOption) => {
                                                var obj = {
                                                    item_id: id,
                                                    key: 'andata_trasporto',
                                                    value: selectedOption.value
                                                }
                                                onUpdateItem(obj);
                                            }}
                                        />
                                    </Form.Group>
                                    :
                                    null
                    }
                </td>
                <td>
                    <Form.Group className="m-0" style={{ width: `${(8 * ritorno_tipo_trasporto.length) + 100}px` }}
                        disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                    >
                        <Select options={listaTipoTrasportoRitorno}
                            isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            defaultValue={{ label: ritorno_tipo_trasporto, value: ritorno_tipo_trasporto }}
                            onChange={(selectedOption) => {
                                var obj = {
                                    item_id: id,
                                    key: 'ritorno_tipo_trasporto',
                                    value: selectedOption.value,
                                    bus: (trasporto_bus === '' || trasporto_bus === undefined) ? 'Non selezionato' : trasporto_bus,
                                    treno: (trasporto_treno === '' || trasporto_treno === undefined) ? 'Non selezionato' : trasporto_treno,
                                    autonomo: (autonomo_testo === '' || autonomo_testo === ' ' || autonomo_testo === undefined) ? 'Non selezionato' : autonomo_testo
                                }
                                onUpdateItem(obj);
                            }}
                        />
                    </Form.Group>
                    {
                        (ritorno_tipo_trasporto === 'Bus') ?
                            <Form.Group className="m-0" style={{ width: `${(8 * ritorno_trasporto.length) + 100}px` }}
                                disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            >
                                <Select options={listaBus}
                                    isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                    defaultValue={{ label: trasporto_bus, value: trasporto_bus }}
                                    value={{ label: ritorno_trasporto, value: ritorno_trasporto }}
                                    onChange={(selectedOption) => {
                                        var obj = {
                                            item_id: id,
                                            key: 'ritorno_trasporto',
                                            value: selectedOption.value
                                        }
                                        onUpdateItem(obj);
                                    }}
                                />
                            </Form.Group>
                            :
                            (ritorno_tipo_trasporto === 'Treno') ?
                                <Form.Group className="m-0" style={{ width: `${(8 * ritorno_trasporto.length) + 100}px` }}
                                    disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                >
                                    <Select options={listaTreni}
                                        isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                        defaultValue={{ label: trasporto_treno, value: trasporto_treno }}
                                        value={{ label: ritorno_trasporto, value: ritorno_trasporto }}
                                        onChange={(selectedOption) => {
                                            var obj = {
                                                item_id: id,
                                                key: 'ritorno_trasporto',
                                                value: selectedOption.value
                                            }
                                            onUpdateItem(obj);
                                        }}
                                    />
                                </Form.Group>
                                :
                                (ritorno_trasporto === 'Autonomo') ?
                                    <Form.Group className="m-0" style={{ width: `${(8 * ritorno_trasporto.length) + 100}px` }}
                                        disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                    >
                                        <Select options={autonomoList()}
                                            isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                            defaultValue={{ label: autonomo_testo, value: autonomo_testo }}
                                            value={{ label: ritorno_trasporto, value: ritorno_trasporto }}
                                            onChange={(selectedOption) => {
                                                var obj = {
                                                    item_id: id,
                                                    key: 'ritorno_trasporto',
                                                    value: selectedOption.value
                                                }
                                                onUpdateItem(obj);
                                            }}
                                        />
                                    </Form.Group>
                                    :
                                    null
                    }
                </td>
                <td>
                    <Form.Group className="mb-3"
                        disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                    >
                        <Form.Check
                            disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            type="switch"
                            id={"switchART_" + id}
                            htmlFor={"switchART_" + id}
                            label="Responsabile Trasporto Andata"
                            className="small"
                            checked={(andata_responsabile_trasporto === 'S')}
                            onChange={(e) => {
                                var obj = {
                                    item_id: id,
                                    key: 'andata_responsabile_trasporto',
                                    value: 'N'
                                }
                                if (e.target.checked) {
                                    obj.value = 'S';
                                }
                                else {
                                    obj.value = 'N';
                                }

                                onUpdateItem(obj);
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3"
                        disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                    >
                        <Form.Check
                            disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            type="switch"
                            label="Responsabile Trasporto Ritorno"
                            id={"switchRRT_" + id}
                            htmlFor={"switchRRT_" + id}
                            className="small"
                            checked={(ritorno_responsabile_trasporto === 'S')}
                            onChange={(e) => {
                                var obj = {
                                    item_id: id,
                                    key: 'ritorno_responsabile_trasporto',
                                    value: 'N'
                                }
                                if (e.target.checked) {
                                    obj.value = 'S';
                                }
                                else {
                                    obj.value = 'N';
                                }

                                onUpdateItem(obj);
                            }}
                        />
                    </Form.Group>
                </td>
                {(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                    <td>
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                <DotsHorizontalIcon className="icon icon-xs icon-dark" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="py-0">
                                <Dropdown.Item onClick={() => {
                                    setItemNoteModal({
                                        item_id: id,
                                        key: 'note',
                                        value: note,
                                        title: 'Note per Conferme ' + nominativo + ' (' + date_disponibilita + ')'
                                    });
                                    setShowNoteModal(true);
                                }}>
                                    <PencilAltIcon className="icon icon-xs me-2" /> Note Conferme
                                </Dropdown.Item>
                                <Dropdown.Item onClick={async () => {
                                    const result = await SwalWithBootstrapButtons.fire({
                                        icon: "error",
                                        title: "Conferma Eliminazione",
                                        text: "Sei sicuro di voler eliminare l'elemento con ID: " + id + " e Nominativo: " + nominativo,
                                        showCancelButton: true,
                                        confirmButtonText: "Si",
                                        cancelButtonText: "Annulla"
                                    });

                                    if (result.isConfirmed) {
                                        var myObj = {};
                                        myObj['action'] = 'delete_item';
                                        myObj['obj'] = {};
                                        myObj['obj']['id'] = id;
                                        await eliminaConferme(myObj);
                                        var refs = confermeRefresh;
                                        refs++;
                                        setConfermeRefresh(refs);
                                        setMyDataAtom(undefined);
                                        setSearchValue("");
                                        const confirmMessage = "L'elemento ID: " + id + " è stato eliminato con successo";

                                        await SwalWithBootstrapButtons.fire('Eliminazione completata', confirmMessage, 'success');
                                    }
                                }}>
                                    <TrashIcon className="icon icon-xs text-danger me-2" /> Rimuovi
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                    :
                    null
                }
            </tr >
        );
    };

    const listPages = () => {
        var pageArrays = [];

        for (var i = 0; i < (Math.ceil(quantiUsers() / rowsPerPage)); i++) {
            pageArrays.push(<option value={i} key={'keyPage' + i}>{i + 1}</option>);
        }

        return pageArrays;
    }

    const setRicercaItem = (key, value) => {
        var tempJson = JSON.parse(JSON.stringify(itemSearch));
        tempJson[key] = value;
        setItemSearch(tempJson);

        var newUsers = JSON.parse(JSON.stringify(users));
        newUsers = users.map(u => ({ ...u, isSelected: false }));
        setUsers(newUsers);
    };

    return (
        <Card border="0" className="table-wrapper table-responsive shadow fmxh-400">
            <Card.Body>
                <div className="d-flex mb-3">
                    {
                        (myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                            <Form.Select className="fmxw-200" disabled={disabledBulkMenu} value={bulkOption} onChange={bulkActionChange}>
                                <option value="delete_user" key="delete_user">Cancella Selezionati</option>
                                <option value="genera_pdf" key="genera_pdf">Genera PDF Conferme</option>
                            </Form.Select>
                            :
                            null
                    }
                    {
                        (myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                            <Button variant="info" size="sm" className="ms-3" disabled={disabledBulkMenu} onClick={applyBulkAction}>
                                Applica
                            </Button>
                            :
                            null
                    }
                    <Form.Select className="fmxw-200 ms-3" disabled={mydata.length === 0} value={orderOption} onChange={orderActionChange}>
                        <option value="nominativo">Ordina per Nominativo</option>
                        <option value="dateOrdinamento">Ordina per Periodo Disponibilit&agrave;</option>
                    </Form.Select>
                    <Form.Check
                        type="switch"
                        className="fmxw-200 ms-3 small"
                        label={(orderOptionType === 'asc') ? 'Ordine A-Z' : 'Ordine Z-A'}
                        id="switchOrderType"
                        htmlFor="switchOrderType"
                        checked={orderOptionType === 'asc'}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setOrderOptionType('asc');
                            }
                            else {
                                setOrderOptionType('desc');
                            }
                        }}
                    />
                    <Form.Select className="fmxw-200 ms-3" value={statoOption} onChange={(e) => { setStatoOption(e.target.value) }}>
                        <option value="T">Tutti gli stati</option>
                        <option value="0">Proposto</option>
                        <option value="1">Accettato</option>
                        <option value="2">Rifiutato</option>
                        <option value="3">Stand-By</option>
                    </Form.Select>
                </div>
                <Table hover className="user-table align-items-left">
                    <thead>
                        <tr>
                            <th className="border-bottom">
                                <FormCheck type="checkbox" className="dashboard-check">
                                    <FormCheck.Input id="userCheckAll" checked={allSelected} onChange={selectAllUsers} />
                                    <FormCheck.Label htmlFor="userCheckAll" />
                                </FormCheck>
                            </th>
                            <th className="border-bottom">Stato</th>
                            <th className="border-bottom">Nominativo
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('nominativo', e.target.value);
                                    }}
                                    value={itemSearch['nominativo']} /></th>
                            <th className="border-bottom">Collaborazione
                            <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('collaborazione', e.target.value);
                                    }}
                                    value={itemSearch['collaborazione']} /></th>
                            <th className="border-bottom">Periodo
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('date_disponibilita', e.target.value);
                                    }}
                                    value={itemSearch['date_disponibilita']} /></th>
                            <th className="border-bottom">Location
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('location', e.target.value);
                                    }}
                                    value={itemSearch['location']} /></th>
                            <th className="border-bottom">Camp
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('camp', e.target.value);
                                    }}
                                    value={itemSearch['camp']} /></th>
                            <th className="border-bottom">CRM + Tabella</th>
                            <th className="border-bottom">Ruolo
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('ruolo', e.target.value);
                                    }}
                                    value={itemSearch['ruolo']} /></th>
                            <th className="border-bottom">Compenso</th>
                            <th className="border-bottom">Rimborso</th>
                            <th className="border-bottom">Trasporto Andata
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('trasporto_andata', e.target.value);
                                    }}
                                    value={itemSearch['trasporto_andata']} /></th>
                            <th className="border-bottom">Trasporto Ritorno
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('trasporto_ritorno', e.target.value);
                                    }}
                                    value={itemSearch['trasporto_ritorno']} /></th>
                            <th className="border-bottom">Responsabile Trasporto</th>
                            {(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                                <th className="border-bottom">Azione</th>
                                :
                                null
                            }
                        </tr>
                    </thead>
                    <tbody className="border-0">
                        {mydata.map(u => <TableRow key={`user-${u.id}`} {...u} />)}
                    </tbody>
                </Table>
                <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <Form.Group id="listaPagine" className="mb-4">
                        <Form.Label>Seleziona Pagina</Form.Label>
                        <Form.Select id="pagine"
                            style={{ 'width': '80px' }}
                            value={
                                currentPage
                            }
                            onChange={(e) => {
                                setCurrentPage(e.target.value)
                            }}>
                            {listPages()}
                        </Form.Select>
                    </Form.Group>
                    <Nav>
                        <Pagination className="mb-0">
                            <Pagination.Prev onClick={() => {
                                setCurrentPage(currentPage - 1);
                            }}
                                disabled={currentPage === 0}>
                                <ArrowLeftIcon className="icon icon-xs text-danger me-2" />
                            </Pagination.Prev>
                            <Pagination.Next onClick={() => {
                                setCurrentPage(currentPage + 1)
                            }}
                                disabled={
                                    (currentPage + 1) === (Math.ceil(quantiUsers() / rowsPerPage))
                                }>
                                <ArrowRightIcon className="icon icon-xs text-danger me-2" />
                            </Pagination.Next>
                        </Pagination>
                        <small className="fw-normal small m-4 mt-lg-0">
                            Visualizzazione <b>{mydata.length + (currentPage * rowsPerPage)}</b> di <b>{quantiUsers()}</b> risultati
                        </small>
                    </Nav>
                </Card.Footer>
            </Card.Body>
            <NoteConfermeModal
                title={itemNoteModal.title}
                id={itemNoteModal.item_id}
                key={'note'}
                value={itemNoteModal.value}
                noteDefault={itemNoteModal.value}
                onUpdateItem={onUpdateItem}
            />
            <DetailsDisponibilitaModal />
        </Card>

    );
};