
import React, { useState } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AdjustmentsIcon, CheckIcon, DownloadIcon, PlusIcon, RefreshIcon, SearchIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, InputGroup, Dropdown } from 'react-bootstrap';

import { useRecoilState, useRecoilValue } from "recoil";
import { annoGestioneSelector, confermeSelector, listaConfermeSelector, loginUserSelector, } from "data/selectors";
import { TableConferme } from "./TablesConferme";
import { confermeOrderItemAtom, confermeOrderItemTypeAtom, confermeRefreshIncrementAtom, confermeRicercaObjAtom, confermeSearchAtom, confermeSetAtom, confermeStatoTypeAtom, currentPageConfermeAtom, myConfermeDataAtom, myDisponibilitaDataAtom, rowsPerPageConfermeAtom } from "data/atom";
import { actionUtilityConferme, eliminaConferme } from "data/integrations";

import { exportToExcel } from 'react-json-to-excel';

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));

export default () => {
  const myUsers = useRecoilValue(listaConfermeSelector);
  const [confermeRefresh, setConfermeRefresh] = useRecoilState(confermeRefreshIncrementAtom);
  const [orderOption, setOrderOption] = useRecoilState(confermeOrderItemAtom);
  const [orderOptionType, setOrderOptionType] = useRecoilState(confermeOrderItemTypeAtom);

  const [searchValue, setSearchValue] = useRecoilState(confermeSearchAtom);
  const [myDataAtom, setMyDataAtom] = useRecoilState(myConfermeDataAtom);
  const [myDataDispAtom, setMyDataDispAtom] = useRecoilState(myDisponibilitaDataAtom);

  const annoGestione = useRecoilValue(annoGestioneSelector);
  const [statoOption, setStatoOption] = useRecoilState(confermeStatoTypeAtom);

  let [users, setUsers] = useRecoilState(confermeSelector);
  let [rowsPerPage, setRowsPerPage] = useRecoilState(rowsPerPageConfermeAtom);
  const [currentPage, setCurrentPage] = useRecoilState(currentPageConfermeAtom);

  let totalUsers = users.length;
  let selectedUsersIds = users.filter(u => u.isSelected).map(u => u.id);

  const [myLoginUser, setLoginUser] = useRecoilState(loginUserSelector);
  const [confermeRicercaObj, setConfermeRicercaObj] = useRecoilState(confermeRicercaObjAtom);

  const generaXlsData = () => {
    var myXlsData = [];
    myUsers.forEach(
      u => {
        var myItem = JSON.parse(JSON.stringify(u));
        myXlsData.push(myItem);
      }
    );

    return myXlsData;
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc' ?
      (a, b) => descendingComparator(a, b, orderBy) :
      (a, b) => -descendingComparator(a, b, orderBy);
  }

  const filterTableData = () => {
    var newData = JSON.parse(JSON.stringify(users));
    newData = newData.filter(u => (statoOption === "T") ? u.show : (u.show && parseInt(u.stato) === parseInt(statoOption)))

    var orderBy = orderOption;
    var orderType = orderOptionType;
    if (newData.length > 0) {
      newData = newData.sort(getComparator(orderType, orderBy))
    }
    else return;

    if (searchValue.length > 0) {
      newData = newData.filter(u => (u.nominativo.toLowerCase().includes(searchValue.toLowerCase())));
    }

    var fieldArray = ["nominativo", "collaborazione", "date_disponibilita", "ruolo", "location", "camp", "trasporto_andata", "trasporto_ritorno"];
    var itemSearch = confermeRicercaObj;

    for (var i = 0; i < fieldArray.length; i++) {
      var myField = fieldArray[i];
      switch (fieldArray[i]) {
        case 'trasporto_andata':
          newData = newData.filter(u => (u['andata_tipo_trasporto'].toLowerCase().includes(itemSearch['trasporto_andata'].toLowerCase()) || u['andata_trasporto'].toLowerCase().includes(itemSearch['trasporto_andata'].toLowerCase())));
          break;
        case 'trasporto_ritorno':
          newData = newData.filter(u => (u['ritorno_tipo_trasporto'].toLowerCase().includes(itemSearch['trasporto_ritorno'].toLowerCase()) || u['ritorno_trasporto'].toLowerCase().includes(itemSearch['trasporto_ritorno'].toLowerCase())));
          break;
        default:
          if (itemSearch[fieldArray[i]].length > 0) {
            // eslint-disable-next-line no-loop-func
            newData = newData.filter(u => (u[myField].toLowerCase().includes(itemSearch[myField].toLowerCase())));
          }
          break;
      }
    }

    newData = newData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);

    return newData;
  }

  let allSelected = (totalUsers > 0) ? selectedUsersIds.length === filterTableData().length : false;

  const onUpdateItem = async (obj) => {
    const newUsers = JSON.parse(JSON.stringify(users));
    let objIndex = newUsers.findIndex((element => element.id === obj.item_id));
    newUsers[objIndex][obj.key] = obj.value;
    if (obj.key === 'rimborso' && obj.value === 'N') {
      newUsers[objIndex]['rimborso_quota'] = "0";
    }
    if (obj.key === 'andata_tipo_trasporto') {
      switch (obj.value) {
        case "Bus":
          newUsers[objIndex]['andata_trasporto'] = obj.bus;
          break;
        case "Treno":
          newUsers[objIndex]['andata_trasporto'] = obj.treno;
          break;
        case "Autonomo":
          newUsers[objIndex]['andata_trasporto'] = obj.autonomo;
          break;
        default:
          newUsers[objIndex]['andata_trasporto'] = "Non selezionato";
          break;
      }
    }
    if (obj.key === 'ritorno_tipo_trasporto') {
      switch (obj.value) {
        case "Bus":
          newUsers[objIndex]['ritorno_trasporto'] = obj.bus;
          break;
        case "Treno":
          newUsers[objIndex]['ritorno_trasporto'] = obj.treno;
          break;
        case "Autonomo":
          newUsers[objIndex]['ritorno_trasporto'] = obj.autonomo;
          break;
        default:
          newUsers[objIndex]['ritorno_trasporto'] = "Non selezionato";
          break;
      }
    }

    var myObj = {};
    myObj['action'] = 'update_item';
    myObj['subject'] = 'conferme';
    myObj['obj'] = obj;
    myObj['user'] = myLoginUser['username'];

    await actionUtilityConferme(myObj);

    if (obj.key === 'rimborso' && obj.value === 'N') {
      obj.key = 'rimborso_quota';
      obj.value = "0";
      myObj['action'] = 'update_item';
      myObj['subject'] = 'conferme';
      myObj['obj'] = obj;
      myObj['user'] = myLoginUser['username'];

      await actionUtilityConferme(myObj);
    }
    if (obj.key === 'andata_tipo_trasporto') {
      obj.key = 'andata_trasporto';
      obj.value = newUsers[objIndex]['andata_trasporto'];
      myObj['action'] = 'update_item';
      myObj['subject'] = 'conferme';
      myObj['obj'] = obj;
      myObj['user'] = myLoginUser['username'];

      await actionUtilityConferme(myObj);
    }

    if (obj.key === 'ritorno_tipo_trasporto') {
      obj.key = 'ritorno_trasporto';
      obj.value = newUsers[objIndex]['ritorno_trasporto'];
      myObj['action'] = 'update_item';
      myObj['subject'] = 'conferme';
      myObj['obj'] = obj;
      myObj['user'] = myLoginUser['username'];

      await actionUtilityConferme(myObj);
    }
    setUsers(newUsers);
  };

  const changeSearchValue = (e) => {
    setCurrentPage(0);

    const newSearchValue = e.target.value;

    setSearchValue(newSearchValue);

    var newUsers = JSON.parse(JSON.stringify(users));
    newUsers = users.map(u => ({ ...u, isSelected: false }));
    setUsers(newUsers);
  };

  const selectAllUsers = () => {
    /*
    const newUsers = selectedUsersIds.length === totalUsers ?
      users.map(u => ({ ...u, isSelected: false })) :
      users.map(u => ({ ...u, isSelected: true }));
    */

      var newUsers = JSON.parse(JSON.stringify(users));
      var myData = filterTableData();
  
      /*
      selectedUsersIds.length === totalUsers ?
        users.map(u => ({ ...u, isSelected: false })) :
        users.map(u => ({ ...u, isSelected: true }));
      */
     for(var i=0;i<myData.length;i++){
        // eslint-disable-next-line no-loop-func
        newUsers = newUsers.map(u => u.id === myData[i].id ? ({ ...u, isSelected: !u.isSelected }) : u);
     }

    setUsers(newUsers);
  };

  const selectUser = (id) => {
    console.log(id);
    const newUsers = users.map(u => u.id === id ? ({ ...u, isSelected: !u.isSelected }) : u);
    setUsers(newUsers);
  };

  const deleteUsers = async (ids) => {
    const usersToBeDeleted = ids ? ids : selectedUsersIds;
    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Conferma Eliminazione Massiva",
      text: "Sei sicuro di voler eliminare " + usersToBeDeleted.length + " elementi ?",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Annulla"
    });

    if (result.isConfirmed) {
      var myObj = {};
      myObj['action'] = 'delete_bulk';
      myObj['obj'] = {};
      myObj['subject'] = 'conferme';
      myObj['obj']['ids'] = usersToBeDeleted;
      myObj['user'] = myLoginUser['username'];
      await eliminaConferme(myObj);
      var refs = confermeRefresh;
      refs++;
      setConfermeRefresh(refs);
      setMyDataAtom(undefined);
      setMyDataDispAtom(undefined);
      setSearchValue("");
      const confirmMessage = "Elementi eliminati con successo";

      await SwalWithBootstrapButtons.fire('Eliminazione completata', confirmMessage, 'success');
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Gestione Conferme {annoGestione}</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button variant="info" size="sm" className="ms-2 ms-lg-3 d-inline-flex align-items-center" onClick={() => {
            var refs = confermeRefresh;
            refs++;
            setConfermeRefresh(refs);
            setMyDataAtom(undefined);
            setSearchValue("");
          }}>
            <RefreshIcon className="icon icon-xs me-2" /> Aggiorna
          </Button>
          <Button variant="info" size="sm" className="ms-2 ms-lg-3 d-inline-flex align-items-center" onClick={() => {
            exportToExcel(generaXlsData(), 'Conferme')
          }}>
            <DownloadIcon className="icon icon-xs me-2" /> Esporta Dati Conferme
          </Button>
        </div>
      </div>

      <div className="table-settings mb-1">
        <Row className="justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-300">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Cerca Conferma"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
          </Col>
          <Col xs={3} lg={4} className="d-flex justify-content-end">
            <ButtonGroup>
              <Dropdown className="me-1">
                <Dropdown.Toggle split as={Button} variant="info" className="text-dark m-0 p-1">
                  <AdjustmentsIcon className="icon icon-sm" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end pb-0">
                  <span className="small ps-3 fw-bold text-dark">
                    Righe per pagina
                  </span>
                  <Dropdown.Item className="d-flex align-items-center fw-bold" onClick={() => { setRowsPerPage(10) }}>
                    10 {
                      (rowsPerPage === 10) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                    }
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold" onClick={() => { setRowsPerPage(20) }}>20 {
                    (rowsPerPage === 20) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                  }</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom" onClick={() => { setRowsPerPage(50) }}>50 {
                    (rowsPerPage === 50) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                  }</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom" onClick={() => { setRowsPerPage(100) }}>100 {
                    (rowsPerPage === 100) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                  }</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom" onClick={() => { setRowsPerPage(200) }}>200 {
                    (rowsPerPage === 200) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                  }</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
          </Col>
        </Row>
      </div>

      <TableConferme
        mydata={filterTableData()}
        allSelected={allSelected}
        selectUser={selectUser}
        deleteUsers={deleteUsers}
        selectAllUsers={selectAllUsers}
        onUpdateItem={onUpdateItem}
      />
    </>
  );
};
