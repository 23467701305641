
import { LockClosedIcon, MailIcon } from "@heroicons/react/solid";
import React from "react";
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { Redirect, useHistory } from 'react-router-dom';

import ExpLogo from "assets/img/ExperienceSummerCamp_LOGO_COLORE-002.png";
import BgImage from "assets/img/illustrations/Recriuitmant.png";
import { refreshTokenAtom } from "data/atom";
import { loginUser } from "data/integrations";
import { loginUserSelector, tokenSelector } from "data/selectors";
import { useRecoilState } from 'recoil';
import { Routes } from "routes";

export default () => {

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [token, setToken] = useRecoilState(tokenSelector);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenAtom);
  const [myLoginUser, setLoginUser] = useRecoilState(loginUserSelector);

  let history = useHistory();

  const doLogin = async (e) => {
    e.preventDefault();
    let data = await loginUser(email, password);
    if (data.esito === 'OK') {
      setToken(data.obj.token);
      var refs = refreshToken;
      refs++;
      setRefreshToken(refs);
    }
  }

  const drawLogin = () => {
    return (
      <main style={{ backgroundImage: `url(${BgImage})`,backgroundSize: 'contain',
      backgroundRepeat: 'round' }}>
        <section className="d-flex align-items-center vh-lg-100 mt-5 mt-lg-0 bg-soft">
          <Container>
            <Row className="justify-content-center form-bg-image">
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <img src={ExpLogo} className="image-md" alt="Logo Exp"/>
                  </div>
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0 fw-bold fs-5">Accedi alla piattaforma</h3>
                  </div>
                  <Form className="mt-4" onSubmit={doLogin}>
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>Email</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <MailIcon className="icon icon-xs text-primary" />
                        </InputGroup.Text>
                        <Form.Control autoFocus required type="email" placeholder="example@company.com" value={email} onChange={(e) => {
                          setEmail(e.target.value)
                        }} />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <Form.Group id="password" className="mb-4">
                        <Form.Label>Password</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <LockClosedIcon className="icon icon-xs text-primary" />
                          </InputGroup.Text>
                          <Form.Control required type="password" placeholder="Password" onChange={(e) => {
                            setPassword(e.target.value)
                          }} />
                        </InputGroup>
                      </Form.Group>
                    </Form.Group>
                    <div className="d-grid">
                      <Button variant="primary" type="submit">
                        Login
                      </Button>
                    </div>
                  </Form>
                  {/*
                  <div className="mt-4 mb-4 text-center">
                    <span className="fw-small">Scoprici su</span>
                  </div>
                  <div className="d-flex justify-content-center my-4">
                    <Button variant="outline-gray-300" className="btn-icon-only btn-circle me-2 btn-facebook" href="#">
                      <FacebookNewIcon size="xs" color="currentColor" />
                    </Button>
                    <Button variant="outline-gray-300" className="btn-icon-only btn-circle btn-instagram" href="#">
                      <InstagramIcon size="xs" color="currentColor" />
                    </Button>
                  </div>
                  */}
                </div>

              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }

  if (token) {
    return <Redirect to={Routes.DashboardAnagrafica.path} />
  }
  else {
    return drawLogin();
  }
};
