import React, { useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { ArchiveIcon, CalendarIcon, CameraIcon, CheckIcon, ClipboardCheckIcon, ClockIcon, EyeIcon, PaperClipIcon, PlusIcon, SelectorIcon, ShareIcon, TagIcon, UserGroupIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Modal, Button, InputGroup, Image, Badge, FloatingLabel } from 'react-bootstrap';
import { useRecoilState } from "recoil";
import { showUtilityDetailModalAtom, showUtilityDetailModalAtomListaCamp, tipoUtilityAtom, tipoUtilityAtomCamp, utilityItemAtom, utilityItemAtomListaCamp } from "data/atom";


export const DetailsListaCampModal = (props) => {
    const [item, setItem] = useRecoilState(utilityItemAtomListaCamp);
    const [show, setShowDefault] = useRecoilState(showUtilityDetailModalAtomListaCamp);

    const { edit = false, title } = props;
    const [tipoUtility, setTipoUtility] = useRecoilState(tipoUtilityAtomCamp);

    const onDescrizioneChange = (e) => {
        let objTemp = JSON.parse(JSON.stringify(item));
        objTemp['descrizione'] = e.target.value;
        setItem(objTemp);
    }

    const onTipologiaChange = (e) => {
        let objTemp = JSON.parse(JSON.stringify(item));
        objTemp['tipologia'] = e.target.value;
        setItem(objTemp);
    }

    const onConfirm = () => {
        
        const payload = JSON.parse(JSON.stringify(item));

        if (edit) {
            return props.onUpdate && props.onUpdate(payload);
        }

        return props.onAdd && props.onAdd(payload);
    }

    const handleClose = () => setShowDefault(false);


    return (
        <Modal as={Modal.Dialog} centered show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">{title}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Form className="modal-content">
                <Modal.Body>
                    {
                        (edit) ?
                            <Form.Group id="id_item" className="mb-4">
                                <Form.Label>ID</Form.Label>
                                <Form.Control
                                    required
                                    disabled
                                    type="text"
                                    value={item['id']} />
                            </Form.Group>
                            :
                            null
                    }
                    <Form.Group id="descrizione" className="mb-4">
                        <Form.Label>Descrizione</Form.Label>
                        <Form.Control
                            required
                            autoFocus
                            type="text"
                            value={item['descrizione']}
                            onChange={onDescrizioneChange} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="me-2" onClick={onConfirm} 
                        disabled={item['descrizione'].length===0}
                    >
                        Salva
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};