
import React, { useState } from "react";
import { ArrowLeftIcon, ArrowNarrowDownIcon, ArrowNarrowUpIcon, ArrowRightIcon, CheckCircleIcon, ChevronDownIcon, ChevronUpIcon, DotsHorizontalIcon, ExternalLinkIcon, EyeIcon, FolderOpenIcon, InformationCircleIcon, PencilAltIcon, ShieldExclamationIcon, TrashIcon, UserRemoveIcon, XCircleIcon } from "@heroicons/react/solid";
import { Col, Row, Nav, Card, Form, Image, Button, Table, Dropdown, ProgressBar, Pagination, Tooltip, FormCheck, ButtonGroup, OverlayTrigger } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from "recoil";
import { anagraficaOrderItemAtom, anagraficaOrderItemTypeAtom, anagraficaRicercaObjAtom, anagraficaSearchAtom, anagraficheCurrentItemAtom, anagraficheRefreshIncrementAtom, currentPageAnagraficaAtom, editUtilityDetailModalAtom, formAnagraficaProvenienza, myAnagraficaDataAtom, rowsPerPageAnagraficaAtom, utilityItemAtom } from "data/atom";
import Swal from 'sweetalert2';
import { eliminaAnagrafiche } from "data/integrations";
import withReactContent from "sweetalert2-react-content";
import { listaAnagraficheSelector, loginUserSelector } from "data/selectors";
import { Link } from "react-router-dom";
import { Routes } from "routes";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary me-3',
        cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));


export const TableAnagrafica = (props) => {
    const { mydata = [], allSelected } = props;
    const [bulkOption, setBulkOption] = useState('delete_user');
    const [orderOption, setOrderOption] = useRecoilState(anagraficaOrderItemAtom);
    const [orderOptionType, setOrderOptionType] = useRecoilState(anagraficaOrderItemTypeAtom);
    const disabledBulkMenu = mydata.filter(u => u.isSelected).length === 0;
    const myUsers = useRecoilValue(listaAnagraficheSelector);
    const [currentPage, setCurrentPage] = useRecoilState(currentPageAnagraficaAtom);
    let [rowsPerPage, setRowsPerPage] = useRecoilState(rowsPerPageAnagraficaAtom);
    const [anagraficaRefresh, setAnagraficaRefresh] = useRecoilState(anagraficheRefreshIncrementAtom);
    const [myDataAtom, setMyDataAtom] = useRecoilState(myAnagraficaDataAtom);
    const [searchValue, setSearchValue] = useRecoilState(anagraficaSearchAtom);
    const [provenienza, setProvenienza] = useRecoilState(formAnagraficaProvenienza);

    const [itemModal, setItemModal] = useRecoilState(anagraficheCurrentItemAtom);
    const [itemSearch, setItemSearch] = useRecoilState(anagraficaRicercaObjAtom);

    const [myLoginUser, setLoginUser] = useRecoilState(loginUserSelector);

    function quantiUsers() {
        if (searchValue.length === 0) {
            var quanti = myUsers.length;
            return quanti;
        }
        else {
            var newUsers = myUsers.filter(u => u.nome.toLowerCase().includes(searchValue.toLowerCase()) | u.cognome.toLowerCase().includes(searchValue.toLowerCase()) | u.data_nascita.toLowerCase().includes(searchValue.toLowerCase()) | u.email.toLowerCase().includes(searchValue.toLowerCase()));
            return newUsers.length;
        }
    }

    const onAddItem = async (obj) => {
        var refs = anagraficaRefresh;
        refs++;
        setAnagraficaRefresh(refs);
        setMyDataAtom(undefined);
        setSearchValue("");
    };

    const onUpdateItem = async (obj) => {
        var refs = anagraficaRefresh;
        refs++;
        setAnagraficaRefresh(refs);
        setMyDataAtom(undefined);
        setSearchValue("");
    };

    const selectUser = (id) => {
        props.selectUser && props.selectUser(id);
    };

    const selectAllUsers = () => {
        props.selectAllUsers && props.selectAllUsers();
    };

    const bulkActionChange = (e) => {
        const newOption = e.target.value;
        setBulkOption(newOption);
    }

    const orderActionChange = (e) => {
        const newOption = e.target.value;
        setOrderOption(newOption);
    }

    const applyBulkAction = () => {
        if (bulkOption === "delete_user") deleteUsers();
    }

    const deleteUsers = (ids) => {
        props.deleteUsers && props.deleteUsers(ids)
    }

    const setRicercaItem = (key, value) => {
        var tempJson = JSON.parse(JSON.stringify(itemSearch));
        tempJson[key] = value;
        setItemSearch(tempJson);
    };

    const TableRow = (props) => {
        const { id, nome, cognome, email, data_nascita, isSelected } = props;

        return (
            <tr className="border-bottom">
                <td>
                    <FormCheck type="checkbox" className="dashboard-check">
                        <FormCheck.Input id={`user-${id}`} checked={isSelected === true} onChange={() => selectUser(id)} />
                        <FormCheck.Label htmlFor={`user-${id}`} />
                    </FormCheck>
                </td>
                <td style={{ width: '30px' }}>
                    <span className="fw-normal">{id}</span>
                </td>
                <td style={{ width: `${(8 * nome.length) + 30}px` }}>
                    <span className="fw-normal">{nome}</span>
                </td>
                <td style={{ width: `${(8 * cognome.length) + 30}px` }}>
                    <span className="fw-normal">{cognome}</span>
                </td>
                <td style={{ width: `${(8 * email.length) + 30}px` }}>
                    <span className="fw-normal">{email}</span>
                </td>
                <td style={{ width: `${(8 * data_nascita.length) + 30}px` }}>
                    <span className="fw-normal">{data_nascita}</span>
                </td>
                {
                    (myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                        <td>
                            <Button variant="soft" size="xs" onClick={() => {
                                var myItemModal = {};
                                myItemModal = mydata.filter(u => u.id === id)[0];
                                setProvenienza("A");
                                setItemModal(myItemModal);
                            }} as={Link} to={Routes.FormAnagrafica.path}>
                                <PencilAltIcon variant="info" className="icon icon-xs" style={{ "cursor": "pointer" }} />
                            </Button>
                        </td>
                        :
                        null
                }
                <td>
                    {(mydata.filter(u => u.id === id)[0].cv !== undefined && mydata.filter(u => u.id === id)[0].cv !== "") ?
                        <Button variant="soft" size="xs" onClick={() => {
                            var myURL = 'backend/' + mydata.filter(u => u.id === id)[0]['cv'];
                            window.open(myURL, '_blank');
                        }}>
                            <FolderOpenIcon className="icon icon-xs" style={{ "cursor": "pointer" }} />
                        </Button>
                        :
                        <span className="fw-normal">&nbsp;</span>
                    }
                </td>
                {(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                    <td>
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                <DotsHorizontalIcon className="icon icon-xs icon-dark" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="py-0">
                                <Dropdown.Item onClick={() => {
                                    var myItemModal = {};
                                    myItemModal = mydata.filter(u => u.id === id)[0];
                                    setProvenienza("A");
                                    setItemModal(myItemModal);
                                }} as={Link} to={Routes.FormAnagrafica.path}>
                                    <PencilAltIcon className="icon icon-xs me-2" /> Modifica
                                </Dropdown.Item>
                                <Dropdown.Item onClick={async () => {
                                    const result = await SwalWithBootstrapButtons.fire({
                                        icon: "error",
                                        title: "Conferma Eliminazione",
                                        text: "Sei sicuro di voler eliminare l'elemento con ID: " + id + " e Nominativo: " + nome + " " + cognome,
                                        showCancelButton: true,
                                        confirmButtonText: "Si",
                                        cancelButtonText: "Annulla"
                                    });

                                    if (result.isConfirmed) {
                                        var myObj = {};
                                        myObj['action'] = 'delete_item';
                                        myObj['obj'] = {};
                                        myObj['obj']['id'] = id;
                                        await eliminaAnagrafiche(myObj);
                                        var refs = anagraficaRefresh;
                                        refs++;
                                        setAnagraficaRefresh(refs);
                                        setMyDataAtom(undefined);
                                        setSearchValue("");
                                        const confirmMessage = "L'elemento ID: " + id + " è stato eliminato con successo";

                                        await SwalWithBootstrapButtons.fire('Eliminazione completata', confirmMessage, 'success');
                                    }
                                }}>
                                    <TrashIcon className="icon icon-xs text-danger me-2" /> Rimuovi
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                    :
                    null
                }
            </tr>
        );
    };

    const listPages = () => {
        var pageArrays = [];

        for (var i = 0; i < (Math.ceil(quantiUsers() / rowsPerPage)); i++) {
            pageArrays.push(<option value={i} key={'keyPage' + i}>{i + 1}</option>);
        }

        return pageArrays;
    }

    return (
        <Card border="0" className="table-wrapper table-responsive shadow">
            <Card.Body>
                <div className="d-flex mb-3">
                    {
                        (myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                            <Form.Select className="fmxw-200" disabled={disabledBulkMenu} value={bulkOption} onChange={bulkActionChange}>
                                <option value="delete_user">Cancella Selezionati</option>
                            </Form.Select>
                            :
                            null
                    }
                    {
                        (myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                            <Button variant="info" size="sm" className="ms-3" disabled={disabledBulkMenu} onClick={applyBulkAction}>
                                Applica
                            </Button>
                            :
                            null
                    }
                    <Form.Select className="fmxw-200 ms-3" disabled={mydata.length === 0} value={orderOption} onChange={orderActionChange}>
                        <option value="id">Ordina per ID</option>
                        <option value="cognome">Ordina per Cognome</option>
                        <option value="nome">Ordina per Nome</option>
                        <option value="email">Ordina per Email</option>
                        <option value="data_nascita">Ordina per Data Nascita</option>
                    </Form.Select>
                    <Form.Check
                        type="switch"
                        className="fmxw-200 ms-3 small"
                        label={(orderOptionType === 'asc') ? 'Ordine A-Z' : 'Ordine Z-A'}
                        id="switchOrderType"
                        htmlFor="switchOrderType"
                        checked={orderOptionType === 'asc'}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setOrderOptionType('asc');
                            }
                            else {
                                setOrderOptionType('desc');
                            }
                        }}
                    />
                </div>
                <Table hover className="user-table align-items-left">
                    <thead>
                        <tr>
                            <th className="border-bottom">
                                <FormCheck type="checkbox" className="dashboard-check">
                                    <FormCheck.Input id="userCheckAll" checked={allSelected} onChange={selectAllUsers} />
                                    <FormCheck.Label htmlFor="userCheckAll" />
                                </FormCheck>
                            </th>
                            <th className="border-bottom">ID
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('id', e.target.value);
                                    }}
                                    value={itemSearch['id']} />
                            </th>
                            <th className="border-bottom">Nome
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('nome', e.target.value);
                                    }}
                                    value={itemSearch['nome']} /></th>
                            <th className="border-bottom">Cognome
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('cognome', e.target.value);
                                    }}
                                    value={itemSearch['cognome']} /></th>
                            <th className="border-bottom">Email
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('email', e.target.value);
                                    }}
                                    value={itemSearch['email']} /></th>
                            <th className="border-bottom">Data Nascita
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('data_nascita', e.target.value);
                                    }}
                                    value={itemSearch['data_nascita']} /></th>
                            {
                                (myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                                    <th className="border-bottom">&nbsp;</th>
                                    :
                                    null
                            }
                            <th className="border-bottom">CV</th>
                            {(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                                <th className="border-bottom">Azione</th>
                                :
                                null
                            }
                        </tr>
                    </thead>
                    <tbody className="border-0">
                        {mydata.map(u => <TableRow key={`user-${u.id}`} {...u} />)}
                    </tbody>
                </Table>
                <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <Form.Group id="listaPagine" className="mb-4">
                        <Form.Label>Seleziona Pagina</Form.Label>
                        <Form.Select id="pagine"
                            style={{ 'width': '80px' }}
                            value={
                                currentPage
                            }
                            onChange={(e) => {
                                setCurrentPage(e.target.value)
                            }}>
                            {listPages()}
                        </Form.Select>
                    </Form.Group>
                    <Nav>
                        <Pagination className="mb-0">
                            <Pagination.Prev onClick={() => {
                                setCurrentPage(currentPage - 1);
                            }}
                                disabled={currentPage === 0}>
                                <ArrowLeftIcon className="icon icon-xs text-danger me-2" />
                            </Pagination.Prev>
                            <Pagination.Next onClick={() => {
                                setCurrentPage(currentPage + 1)
                            }}
                                disabled={
                                    (currentPage + 1) === (Math.ceil(quantiUsers() / rowsPerPage))
                                }>
                                <ArrowRightIcon className="icon icon-xs text-danger me-2" />
                            </Pagination.Next>
                        </Pagination>
                    </Nav>
                    <small className="fw-normal small mt-4 mt-lg-0">
                        Visualizzazione <b>{mydata.length + (currentPage * rowsPerPage)}</b> di <b>{quantiUsers()}</b> risultati
                    </small>
                </Card.Footer>
            </Card.Body>
        </Card>

    );
};