
import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { CSSTransition } from 'react-transition-group';
import { CheckCircleIcon, ChevronRightIcon, CogIcon, InformationCircleIcon, TableIcon, TruckIcon, UserGroupIcon, UsersIcon, XIcon } from "@heroicons/react/solid";
import { LogoutIcon } from "@heroicons/react/outline";
import { Nav, Badge, Image, Button, Dropdown, Navbar, Collapse, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "routes";
import ReactHero from "assets/img/icons/iconaSidebar.png";
import { useRecoilState } from "recoil";
import { anagraficheRefreshIncrementAtom, confermeRefreshIncrementAtom, disponibilitaRefreshIncrementAtom, myAnagraficaDataAtom, myConfermeDataAtom, myDisponibilitaDataAtom, myUtilityAtom, myUtilityAtomCamp, refreshTokenAtom, sidebarContractedAtom, tipoUtilityAtom, tipoUtilityAtomCamp, utentiRefreshAtom, utilitySearchAtom, utilitySearchAtomCamp } from "data/atom";
import { loginUserSelector } from "data/selectors";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const [collapsedItems, setCollapsedItems] = useState([pathname]);
  const contracted = props.contracted ? "contracted" : "";
  const [contractSidebar, setContractSidebar] = useRecoilState(sidebarContractedAtom);
  const showClass = show ? "show" : "";
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenAtom);
  const [myLoginUser, setLoginUser] = useRecoilState(loginUserSelector);
  const [tipoUtility, setTipoUtility] = useRecoilState(tipoUtilityAtom);
  const [utilityAtom, setUtilityAtom] = useRecoilState(myUtilityAtom);
  const [searchValueUtility, setSearchValueUtility] = useRecoilState(utilitySearchAtom);

  const [tipoUtilityCamp, setTipoUtilityCamp] = useRecoilState(tipoUtilityAtomCamp);
  const [utilityAtomCamp, setUtilityAtomCamp] = useRecoilState(myUtilityAtomCamp);
  const [searchValueUtilityCamp, setSearchValueUtilityCamp] = useRecoilState(utilitySearchAtomCamp);

  const [anagraficaRefresh, setAnagraficaRefresh] = useRecoilState(anagraficheRefreshIncrementAtom);
  const [disponibilitaRefresh, setDisponibilitaRefresh] = useRecoilState(disponibilitaRefreshIncrementAtom);
  const [confermeRefresh, setConfermeRefresh] = useRecoilState(confermeRefreshIncrementAtom);
  const [anagDataAtom, setAnagDataAtom] = useRecoilState(myAnagraficaDataAtom);
  const [dispDataAtom, setDispDataAtom] = useRecoilState(myDisponibilitaDataAtom);
  const [confermeDataAtom, setConfermeDataAtom] = useRecoilState(myConfermeDataAtom);

  const [utentiRefresh, setUtentiRefresh] = useRecoilState(utentiRefreshAtom);


  const onCollapse = () => setShow(!show);
  const onMouseEnter = () => props.onMouseEnter && props.onMouseEnter();
  const onMouseLeave = () => props.onMouseLeave && props.onMouseLeave();

  const onNavItemCollapse = (itemKey) => {
    const isCollapsed = collapsedItems.some(item => item.includes(itemKey));
    const newCollapsedItems = isCollapsed ? collapsedItems.filter(item => !item.includes(itemKey)) : [...collapsedItems, itemKey];
    setCollapsedItems(newCollapsedItems);
  };

  const events = isMobile ? {} : { onMouseEnter, onMouseLeave };

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon: NavItemIcon, children = null } = props;
    const isOpened = collapsedItems.some(item => item.includes(eventKey));

    return (
      <Nav.Item>
        <Nav.Link
          onClick={() => onNavItemCollapse(eventKey)}
          aria-expanded={isOpened}
          aria-controls={eventKey}
          className="d-flex justify-content-between align-items-center"
        >
          <span>
            <span className="sidebar-icon">
              <NavItemIcon className="icon icon-xs me-2" />
            </span>
            <span className="sidebar-text">
              {title}
            </span>
          </span>
          <span className="link-arrow">
            <ChevronRightIcon className="icon icon-sm" />
          </span>
        </Nav.Link>
        <Collapse in={isOpened} className="multi-level">
          <div id={eventKey}>
            {children}
          </div>
        </Collapse>
      </Nav.Item>
    );
  };

  const NavItem = (props) => {
    const { title, link, target, icon: NavItemIcon, image, badgeText, badgeBg, badgeColor = "white", handleClick } = props;
    const classNames = badgeText ? "d-flex align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";

    return (
      <Nav.Item className={navItemClassName} onClick={() => {
        setShow(false);
        if (handleClick !== undefined) {
          handleClick();
        }
      }}>
        <Nav.Link as={Link} to={link} target={target} className={classNames}>
          <span>
            {NavItemIcon && (
              <span className="sidebar-icon">
                <NavItemIcon className="icon icon-xs me-2" />
              </span>
            )}

            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            {!show && contracted && !NavItemIcon && !image ? (
              <span className="sidebar-text-contracted">
                {title[0]}
              </span>
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>

          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-sm notification-count">
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar as={Col} xs={12} expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-lg-none">
        <Navbar.Brand as={Link} to={Routes.DashboardAnagrafica.path} className="me-lg-5">
          <Image src={ReactHero} className="navbar-brand-dark" />
        </Navbar.Brand>
        <div className="d-flex align-items-center">
          <Navbar.Toggle as={Button} onClick={() => {
            onCollapse();
          }}>
            <span className="navbar-toggler-icon" />
          </Navbar.Toggle>
        </div>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar {...events} className={`${contracted} ${showClass} sidebar d-lg-block bg-primary text-white collapse`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="avatar-lg me-2">
                  <Image src={myLoginUser["foto"]} className="card-img-top rounded-circle border-white" />
                </div>
                <div className={`d-block ${(contracted) ? 'd-md-none' : ''}`}>
                  <h5 className={`mb-3`}>{(myLoginUser !== undefined) ? myLoginUser["nome"] + ' ' + myLoginUser["cognome"] : ''}</h5>
                  <Button as={Link} variant="secondary" size="sm" to={Routes.Login.path} className="d-inline-flex align-items-center" onClick={() => {
                    sessionStorage.removeItem('myLoginUser');
                    sessionStorage.removeItem('token');
                    var refs = refreshToken;
                    refs++;
                    setRefreshToken(refs);
                  }}>
                    <LogoutIcon className="icon icon-xxs me-1" /> Logout
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <XIcon className="icon icon-xs" />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <Dropdown.Divider className="my-3 border-primary" />
              <NavItem title="Anagrafiche" icon={UsersIcon} link={Routes.DashboardAnagrafica.path} handleClick={() => {
                setAnagDataAtom(undefined);
                var refs = anagraficaRefresh;
                refs++;
                setAnagraficaRefresh(refs);
              }} />
              <NavItem title="Disponibilità" icon={InformationCircleIcon} link={Routes.DashboardDisponibilita.path} handleClick={() => {
                setDispDataAtom(undefined);
                var refs = disponibilitaRefresh;
                refs++;
                setDisponibilitaRefresh(refs);
              }} />
              <NavItem title="Conferme" icon={CheckCircleIcon} link={Routes.DashboardConferme.path} handleClick={() => {
                setConfermeDataAtom(undefined);
                var refs = confermeRefresh;
                refs++;
                setConfermeRefresh(refs);
              }} />
              {
                (myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                  <CollapsableNavItem eventKey="settings/" title="Tabelle Utilità" icon={CogIcon}>
                    <NavItem title="Livello Inglese" link={Routes.ImpostazioniInglese.path} icon={TableIcon} handleClick={() => {
                      setTipoUtility('livello_inglese');
                      setUtilityAtom(undefined);
                      setSearchValueUtility("");
                    }} />
                    <NavItem title="Titoli di Studio" link={Routes.ImpostazioniStudio.path} icon={TableIcon} handleClick={() => {
                      setTipoUtility('titoli_studio');
                      setUtilityAtom(undefined);
                      setSearchValueUtility("");
                    }} />
                    <NavItem title="Tipo di Contratto" link={Routes.ImpostazioniContratti.path} icon={TableIcon} handleClick={() => {
                      setTipoUtility('tipo_contratto');
                      setUtilityAtom(undefined);
                      setSearchValueUtility("");
                    }} />
                    <NavItem title="Ruoli" link={Routes.ImpostazioniRuoli.path} icon={TableIcon} handleClick={() => {
                      setTipoUtility('ruoli');
                      setUtilityAtom(undefined);
                      setSearchValueUtility("");
                    }} />
                    <NavItem title="Discipline" link={Routes.ImpostazioniDiscipline.path} icon={TableIcon} handleClick={() => {
                      setTipoUtility('discipline');
                      setUtilityAtom(undefined);
                      setSearchValueUtility("");
                    }} />
                    <NavItem title="Trasporto Bus" link={Routes.ImpostazioniTrasportoBus.path} icon={TableIcon} handleClick={() => {
                      setTipoUtility('trasporto_bus');
                      setUtilityAtom(undefined);
                      setSearchValueUtility("");
                    }} />
                    <NavItem title="Trasporto Treno" link={Routes.ImpostazioniTrasportoTreno.path} icon={TableIcon} handleClick={() => {
                      setTipoUtility('trasporto_treno');
                      setUtilityAtom(undefined);
                      setSearchValueUtility("");
                    }} />
                  </CollapsableNavItem>
                  :
                  null
              }
              {
                (myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                  <CollapsableNavItem eventKey="settings-camp/" title="Gestione Camp" icon={CogIcon}>
                    <NavItem title="Location" link={Routes.GestioneLocation.path} icon={TableIcon} handleClick={() => {
                      setUtilityAtomCamp(undefined);
                      setTipoUtilityCamp('location');
                      setSearchValueUtilityCamp("");
                    }} />
                    <NavItem title="Lista Camp" link={Routes.GestioneCamp.path} icon={TableIcon} handleClick={() => {
                      setUtilityAtomCamp(undefined);
                      setTipoUtilityCamp('lista_camp');
                      setSearchValueUtilityCamp("");
                    }} />
                    <NavItem title="Tabella Trasporti" link={Routes.TabellaTrasporti.path} icon={TruckIcon} handleClick={() => {
                      setUtilityAtomCamp(undefined);
                      setTipoUtilityCamp('lista_trasporti');
                      setSearchValueUtilityCamp("");
                    }} />
                    <NavItem title="Impostazioni" link={Routes.ImpostazioniCamp.path} icon={TableIcon} handleClick={() => {
                      setUtilityAtomCamp(undefined);
                      setSearchValueUtilityCamp("");
                    }} />
                  </CollapsableNavItem>
                  :
                  null
              }
              <NavItem title="Gestione Utenti" icon={UserGroupIcon} link={Routes.GestioneUtenti.path} handleClick={() => {
                var refs = utentiRefresh;
                refs++;
                setUtentiRefresh(refs);
              }} />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
