import React, { useState } from "react";
import Select from "react-select";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { ArchiveIcon, CalendarIcon, CameraIcon, CheckIcon, ClipboardCheckIcon, ClockIcon, EyeIcon, PaperClipIcon, PlusIcon, SelectorIcon, ShareIcon, TagIcon, UserGroupIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Modal, Button, InputGroup, Image, Badge, FloatingLabel, Card } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from "recoil";
import { disponibilitaDettaglioModalAtom, showDisponibilitaModalAtom, } from "data/atom";
import { listaDisciplineDisponibilitaSelector, locationSelectSelector } from "data/selectors";


export const DetailsDisponibilitaModal = (props) => {
    const [item, setItem] = useRecoilState(disponibilitaDettaglioModalAtom);
    const [show, setShowDefault] = useRecoilState(showDisponibilitaModalAtom);
    const handleClose = () => setShowDefault(false);
    const listaDiscipline = useRecoilValue(listaDisciplineDisponibilitaSelector);

    const elencaDiscipline = ()=> {
        if(item===undefined) return "";
        if(item.disciplina===undefined) return "";
        var strArray =  [];
        item.disciplina.forEach(d => {
            strArray.push(d.label);
        });
        return strArray.join();
    };

    return (
        <Modal as={Modal.Dialog} centered show={show} onHide={handleClose} size="lg">
            <Modal.Header>
                <Modal.Title className="h6">Dettaglio Disponibilit&agrave;</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
                <Card as="form" border="0" className="shadow pb-4 mb-4">
                    <Card.Body className="p-0 p-md-4">
                        <Row className="justify-content-center">
                            <Col xs={12} lg={6}>
                                <Form.Group id="nominativo" className="mb-4">
                                    <Form.Label>Nominativo</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        value={item['nominativo']} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Group id="date_disponibilita" className="mb-4">
                                    <Form.Label>Data Disponobilit&agrave;</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        value={item['date_disponibilita']} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xs={12} lg={6}>
                                <Form.Group id="collaborazione" className="mb-4">
                                    <Form.Label>Collaborazione</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        value={item['collaborazione']} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Group id="ruolo" className="mb-4">
                                    <Form.Label>Ruolo</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        value={item['ruolo']} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xs={12} lg={4}>
                                <Form.Group id="disciplina_tipo" className="mb-4">
                                    <Form.Label>Tipo Disciplina</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        value={item['disciplina_tipo']} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={8}>
                                <Form.Group id="disciplina" className="mb-4">
                                    <Form.Label>Discipline Scelte</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        value={elencaDiscipline()} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xs={12} lg={6}>
                                <Form.Group id="trasporto_bus" className="mb-4">
                                    <Form.Label>Trasporto Bus</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        value={item['trasporto_bus']} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Group id="trasporto_treno" className="mb-4">
                                    <Form.Label>Trasporto Treno</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        value={item['trasporto_treno']} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xs={12} lg={4}>
                                <Form.Group id="autonomo" className="mb-4">
                                    <Form.Label>Trasporto Autonomo</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        value={item['autonomo']} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={8}>
                                <Form.Group id="autonomo_testo" className="mb-4">
                                    <Form.Label>Trasporto Autonomo Dettaglio</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        value={item['autonomo_testo']} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xs={12} lg={12}>
                                <Form.Group id="note" className="mb-4">
                                    <Form.Label>Note</Form.Label>
                                    <Form.Control
                                        disabled
                                        as="textarea" rows="5"
                                        value={item['note']} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" className="me-2" onClick={handleClose}
                >
                    Chiudi Dettaglio
                </Button>
            </Modal.Footer>
        </Modal>
    );
};