

export const Routes = {
    //XP Camp
    Login: { path: "/login/" },
    DashboardAnagrafica: { path: "/dashboard-anagrafica/" },
    DashboardDisponibilita: { path: "/dashboard-disponibilita/" },
    DashboardConferme: { path: "/dashboard-conferme/" },
    FormAnagrafica: { path: "/form-anagrafica/" },
    VoidPath: { path: "/" },

    ImpostazioniInglese: { path: "/settings/livelloinglese" },
    ImpostazioniStudio: { path: "/settings/titolistudio" },
    ImpostazioniContratti: { path: "/settings/tipocontratti" },
    ImpostazioniRuoli: { path: "/settings/ruoli" },
    ImpostazioniDiscipline: { path: "/settings/discipline" },
    ImpostazioniTrasportoTreno: { path: "/settings/treno" },
    ImpostazioniTrasportoBus: { path: "/settings/bus" },

    GestioneLocation: { path: "/settings-camp/location" },
    GestioneCamp: { path: "/settings-camp/lista-camp" },
    TabellaTrasporti: { path: "/settings-camp/tabella-trasporti" },
    ImpostazioniCamp: { path: "/settings-camp/impostazioni-camp" },

    GestioneUtenti: { path: "/gestione-utenti/" },


    // pages
    Presentation: { path: "/" },
    DashboardOverview: { path: "/dashboard/overview" },
    DashboardTraffic: { path: "/dashboard/traffic-and-engagement" },
    DashboardProductAnalysis: { path: "/dashboard/product-analysis" },
    Kanban: { path: "/kanban" },
    Messages: { path: "/messages" },
    SingleMessage: { path: "/message" },
    Users: { path: "/users" },
    Transactions: { path: "/transactions" },
    Tasks: { path: "/tasks" },
    Settings: { path: "/settings" },
    Calendar: { path: "/calendar" },
    Map: { path: "/map" },
    Datatables: { path: "/tables/datatables" },
    BootstrapTables: { path: "/tables/bootstrap-tables" },
    Pricing: { path: "/examples/pricing" },
    Billing: { path: "/examples/billing" },
    Invoice: { path: "/examples/invoice" },
    Signin: { path: "/login/sign-in" },
    Signup: { path: "/examples/sign-up" },
    ForgotPassword: { path: "/examples/forgot-password" },
    ResetPassword: { path: "/examples/reset-password" },
    Lock: { path: "/examples/lock" },
    NotFound: { path: "/examples/404" },
    ServerError: { path: "/examples/500" },

    // docs
    DocsOverview: { path: "/documentation/overview" },
    DocsDownload: { path: "/documentation/download" },
    DocsQuickStart: { path: "/documentation/quick-start" },
    DocsLicense: { path: "/documentation/license" },
    DocsFolderStructure: { path: "/documentation/folder-structure" },
    DocsBuild: { path: "/documentation/build-tools" },
    DocsChangelog: { path: "/documentation/changelog" },

    // plugins
    PluginCharts: { path: "/plugins/charts" },
    PluginCalendar: { path: "/plugins/calendar" },
    PluginDatatable: { path: "/plugins/datatable" },
    PluginMap: { path: "/plugins/map" },
    PluginDropzone: { path: "/plugins/dropzone" },
    PluginSweetAlert: { path: "/plugins/sweet-alert" },

    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" }
};