
import React, { useState } from "react";
import { ArrowLeftIcon, ArrowNarrowDownIcon, ArrowNarrowUpIcon, ArrowRightIcon, CheckCircleIcon, ChevronDownIcon, ChevronUpIcon, DotsHorizontalIcon, ExternalLinkIcon, EyeIcon, FolderOpenIcon, InformationCircleIcon, MailIcon, PencilAltIcon, ShieldExclamationIcon, TrashIcon, UserRemoveIcon, UsersIcon, XCircleIcon } from "@heroicons/react/solid";
import { Col, Row, Nav, Card, Form, Image, Button, Table, Dropdown, ProgressBar, Pagination, Tooltip, FormCheck, ButtonGroup, OverlayTrigger, Badge } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from "recoil";
import Swal from 'sweetalert2';
import { eliminaAnagrafiche, eliminaDisponibilita, estraiAnagrafiche } from "data/integrations";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { Routes } from "routes";
import { anagraficheCurrentItemAtom, confermeRefreshIncrementAtom, currentPageDisponibilitaAtom, disponibilitaAllAtom, disponibilitaCurrentItemAtom, disponibilitaDettaglioClonaModalAtom, disponibilitaNoteModalItemAtom, disponibilitaOrderItemAtom, disponibilitaOrderItemTypeAtom, disponibilitaRefreshIncrementAtom, disponibilitaRicercaObjAtom, disponibilitaSearchAtom, formAnagraficaProvenienza, myDisponibilitaDataAtom, rowsPerPageDisponibilitaAtom, showDisponibilitaClonaModalAtom, showNoteDisponibilitaModalAtom, statoDisponibilitaAtom } from "data/atom";
import { disponibilitaSelector, listaBusDisponibilitaSelector, listaCollaborazioniSelector, listaConfermeSelector, listaDisciplineAltroDisponibilitaSelector, listaDisciplineMedicheDisponibilitaSelector, listaDisciplineSportiveDisponibilitaSelector, listaDisciplineTematicheDisponibilitaSelector, listaDisponibilitaSelector, listaRuoliDisponibilitaSelector, listaRuoliSelector, listaTipoDisciplinaDisponibilitaSelector, listaTreniDisponibilitaSelector, loginUserSelector } from "data/selectors";
import Select from "react-select";
import { NoteDisponibilitaModal } from "./NoteDisponibilita";
import { DetailsDisponibilitaClonaModal } from "./DetailsDisponibilitaClona";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary me-3',
        cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));


export const TableDisponibilita = (props) => {
    const { mydata = [], allSelected } = props;
    const [bulkOption, setBulkOption] = useState('delete_user');
    const [orderOption, setOrderOption] = useRecoilState(disponibilitaOrderItemAtom);
    const [orderOptionType, setOrderOptionType] = useRecoilState(disponibilitaOrderItemTypeAtom);
    const disabledBulkMenu = mydata.filter(u => u.isSelected).length === 0;
    const disabledClonazione = mydata.filter(u => u.isSelected).length !== 1;
    const myUsers = useRecoilValue(listaDisponibilitaSelector);
    const [currentPage, setCurrentPage] = useRecoilState(currentPageDisponibilitaAtom);
    let [rowsPerPage, setRowsPerPage] = useRecoilState(rowsPerPageDisponibilitaAtom);
    const [disponibilitaRefresh, setDisponibilitaRefresh] = useRecoilState(disponibilitaRefreshIncrementAtom);
    const [myDataAtom, setMyDataAtom] = useRecoilState(myDisponibilitaDataAtom);
    const [searchValue, setSearchValue] = useRecoilState(disponibilitaSearchAtom);
    let [users, setUsers] = useRecoilState(disponibilitaSelector);

    const [itemModal, setItemModal] = useRecoilState(disponibilitaCurrentItemAtom);
    const [propostaType, setPropostaType] = useRecoilState(statoDisponibilitaAtom);

    const [show, setShowModal] = useRecoilState(showNoteDisponibilitaModalAtom);
    const [itemNoteModal, setItemNoteModal] = useRecoilState(disponibilitaNoteModalItemAtom);

    const [provenienza, setProvenienza] = useRecoilState(formAnagraficaProvenienza);
    const [itemModalAnagrafica, setItemModalAnagrafica] = useRecoilState(anagraficheCurrentItemAtom);
    const [confermeRefresh, setConfermeRefresh] = useRecoilState(confermeRefreshIncrementAtom);

    const listaCollaborazioni = useRecoilValue(listaCollaborazioniSelector);
    const listaRuoli = useRecoilValue(listaRuoliDisponibilitaSelector);
    const listaTipoDiscipline = useRecoilValue(listaTipoDisciplinaDisponibilitaSelector);
    const listaDisciplineSportive = useRecoilValue(listaDisciplineSportiveDisponibilitaSelector);
    const listaDisciplineTematiche = useRecoilValue(listaDisciplineTematicheDisponibilitaSelector);
    const listaDisciplineSanitarie = useRecoilValue(listaDisciplineMedicheDisponibilitaSelector);
    const listaDisciplineNeutre = useRecoilValue(listaDisciplineAltroDisponibilitaSelector);
    const listaBus = useRecoilValue(listaBusDisponibilitaSelector);
    const listaTreni = useRecoilValue(listaTreniDisponibilitaSelector);
    const confermeList = useRecoilValue(listaConfermeSelector);

    const [itemClona, setItemClona] = useRecoilState(disponibilitaDettaglioClonaModalAtom);
    const [showClona, setShowDefaultClona] = useRecoilState(showDisponibilitaClonaModalAtom);

    const [itemSearch, setItemSearch] = useRecoilState(disponibilitaRicercaObjAtom);
    const [myLoginUser, setLoginUser] = useRecoilState(loginUserSelector);

    function quantiUsers() {
        if (searchValue.length === 0) {
            var quanti = myUsers.length;
            return quanti;
        }
        else {
            var newUsers = myUsers.filter(u => (u.nominativo.toLowerCase().includes(searchValue.toLowerCase())));
            return newUsers.length;
        }
    }

    const onUpdateItem = async (obj) => {
        props.onUpdateItem && props.onUpdateItem(obj);
    };

    const onPostClonaItem = () => {
        setBulkOption('delete_user');
    };

    const selectUser = (id) => {
        props.selectUser && props.selectUser(id);
    };

    const selectAllUsers = () => {
        props.selectAllUsers && props.selectAllUsers();
    };

    const bulkActionChange = (e) => {
        const newOption = e.target.value;
        setBulkOption(newOption);
    }

    const orderActionChange = (e) => {
        const newOption = e.target.value;
        setOrderOption(newOption);
    }

    const applyBulkAction = () => {
        if (bulkOption === "delete_user") deleteUsers();
        if (bulkOption === "clone_user") {
            var disp = mydata.filter(u => u.isSelected)[0];
            var anag = { label: disp['nominativo'], value: disp['id_anagrafica'] }

            setItemModal(anag);
            setItemClona(disp);
            setShowDefaultClona(true);
        }
    }

    const deleteUsers = (ids) => {
        props.deleteUsers && props.deleteUsers(ids)
    }



    const TableRow = (props) => {
        const { id, id_anagrafica, nominativo, date_disponibilita, isSelected, ruolo, collaborazione, proposta, disciplina_tipo, disciplina, trasporto_bus, trasporto_treno, autonomo, note, autonomo_testo } = props;
        const [autonomoText, setAutonomoText] = useState(autonomo_testo);

        const tooltip = (
            <Tooltip id="tooltip">
                <strong>Clicca per confermare la disponibilit&agrave; di {nominativo}</strong>
            </Tooltip>
        );

        const tooltip_anagrafica = (
            <Tooltip id="tooltip">
                <strong>Clicca per visualizzare la scheda anagrafica di {nominativo}</strong>
            </Tooltip>
        );

        return (
            <tr className="border-bottom">
                <td>
                    <FormCheck type="checkbox" className="dashboard-check">
                        <FormCheck.Input id={`user-${id}`} checked={isSelected === true} onChange={() => selectUser(id)} />
                        <FormCheck.Label htmlFor={`user-${id}`} />
                    </FormCheck>
                </td>
                {(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                    <td>{(proposta === 'N') ?
                        <OverlayTrigger placement="top" overlay={tooltip}>
                            <Button
                                className="btn-icon-only btn-circle m-0 p-0" size="sm" variant="info" alt="Invia Proposta"
                                onClick={async () => {
                                    const result = await SwalWithBootstrapButtons.fire({
                                        icon: "question",
                                        title: "Conferma Invio Proposta",
                                        text: "Sei sicuro di voler confermare " + nominativo + " per la data " + date_disponibilita + " ?",
                                        showCancelButton: true,
                                        confirmButtonText: "Si",
                                        cancelButtonText: "Annulla"
                                    });
                                    if (result.isConfirmed) {
                                        var obj = {
                                            item_id: id,
                                            key: 'proposta',
                                            value: 'S'
                                        }
                                        onUpdateItem(obj);
                                        var refs = confermeRefresh;
                                        refs++;
                                        setConfermeRefresh(refs);
                                        refs = disponibilitaRefresh;
                                        refs++;
                                        setDisponibilitaRefresh(refs);
                                        await SwalWithBootstrapButtons.fire('Invio Proposta', 'La proposta a ' + nominativo + ' per la data ' + date_disponibilita + ' è stata inviata con successo.', 'success');
                                    }
                                }}>
                                <MailIcon className="icon icon-xxs" />
                            </Button>
                        </OverlayTrigger>
                        :
                        <Badge pill={true} bg="success" className="badge-lg">
                            <CheckCircleIcon className="icon icon-xs" />
                        </Badge>
                    }
                    </td>
                    :
                    null
                }
                <td>
                    <span className="fw-normal">{nominativo}</span>
                    {(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                        <OverlayTrigger placement="top" overlay={tooltip_anagrafica}>
                            <Button as={Link} to={Routes.FormAnagrafica.path} variant="soft" size="xs" onClick={async () => {
                                var myItemModal = {};
                                var mydataAnag = await estraiAnagrafiche();
                                mydataAnag = mydataAnag.obj;
                                myItemModal = mydataAnag.filter(u => u.id === id_anagrafica)[0];
                                setProvenienza("D");
                                setItemModalAnagrafica(myItemModal);
                            }} >
                                <UsersIcon variant="info" className="icon icon-xs" style={{ "cursor": "pointer" }} />
                            </Button>
                        </OverlayTrigger>
                        :
                        null
                    }
                </td>
                <td>
                    <span className="fw-normal">{date_disponibilita}</span>
                </td>
                <td>
                    <span className="fw-normal">{collaborazione}</span>
                </td>
                <td>
                    <Form.Group className="m-0" style={{ width: `${(8 * ruolo.length) + 100}px` }}>
                        <Select options={listaRuoli}
                            isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            defaultValue={{ label: ruolo, value: ruolo }}
                            onChange={(selectedOption) => {
                                var obj = {
                                    item_id: id,
                                    key: 'ruolo',
                                    value: selectedOption.value
                                }
                                onUpdateItem(obj)
                            }}
                        />
                    </Form.Group>
                </td>
                <td>
                    <Form.Group className="m-0" style={{ width: `${(8 * disciplina_tipo.length) + 100}px` }}>
                        <Select options={listaTipoDiscipline}
                            isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            defaultValue={{ label: disciplina_tipo, value: disciplina_tipo }}
                            onChange={(selectedOption) => {
                                var obj = {
                                    item_id: id,
                                    key: 'disciplina_tipo',
                                    value: selectedOption.value
                                }
                                onUpdateItem(obj)
                            }}
                        />
                    </Form.Group>
                </td>
                <td>
                    {(disciplina_tipo === 'Sportiva') ?
                        <Form.Group className="m-0" style={{ width: `${(8 * disciplina[0].label.length) + 200}px` }}>
                            <Select options={listaDisciplineSportive}
                                isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                isMulti
                                defaultValue={disciplina}
                                onChange={(selectedOptions) => {
                                    var mySelectedOptions = [];
                                    if (selectedOptions.length === 0) {
                                        mySelectedOptions.push({ label: 'Non selezionato', value: 'Non selezionato' });
                                    }
                                    else {
                                        mySelectedOptions = selectedOptions
                                    }
                                    var obj = {
                                        item_id: id,
                                        key: 'disciplina',
                                        value: mySelectedOptions
                                    }
                                    onUpdateItem(obj)
                                }}
                            />
                        </Form.Group>
                        :
                        (disciplina_tipo === 'Tematica') ?
                            <Form.Group className="m-0" style={{ width: `${(8 * disciplina[0].label.length) + 200}px` }}>
                                <Select options={listaDisciplineTematiche}
                                    isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                    isMulti
                                    defaultValue={disciplina}
                                    onChange={(selectedOptions) => {
                                        var mySelectedOptions = [];
                                        if (selectedOptions.length === 0) {
                                            mySelectedOptions.push({ label: 'Non selezionato', value: 'Non selezionato' });
                                        }
                                        else {
                                            mySelectedOptions = selectedOptions
                                        }
                                        var obj = {
                                            item_id: id,
                                            key: 'disciplina',
                                            value: mySelectedOptions
                                        }
                                        onUpdateItem(obj)
                                    }}
                                />
                            </Form.Group>
                            :
                            (disciplina_tipo === 'Sanitaria') ?
                                <Form.Group className="m-0" style={{ width: `${(8 * disciplina[0].label.length) + 200}px` }}>
                                    <Select options={listaDisciplineSanitarie}
                                        isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                        isMulti
                                        defaultValue={disciplina}
                                        onChange={(selectedOptions) => {
                                            var mySelectedOptions = [];
                                            if (selectedOptions.length === 0) {
                                                mySelectedOptions.push({ label: 'Non selezionato', value: 'Non selezionato' });
                                            }
                                            else {
                                                mySelectedOptions = selectedOptions
                                            }
                                            var obj = {
                                                item_id: id,
                                                key: 'disciplina',
                                                value: mySelectedOptions
                                            }
                                            onUpdateItem(obj)
                                        }}
                                    />
                                </Form.Group>
                                :
                                <Form.Group className="m-0" style={{ width: `${(8 * disciplina[0].label.length) + 200}px` }}>
                                    <Select options={listaDisciplineNeutre}
                                        isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                        isMulti
                                        defaultValue={disciplina}
                                        onChange={(selectedOptions) => {
                                            var mySelectedOptions = [];
                                            if (selectedOptions.length === 0) {
                                                mySelectedOptions.push({ label: 'Non selezionato', value: 'Non selezionato' });
                                            }
                                            else {
                                                mySelectedOptions = selectedOptions
                                            }
                                            var obj = {
                                                item_id: id,
                                                key: 'disciplina',
                                                value: mySelectedOptions
                                            }
                                            onUpdateItem(obj)
                                        }}
                                    />
                                </Form.Group>
                    }
                </td>
                <td>
                    <Form.Group className="m-0" style={{ width: `${(8 * trasporto_bus.length) + 100}px` }}>
                        <Select options={listaBus}
                            isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            defaultValue={{ label: trasporto_bus, value: trasporto_bus }}
                            onChange={(selectedOption) => {
                                var obj = {
                                    item_id: id,
                                    key: 'trasporto_bus',
                                    value: selectedOption.value
                                }
                                onUpdateItem(obj)
                            }}
                        />
                    </Form.Group>
                </td>
                <td>
                    <Form.Group className="m-0" style={{ width: `${(8 * trasporto_treno.length) + 100}px` }}>
                        <Select options={listaTreni}
                            isDisabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            defaultValue={{ label: trasporto_treno, value: trasporto_treno }}
                            onChange={(selectedOption) => {
                                var obj = {
                                    item_id: id,
                                    key: 'trasporto_treno',
                                    value: selectedOption.value
                                }
                                onUpdateItem(obj)
                            }}
                        />
                    </Form.Group>
                </td>
                <td>
                    <Form.Group className="mb-3">
                        <Form.Check
                            type="switch"
                            disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                            label={(autonomo === 'N') ? 'No' : 'Si'}
                            id={"switchAutonomo_" + id}
                            htmlFor={"switchAutonomo_" + id}
                            checked={(autonomo === 'S')}
                            onChange={async (e) => {
                                var obj = {
                                    item_id: id,
                                    key: 'autonomo',
                                    value: 'N'
                                }
                                if (e.target.checked) {
                                    obj.value = 'N';
                                }
                                else {
                                    obj.value = 'S';
                                }

                                onUpdateItem(obj)
                            }}
                        />
                    </Form.Group>
                    {(autonomo === 'S') ?
                        <Form.Group id="autonomo_testo" className="mb-4" style={{ width: `${(8 * "Questo è un testo di esempio".length) + 100}px` }}>
                            <Form.Control
                                disabled={!(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0')}
                                type="text"
                                value={autonomoText}
                                onChange={(e) => {
                                    setAutonomoText(e.target.value);
                                }}
                                onBlur={(e) => {
                                    var obj = {
                                        item_id: id,
                                        key: 'autonomo_testo',
                                        value: autonomoText
                                    }
                                    onUpdateItem(obj)
                                }} />
                        </Form.Group>
                        :
                        null}
                </td>
                {(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                    <td>
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                <DotsHorizontalIcon className="icon icon-xs icon-dark" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="py-0">
                                <Dropdown.Item onClick={() => {
                                    setItemNoteModal({
                                        item_id: id,
                                        key: 'note',
                                        value: note,
                                        title: 'Note per Disponibilità ' + nominativo + ' (' + date_disponibilita + ')'
                                    });
                                    setShowModal(true);
                                }}>
                                    <PencilAltIcon className="icon icon-xs me-2" /> Note Disponibilità
                                </Dropdown.Item>
                                <Dropdown.Item onClick={async () => {
                                    const result = await SwalWithBootstrapButtons.fire({
                                        icon: "error",
                                        title: "Conferma Eliminazione",
                                        text: "Sei sicuro di voler eliminare l'elemento con ID: " + id + " e Nominativo: " + nominativo,
                                        showCancelButton: true,
                                        confirmButtonText: "Si",
                                        cancelButtonText: "Annulla"
                                    });

                                    if (result.isConfirmed) {
                                        var myObj = {};
                                        myObj['action'] = 'delete_item';
                                        myObj['obj'] = {};
                                        myObj['obj']['id'] = id;
                                        await eliminaDisponibilita(myObj);
                                        var refs = disponibilitaRefresh;
                                        refs++;
                                        setDisponibilitaRefresh(refs);
                                        setMyDataAtom(undefined);
                                        setSearchValue("");
                                        const confirmMessage = "L'elemento ID: " + id + " è stato eliminato con successo";

                                        await SwalWithBootstrapButtons.fire('Eliminazione completata', confirmMessage, 'success');
                                    }
                                }}>
                                    <TrashIcon className="icon icon-xs text-danger me-2" /> Rimuovi
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                    :
                    null
                }
            </tr>
        );
    };

    const listPages = () => {
        var pageArrays = [];

        for (var i = 0; i < (Math.ceil(quantiUsers() / rowsPerPage)); i++) {
            pageArrays.push(<option value={i} key={'keyPage' + i}>{i + 1}</option>);
        }

        return pageArrays;
    }

    const setRicercaItem = (key, value) => {
        var tempJson = JSON.parse(JSON.stringify(itemSearch));
        tempJson[key] = value;
        setItemSearch(tempJson);

        var newUsers = JSON.parse(JSON.stringify(users));
        newUsers = users.map(u => ({ ...u, isSelected: false }));
        setUsers(newUsers);
    };

    return (
        <Card border="0" className="table-wrapper table-responsive shadow fmxh-400">
            <Card.Body>
                <div className="d-flex mb-3">
                    {(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                        <Form.Select className="fmxw-200" disabled={disabledBulkMenu} value={bulkOption} onChange={bulkActionChange}>
                            <option value="delete_user" key="delete_user">Cancella Selezionati</option>
                            {
                                (!disabledClonazione) ?
                                    <option value="clone_user" key="clone_user">Clona Disponibilit&agrave;</option>
                                    :
                                    null
                            }
                        </Form.Select>
                        :
                        null
                    }
                    {(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                        <Button variant="info" size="sm" className="ms-3" disabled={disabledBulkMenu} onClick={applyBulkAction}>
                            Applica
                        </Button>
                        :
                        null
                    }
                    <Form.Select className="fmxw-200 ms-3" disabled={mydata.length === 0} value={orderOption} onChange={orderActionChange}>
                        <option value="nominativo">Ordina per Nominativo</option>
                        <option value="dateOrdinamento">Ordina per Periodo Disponibilit&agrave;</option>
                    </Form.Select>
                    <Form.Check
                        type="switch"
                        className="fmxw-200 ms-3 small"
                        label={(orderOptionType === 'asc') ? 'Ordine A-Z' : 'Ordine Z-A'}
                        id="switchOrderType"
                        htmlFor="switchOrderType"
                        checked={orderOptionType === 'asc'}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setOrderOptionType('asc');
                            }
                            else {
                                setOrderOptionType('desc');
                            }
                        }}
                    />
                    <Form.Check
                        type="switch"
                        className="fmxw-300 ms-3 small"
                        label={(propostaType === 'N') ? 'Mostra Non Confermati' : 'Mostra Tutti'}
                        id="switchPropostaType"
                        htmlFor="switchPropostaType"
                        checked={propostaType === 'N'}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setPropostaType('N');
                            }
                            else {
                                setPropostaType('A');
                            }
                        }}
                    />
                </div>
                <Table hover className="user-table align-items-left">
                    <thead>
                        <tr>
                            <th className="border-bottom">
                                <FormCheck type="checkbox" className="dashboard-check">
                                    <FormCheck.Input id="userCheckAll" checked={allSelected} onChange={selectAllUsers} />
                                    <FormCheck.Label htmlFor="userCheckAll" />
                                </FormCheck>
                            </th>
                            {(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                                <th className="border-bottom">Proposta</th>
                                :
                                null
                            }
                            <th className="border-bottom">Nominativo
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('nominativo', e.target.value);
                                    }}
                                    value={itemSearch['nominativo']} /></th>
                            <th className="border-bottom">Periodo
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('date_disponibilita', e.target.value);
                                    }}
                                    value={itemSearch['date_disponibilita']} /></th>
                            <th className="border-bottom">Collaborazione
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('collaborazione', e.target.value);
                                    }}
                                    value={itemSearch['collaborazione']} /></th>
                            <th className="border-bottom">Ruolo
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('ruolo', e.target.value);
                                    }}
                                    value={itemSearch['ruolo']} /></th>
                            <th className="border-bottom">Tipo Disciplina
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('disciplina_tipo', e.target.value);
                                    }}
                                    value={itemSearch['disciplina_tipo']} /></th>
                            <th className="border-bottom">Disciplina
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('disciplina', e.target.value);
                                    }}
                                    value={itemSearch['disciplina']} /></th>
                            <th className="border-bottom">Trasporto Bus
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('trasporto_bus', e.target.value);
                                    }}
                                    value={itemSearch['trasporto_bus']} /></th>
                            <th className="border-bottom">Trasporto Treno
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('trasporto_treno', e.target.value);
                                    }}
                                    value={itemSearch['trasporto_treno']} /></th>
                            <th className="border-bottom">Autonomo
                                <Form.Control type="text"
                                    size="sm"
                                    onChange={(e) => {
                                        if(currentPage>0){
                                            setCurrentPage(0);
                                        }
                                        setRicercaItem('autonomo', e.target.value);
                                    }}
                                    value={itemSearch['autonomo']} /></th>
                            {(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                                <th className="border-bottom">Azione</th>
                                :
                                null
                            }
                        </tr>
                    </thead>
                    <tbody className="border-0">
                        {mydata.map(u => <TableRow key={`user-${u.id}`} {...u} />)}
                    </tbody>
                </Table>
                <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <Form.Group id="listaPagine" className="mb-4">
                        <Form.Label key="listaPagineLabel">Seleziona Pagina</Form.Label>
                        <Form.Select id="pagine"
                            style={{ 'width': '80px' }}
                            value={
                                currentPage
                            }
                            onChange={(e) => {
                                setCurrentPage(e.target.value)
                            }}>
                            {listPages()}
                        </Form.Select>
                    </Form.Group>
                    <Nav>
                        <Pagination className="mb-0">
                            <Pagination.Prev onClick={() => {
                                setCurrentPage(currentPage - 1);
                            }}
                                disabled={currentPage === 0}>
                                <ArrowLeftIcon className="icon icon-xs text-danger me-2" />
                            </Pagination.Prev>
                            <Pagination.Next onClick={() => {
                                setCurrentPage(currentPage + 1)
                            }}
                                disabled={
                                    (currentPage + 1) === (Math.ceil(quantiUsers() / rowsPerPage))
                                }>
                                <ArrowRightIcon className="icon icon-xs text-danger me-2" />
                            </Pagination.Next>
                        </Pagination>
                        <small className="fw-normal small m-4 mt-lg-0">
                            Visualizzazione <b>{mydata.length + (currentPage * rowsPerPage)}</b> di <b>{quantiUsers()}</b> risultati
                        </small>
                    </Nav>
                </Card.Footer>
            </Card.Body>
            <NoteDisponibilitaModal
                title={itemNoteModal.title}
                id={itemNoteModal.item_id}
                key={'note'}
                value={itemNoteModal.value}
                noteDefault={itemNoteModal.value}
                onUpdateItem={onUpdateItem}
            />
            <DetailsDisponibilitaClonaModal
                onUpdateItem={onPostClonaItem}
            />
        </Card>

    );
};