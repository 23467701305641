import React, { useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { ArchiveIcon, CalendarIcon, CameraIcon, CheckIcon, ClipboardCheckIcon, ClockIcon, EyeIcon, PaperClipIcon, PlusIcon, SelectorIcon, ShareIcon, TagIcon, UserGroupIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Modal, Button, InputGroup, Image, Badge, FloatingLabel } from 'react-bootstrap';
import { useRecoilState } from "recoil";
import { showUtentiDetailModalAtom, utentiItemAtom } from "data/atom";


export const DetailsUtentiModal = (props) => {
    const [item, setItem] = useRecoilState(utentiItemAtom);
    const [show, setShowDefault] = useRecoilState(showUtentiDetailModalAtom);
    const [confermaPassword, setConfermaPassword] = useState('');

    const { edit = false, title } = props;

    const onTextChange = (e, key) => {
        let objTemp = JSON.parse(JSON.stringify(item));
        objTemp[key] = e.target.value;
        setItem(objTemp);
    }

    const onTipologiaChange = (e) => {
        let objTemp = JSON.parse(JSON.stringify(item));
        objTemp['tipo'] = e.target.value;
        setItem(objTemp);
    }

    const onConfirm = () => {

        const payload = JSON.parse(JSON.stringify(item));

        if (edit) {
            return props.onUpdate && props.onUpdate(payload);
        }

        return props.onAdd && props.onAdd(payload);
    }

    const handleClose = () => setShowDefault(false);

    const isButtonDisabled = () => {
        if (edit) { 
            return item['password'] !== confermaPassword;
        }
        else {
            if(item === undefined) return true;
            if(item['nome']===undefined) return true;
            if(item['cognome']===undefined) return true;
            if(item['username']===undefined) return true;
            if(item['password']===undefined) return true;
            
            return (item['nome'].length === 0 || item['cognome'].length === 0
            || item['username'].length === 0
            || item['password'].length === 0
            || item['password'] !== confermaPassword);
        }
    }

    return (
        <Modal as={Modal.Dialog} centered show={show} onHide={handleClose} size="lg">
            <Modal.Header>
                <Modal.Title className="h6">{(edit === true) ? "Modifica Password Utente" : "Nuovo Utente"}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Form className="modal-content">
                <Modal.Body>
                    <Row className="justify-content-center">
                        <Col xs={12} lg={6}>
                            {
                                (!edit) ?
                                    <Form.Group id="nome_utente" className="mb-4">
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control
                                            required
                                            autoFocus
                                            type="text"
                                            value={item['nome']}
                                            onChange={(e) => {
                                                onTextChange(e, 'nome')
                                            }} />
                                    </Form.Group>
                                    : null
                            }
                        </Col>
                        <Col xs={12} lg={6}>
                            {(!edit) ?
                                <Form.Group id="cognome_utente" className="mb-4">
                                    <Form.Label>Cognome</Form.Label>
                                    <Form.Control
                                        required
                                        autoFocus
                                        type="text"
                                        value={item['cognome']}
                                        onChange={(e) => {
                                            onTextChange(e, 'cognome')
                                        }} />
                                </Form.Group>
                                : null
                            }
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={12} lg={12}>
                            {(!edit) ?
                                <Form.Group id="email_utente" className="mb-4">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        required
                                        autoFocus
                                        type="email"
                                        value={item['username']}
                                        onChange={(e) => {
                                            onTextChange(e, 'username')
                                        }} />
                                </Form.Group>
                                : null
                            }
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={12} lg={6}>
                            <Form.Group id="password_utente_1" className="mb-4">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    required
                                    autoFocus
                                    type="password"
                                    value={item['password']}
                                    onChange={(e) => {
                                        onTextChange(e, 'password')
                                    }} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group id="password_utente_2" className="mb-4">
                                <Form.Label>Conferma Password</Form.Label>
                                <Form.Control
                                    required
                                    autoFocus
                                    type="password"
                                    value={confermaPassword}
                                    onChange={(e) => {
                                        setConfermaPassword(e.target.value)
                                    }} />
                            </Form.Group>
                        </Col>
                    </Row>

                    {(!edit) ?
                        <Row className="justify-content-center">
                            <Col xs={12} lg={12}>
                                <Form.Group id="tipo" className="mb-4">
                                    <Form.Label>Tipo Utente</Form.Label>
                                    <Form.Select id="tipo" value={item['tipo']} onChange={onTipologiaChange}>
                                        <option value="0">Amministratore</option>
                                        <option value="1">Visualizzatore</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        : null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="me-2" onClick={onConfirm}
                        disabled={
                            isButtonDisabled()
                        }
                    >
                        Salva
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};