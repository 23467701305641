
import React, { useState } from "react";
import Axios from 'axios'
import moment from "moment-timezone";
import 'moment/locale/it';
import Datetime from "react-datetime";
import { CalendarIcon, CheckCircleIcon, FolderOpenIcon, InformationCircleIcon, SaveIcon, UsersIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Form, Button, InputGroup, Tab, Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Routes } from "routes";
import { anagraficaSearchAtom, anagraficheCurrentItemAtom, anagraficheRefreshIncrementAtom, confermeRefreshIncrementAtom, disponibilitaRefreshIncrementAtom, formAnagraficaProvenienza, myAnagraficaDataAtom } from "data/atom";
import { useRecoilState, useRecoilValue } from "recoil";
import { LivelloIngleseListSelector, loginUserSelector, TipoContrattoListSelector, TitoliStudioListSelector } from "data/selectors";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));

export default () => {
  const [currentItem, setCurrentItem] = useRecoilState(anagraficheCurrentItemAtom);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const titoliStudioList = useRecoilValue(TitoliStudioListSelector);
  const tipoContrattoList = useRecoilValue(TipoContrattoListSelector);
  const livelloIngleseList = useRecoilValue(LivelloIngleseListSelector);
  const [myDataAtom, setMyDataAtom] = useRecoilState(myAnagraficaDataAtom);
  const [anagraficaRefresh, setAnagraficaRefresh] = useRecoilState(anagraficheRefreshIncrementAtom);
  const [searchValue, setSearchValue] = useRecoilState(anagraficaSearchAtom);
  const [provenienza, setProvenienza] = useRecoilState(formAnagraficaProvenienza);

  const [disponibilitaRefresh, setDisponibilitaRefresh] = useRecoilState(disponibilitaRefreshIncrementAtom);
  const [confermeRefresh, setConfermeRefresh] = useRecoilState(confermeRefreshIncrementAtom);
  const [myLoginUser, setLoginUser] = useRecoilState(loginUserSelector);

  const currentItemKey = (key) => {
    var value = (currentItem !== undefined) ? (currentItem[key] !== undefined) ? currentItem[key] : undefined : undefined;
    return value;
  };

  function handleFileChange(e) {
    console.log(e.target.files);
    setSelectedFile(e.target.files[0]);
  }

  function getAnagraficaNominativo() {
    if (currentItem === undefined) {
      return ' - Nuova Anagrafica';
    }
    if (currentItem['nome'] === undefined || currentItem['cognome'] === undefined) {
      return ' - Nuova Anagrafica';
    }
    else {
      return (' - ' + currentItem['nome'] + ' ' + currentItem['cognome']);
    }
  };

  async function validaSalvataggio() {
    var message = '';

    if (currentItem['nome'] === undefined || currentItem['nome'] === '') {
      message = 'Il nome dell\'Anagrafica non risulta compilato correttamente !';
      await SwalWithBootstrapButtons.fire('Validazione Form', message, 'warning');
      return false;
    }

    if (currentItem['cognome'] === undefined || currentItem['cognome'] === '') {
      message = 'Il cognome dell\'Anagrafica non risulta compilato correttamente !';
      await SwalWithBootstrapButtons.fire('Validazione Form', message, 'warning');
      return false;
    }

    if (currentItem['residenza'] === undefined || currentItem['residenza'] === '') {
      message = 'La residenza dell\'Anagrafica non risulta compilata correttamente !';
      await SwalWithBootstrapButtons.fire('Validazione Form', message, 'warning');
      return false;
    }

    if (currentItem['data_nascita'] === undefined || currentItem['data_nascita'] === '') {
      message = 'La data di nascita dell\'Anagrafica non risulta compilata correttamente !';
      await SwalWithBootstrapButtons.fire('Validazione Form', message, 'warning');
      return false;
    }

    var myDateStr = currentItemKey('data_nascita') ? currentItemKey('data_nascita') : "";
    //var myDate = (myDateStr === "") ? moment() : moment(myDateStr, "DD/MM/YYYY");
    var dataValida = moment(myDateStr, "DD/MM/YYYY").format("DD/MM/YYYY") === myDateStr;
    if (!dataValida) {
      message = 'La data di nascita dell\'Anagrafica non risulta compilata correttamente !';
      await SwalWithBootstrapButtons.fire('Validazione Form', message, 'warning');
      return false;
    }

    if (currentItem['email'] === undefined || currentItem['email'] === '') {
      message = 'L\'Email dell\'Anagrafica non risulta compilata correttamente !';
      await SwalWithBootstrapButtons.fire('Validazione Form', message, 'warning');
      return false;
    }

    if (currentItem['telefono'] === undefined || currentItem['telefono'] === '') {
      message = 'Il telefono dell\'Anagrafica non risulta compilato correttamente !';
      await SwalWithBootstrapButtons.fire('Validazione Form', message, 'warning');
      return false;
    }

    if (currentItem['titolo_studio'] === undefined || currentItem['titolo_studio'] === 'XXX') {
      message = 'Il titolo di studio dell\'Anagrafica non risulta compilato correttamente !';
      await SwalWithBootstrapButtons.fire('Validazione Form', message, 'warning');
      return false;
    }

    if (currentItem['livello_inglese'] === undefined || currentItem['livello_inglese'] === 'XXX') {
      message = 'Il livello inglese dell\'Anagrafica non risulta compilato correttamente !';
      await SwalWithBootstrapButtons.fire('Validazione Form', message, 'warning');
      return false;
    }

    if (currentItem['tipo_contratto'] === undefined || currentItem['tipo_contratto'] === 'XXX') {
      message = 'Il tipo contratto dell\'Anagrafica non risulta compilato correttamente !';
      await SwalWithBootstrapButtons.fire('Validazione Form', message, 'warning');
      return false;
    }

    return true;
  }

  const salvaAnagrafica = async () => {
    var isOk = await validaSalvataggio();

    if (isOk === false) return;

    const data = new FormData();

    if (!selectedFile) {
      //TODO
    }
    else {
      data.append("fileCV", selectedFile);
    }
    for (var [key, value] of Object.entries(currentItem)) {
      switch (key) {
        case 'blsd':
        case 'assistente_bagnante':
          if (value === undefined) {
            value = 'NO';
          }
          data.append(key, value);
          break;
        case 'data_nascita':
          var myDateStr = currentItemKey('data_nascita') ? currentItemKey('data_nascita') : "";
          var myDate = (myDateStr === "") ? moment() : moment(myDateStr, "DD/MM/YYYY");
          data.append(key, myDate.format("DD/MM/YYYY"));
          break;
        default:
          data.append(key, value);
          break;
      } 
    }

    data.append('user',myLoginUser['username']);


    let url = '/backend/salvaAnagrafica.php';

    Axios.post(url, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
      .then(async (res) => {
        console.log(res);
        if (res.data.error === false) {
          // then print response status
          SwalWithBootstrapButtons.fire('Salvataggio completato', "Il salvataggio dell'anagrafica è stato completato con successo !", 'success');
          setSelectedFile(undefined);
          setCurrentItem(res.data.obj);

          var refs = anagraficaRefresh;
          refs++;
          setAnagraficaRefresh(refs);
          setMyDataAtom(undefined);
          setSearchValue("");

          var refs = disponibilitaRefresh;
          refs++;
          setDisponibilitaRefresh(refs);

          var refs = confermeRefresh;
          refs++;
          setConfermeRefresh(refs);
        }
        else {
          alert(res.data.message);
        }

      }, error => {
        alert(error);
      });

  };

  function getDataNascita() {
    var myDateStr = currentItemKey('data_nascita') ? currentItemKey('data_nascita') : "";
    //const myDate = (myDateStr === "") ? moment() : moment(myDateStr, "DD/MM/YYYY");
    return myDateStr;
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Anagrafiche Reclutamento {getAnagraficaNominativo()}</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button variant="info" size="sm" className="ms-2 ms-lg-3 d-inline-flex align-items-center"
            disabled={currentItem === undefined}
            onClick={async () => {
              await salvaAnagrafica();
            }}>
            <SaveIcon className="icon icon-xs me-2" /> Salva Anagrafica
          </Button>
        </div>
      </div>
      <Row className="justify-content-center">
        <Col xs={12} className="d-flex justify-content-between flex-column flex-sm-row mb-2">
          {
            (provenienza === 'A') ?
              <Card.Link as={Link} to={Routes.DashboardAnagrafica.path} className="fw-bold text-dark hover:underline d-inline-flex align-items-center mb-2 mb-lg-0">
                <UsersIcon className="icon icon-xs me-2" /> Torna alle Anagrafiche
              </Card.Link>
              :
              (provenienza === 'D') ?
                <Card.Link as={Link} to={Routes.DashboardDisponibilita.path} className="fw-bold text-dark hover:underline d-inline-flex align-items-center mb-2 mb-lg-0">
                  <InformationCircleIcon className="icon icon-xs me-2" /> Torna alle Disponibilità
                </Card.Link>
                :
                (provenienza === 'C') ?
                  <Card.Link as={Link} to={Routes.DashboardConferme.path} className="fw-bold text-dark hover:underline d-inline-flex align-items-center mb-2 mb-lg-0">
                    <CheckCircleIcon className="icon icon-xs me-2" /> Torna alle Conferme
                  </Card.Link>
                  :
                  null
          }
        </Col>
      </Row>
      <div>
        <Tab.Container defaultActiveKey="home">
          <Nav fill variant="pills" className="flex-column flex-sm-row" style={{ 'marginLeft': '0', 'marginRight': '0' }}>
            <Nav.Item>
              <Nav.Link eventKey="home" className="mb-sm-3 mb-md-0">
                Dati Generali
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="dettagli" className="mb-sm-3 mb-md-0">
                Dettaglio Collaboratore
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="mb-9">
            <Tab.Pane eventKey="home" className="py-4">
              <Card as="form" border="0" className="shadow pb-4 mb-4">
                <Card.Body className="p-0 p-md-4">
                  <Row className="justify-content-center">
                    <Col xs={12} lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control type="text"
                          onChange={(e) => {
                            var newItem = (currentItem === undefined) ? {} : JSON.parse(JSON.stringify(currentItem));
                            newItem['nome'] = e.target.value;
                            setCurrentItem(newItem);
                          }}
                          value={(currentItemKey('nome') !== undefined) ? currentItemKey('nome') : ''} />
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Cognome</Form.Label>
                        <Form.Control type="text"
                          onChange={(e) => {
                            var newItem = (currentItem === undefined) ? {} : JSON.parse(JSON.stringify(currentItem));
                            newItem['cognome'] = e.target.value;
                            setCurrentItem(newItem);
                          }}
                          value={(currentItemKey('cognome') !== undefined) ? currentItemKey('cognome') : ''} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col xs={12} lg={4}>
                      <Form.Group id="birthday">
                        <Form.Label>Data di nascita</Form.Label>
                        <Datetime
                          locale="it"
                          timeFormat={false}
                          dateFormat={"DD/MM/YYYY"}
                          inputProps={{
                            disabled: false
                          }}
                          onChange={(e) => {
                            console.log(e.format("DD-MM-YYYY"));
                            var newItem = (currentItem === undefined) ? {} : JSON.parse(JSON.stringify(currentItem));
                            newItem['data_nascita'] = e.format("DD/MM/YYYY");
                            setCurrentItem(newItem);
                          }}
                          renderInput={(props, openCalendar) => (
                            <InputGroup>
                              <InputGroup.Text>
                                <CalendarIcon className="icon icon-xs" />
                              </InputGroup.Text>
                              <Form.Control
                                required
                                type="text"
                                value={getDataNascita()}
                                placeholder="dd/mm/yyyy"
                                onFocus={openCalendar}
                                onChange={(e) => {
                                  var newItem = (currentItem === undefined) ? {} : JSON.parse(JSON.stringify(currentItem));
                                  newItem['data_nascita'] = e.target.value;
                                  setCurrentItem(newItem);
                                }} />
                            </InputGroup>
                          )} />
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Telefono</Form.Label>
                        <Form.Control type="text"
                          onChange={(e) => {
                            var newItem = (currentItem === undefined) ? {} : JSON.parse(JSON.stringify(currentItem));
                            newItem['telefono'] = e.target.value;
                            setCurrentItem(newItem);
                          }}
                          value={(currentItemKey('telefono') !== undefined) ? currentItemKey('telefono') : ''} />

                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="name@example.com"
                          onChange={(e) => {
                            var newItem = (currentItem === undefined) ? {} : JSON.parse(JSON.stringify(currentItem));
                            newItem['email'] = e.target.value;
                            setCurrentItem(newItem);
                          }}
                          value={(currentItemKey('email') !== undefined) ? currentItemKey('email') : ''} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col xs={12} lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Città di Residenza</Form.Label>
                        <Form.Control type="text"
                          onChange={(e) => {
                            var newItem = (currentItem === undefined) ? {} : JSON.parse(JSON.stringify(currentItem));
                            newItem['residenza'] = e.target.value;
                            setCurrentItem(newItem);
                          }}
                          value={(currentItemKey('residenza') !== undefined) ? currentItemKey('residenza') : ''} />

                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Upload CV</Form.Label>
                        <Form.Control type="file"
                          onChange={handleFileChange} />
                      </Form.Group>
                      {
                        (currentItemKey('cv') !== undefined) ?
                          <Button variant="info" size="sm" className="ms-2 d-inline-flex align-items-center" onClick={() => {
                            var myURL = 'backend/' + currentItemKey('cv');
                            window.open(myURL, '_blank');
                          }}>
                            <FolderOpenIcon className="icon icon-xs me-2" /> Apri File
                          </Button>
                          :
                          null
                      }
                      <div></div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Tab.Pane>
            <Tab.Pane eventKey="dettagli" className="py-4">
              <Card as="form" border="0" className="shadow pb-4 mb-4">
                <Card.Body className="p-0 p-md-4">
                  <Row className="justify-content-center">
                    <Col xs={12} lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Assistente Bagnante</Form.Label>
                        <Form.Check
                          type="switch"
                          label={(currentItemKey('assistente_bagnante') !== undefined) ? (currentItemKey('assistente_bagnante') === 'SI') ? 'In possesso Brevetto' : 'Non in possesso del Brevetto' : 'Non in possesso del Brevetto'}
                          id="switchAssbagnante"
                          htmlFor="switchAssbagnante"
                          checked={(currentItemKey('assistente_bagnante') === 'SI')}
                          onChange={(e) => {
                            var newItem = (currentItem === undefined) ? {} : JSON.parse(JSON.stringify(currentItem));
                            if (e.target.checked) {
                              newItem['assistente_bagnante'] = 'SI';
                            }
                            else {
                              newItem['assistente_bagnante'] = 'NO';
                            }
                            setCurrentItem(newItem);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col xs={12} lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Certificazione BLSD</Form.Label>
                        <Form.Check
                          type="switch"
                          label={(currentItemKey('blsd') !== undefined) ? (currentItemKey('blsd') === 'SI') ? 'In possesso Certificazione' : 'Non in possesso della Certificazione' : 'Non in possesso della Certificazione'}
                          id="switchBLSD"
                          htmlFor="switchBLSDe"
                          checked={(currentItemKey('blsd') === 'SI')}
                          onChange={(e) => {
                            var newItem = (currentItem === undefined) ? {} : JSON.parse(JSON.stringify(currentItem));
                            if (e.target.checked) {
                              newItem['blsd'] = 'SI';
                            }
                            else {
                              newItem['blsd'] = 'NO';
                            }
                            setCurrentItem(newItem);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col xs={12} lg={4}>
                      <Form.Group id="titolo_studio" className="mb-4">
                        <Form.Label>Titolo di Studio</Form.Label>
                        <Form.Select id="titolo_studio" value={(currentItem === undefined) ? 'XXX' : (currentItem['titolo_studio'] !== undefined) ? currentItem['titolo_studio'] : 'XXX'}
                          onChange={(e) => {
                            var newItem = (currentItem === undefined) ? {} : JSON.parse(JSON.stringify(currentItem));
                            newItem['titolo_studio'] = e.target.value;
                            setCurrentItem(newItem);
                          }}>
                          <option value="XXX">Seleziona un Titolo di Studio ...</option>
                          {
                            titoliStudioList.map((item) => (
                              <option key={'TS_' + item['id']} value={item['descrizione']}>{item['descrizione']}</option>
                            ))
                          }
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Group id="livello_inglese" className="mb-4">
                        <Form.Label>Livello Inglese</Form.Label>
                        <Form.Select id="livello_inglese" value={(currentItem === undefined) ? 'XXX' : (currentItem['livello_inglese'] !== undefined) ? currentItem['livello_inglese'] : 'XXX'}
                          onChange={(e) => {
                            var newItem = (currentItem === undefined) ? {} : JSON.parse(JSON.stringify(currentItem));
                            newItem['livello_inglese'] = e.target.value;
                            setCurrentItem(newItem);
                          }}>
                          <option value="XXX">Seleziona un Livello Inglese ...</option>
                          {
                            livelloIngleseList.map((item) => (
                              <option key={'LI' + item['id']} value={item['descrizione']}>{item['descrizione']}</option>
                            ))
                          }
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={4}>
                      <Form.Group id="tipo_contratto" className="mb-4">
                        <Form.Label>Tipo Contratto</Form.Label>
                        <Form.Select id="tipo_contratto" value={(currentItem === undefined) ? 'XXX' : (currentItem['tipo_contratto'] !== undefined) ? currentItem['tipo_contratto'] : 'XXX'}
                          onChange={(e) => {
                            var newItem = (currentItem === undefined) ? {} : JSON.parse(JSON.stringify(currentItem));
                            newItem['tipo_contratto'] = e.target.value;
                            setCurrentItem(newItem);
                          }}>
                          <option value="XXX">Seleziona un Tipo Contratto ...</option>
                          {
                            tipoContrattoList.map((item) => (
                              <option key={'TC_' + item['id']} value={item['descrizione']}>{item['descrizione']}</option>
                            ))
                          }
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};