
import React, { useState } from "react";
import { ArrowLeftIcon, ArrowNarrowDownIcon, ArrowNarrowUpIcon, ArrowRightIcon, CheckCircleIcon, ChevronDownIcon, ChevronUpIcon, DotsHorizontalIcon, ExternalLinkIcon, EyeIcon, InformationCircleIcon, PencilAltIcon, ShieldExclamationIcon, TrashIcon, UserRemoveIcon, XCircleIcon } from "@heroicons/react/solid";
import { Col, Row, Nav, Card, Form, Image, Button, Table, Dropdown, ProgressBar, Pagination, Tooltip, FormCheck, ButtonGroup, OverlayTrigger } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from "recoil";
import { UtentiListSelector, loginUserSelector } from "data/selectors";
import Swal from 'sweetalert2';
import { actionUtenti } from "data/integrations";
import withReactContent from "sweetalert2-react-content";
import { DetailsUtentiModal } from "./DetailsUtenti";
import { currentPageUtentiAtom, editUtentiDetailModalAtom, myUtentiAtom, rowsPerPageUtentiAtom, showUtentiDetailModalAtom, utentiItemAtom, utentiRefreshAtom, utentiSearchAtom } from "data/atom";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary me-3',
        cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));


export const UtilityTable = (props) => {
    const [myLoginUser, setLoginUser] = useRecoilState(loginUserSelector);
    const { mydata = [], allSelected } = props;
    const [bulkOption, setBulkOption] = useState('delete_user');
    const disabledBulkMenu = mydata.filter(u => u.isSelected).length === 0;
    const myUsers = useRecoilValue(UtentiListSelector);
    const [currentPage, setCurrentPage] = useRecoilState(currentPageUtentiAtom);
    let [rowsPerPage, setRowsPerPage] = useRecoilState(rowsPerPageUtentiAtom);
    const [utilityRefresh, setRefreshUtility] = useRecoilState(utentiRefreshAtom);
    const [utilityAtom, setUtilityAtom] = useRecoilState(myUtentiAtom);
    const [searchValueUtility, setSearchValueUtility] = useRecoilState(utentiSearchAtom);

    const [show, setShowModal] = useRecoilState(showUtentiDetailModalAtom);
    const [edit, setEditModal] = useRecoilState(editUtentiDetailModalAtom);
    const [itemModal, setItemModal] = useRecoilState(utentiItemAtom);

    function quantiUsers() {
        if (searchValueUtility.length === 0) {
            var quanti = myUsers.length;
            return quanti;
        }
        else {
            var newUsers = myUsers.filter(u => (u.descrizione.toLowerCase().includes(searchValueUtility.toLowerCase())));
            return newUsers.length;
        }
    }

    const onAddItem = async (obj) => {
        var myObj = {};
        myObj['action'] = 'add_item';
        myObj['subject'] = 'users';
        myObj['obj'] = obj;

        await actionUtenti(myObj);
        var refs = utilityRefresh;
        refs++;
        setRefreshUtility(refs);
        setUtilityAtom(undefined);
        setSearchValueUtility("");
        const confirmMessage = "L'utente è stato aggiunto con successo";

        await SwalWithBootstrapButtons.fire('Inserimento completato', confirmMessage, 'success');
        setShowModal(false);
    };

    const onUpdateItem = async (obj) => {
        var myObj = {};
        myObj['action'] = 'update_item';
        myObj['subject'] = 'users';
        myObj['obj'] = obj;

        await actionUtenti(myObj);
        var refs = utilityRefresh;
        refs++;
        setRefreshUtility(refs);
        setUtilityAtom(undefined);
        setSearchValueUtility("");
        const confirmMessage = "L'utente " + obj['nome'] + " " + obj['cognome'] + " e  è stato aggiornato con successo";

        await SwalWithBootstrapButtons.fire('Aggiornamento completato', confirmMessage, 'success');
        setShowModal(false);
    };

    const selectUser = (id) => {
        props.selectUser && props.selectUser(id);
    };

    const selectAllUsers = () => {
        props.selectAllUsers && props.selectAllUsers();
    };

    const bulkActionChange = (e) => {
        const newOption = e.target.value;
        setBulkOption(newOption);
    }

    const applyBulkAction = () => {
        if (bulkOption === "delete_user") deleteUsers();
    }

    const deleteUsers = (ids) => {
        props.deleteUsers && props.deleteUsers(ids)
    }

    const TableRow = (props) => {
        const { id, nome, cognome, tipo, isSelected } = props;
        if ((myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') || ((myLoginUser['id'] === id) && ((myLoginUser['tipo'] === 1 || myLoginUser['tipo'] === '1')))) {
            return (
                <tr className="border-bottom">
                    <td>
                        <FormCheck type="checkbox" className="dashboard-check">
                            <FormCheck.Input id={`user-${id}`} checked={isSelected} onChange={() => selectUser(id)} />
                            <FormCheck.Label htmlFor={`user-${id}`} />
                        </FormCheck>
                    </td>
                    <td>
                        <span className="fw-normal">{id}</span>
                    </td>
                    <td>
                        <span className="fw-normal">{nome}</span>
                    </td>
                    <td>
                        <span className="fw-normal">{cognome}</span>
                    </td>
                    <td>
                        <span className="fw-normal">{(tipo === '0' || tipo === 0) ? "Amministratore" : "Visualizzatore"}</span>
                    </td>
                    <td>
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                <DotsHorizontalIcon className="icon icon-xs icon-dark" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="py-0">
                                {(myLoginUser['id'] === id) ?
                                    <Dropdown.Item onClick={() => {
                                        setEditModal(true);
                                        var myItemModal = {};
                                        myItemModal = mydata.filter(u => u.id === id)[0];
                                        console.log(myItemModal);
                                        setItemModal(myItemModal);
                                        setShowModal(true);
                                    }}>
                                        <PencilAltIcon className="icon icon-xs me-2" /> Modifica Password
                                    </Dropdown.Item>
                                    :
                                    null
                                }
                                {(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                                    <Dropdown.Item onClick={async () => {
                                        const result = await SwalWithBootstrapButtons.fire({
                                            icon: "error",
                                            title: "Conferma Eliminazione",
                                            text: "Sei sicuro di voler eliminare l'elemento con ID: " + id + " e Nominativo: " + nome + " " + cognome,
                                            showCancelButton: true,
                                            confirmButtonText: "Si",
                                            cancelButtonText: "Annulla"
                                        });

                                        if (result.isConfirmed) {
                                            var myObj = {};
                                            myObj['action'] = 'delete_item';
                                            myObj['subject'] = 'users';
                                            myObj['obj'] = {};
                                            myObj['obj']['id'] = id;
                                            await actionUtenti(myObj);
                                            var refs = utilityRefresh;
                                            refs++;
                                            setRefreshUtility(refs);
                                            setUtilityAtom(undefined);
                                            setSearchValueUtility("");
                                            const confirmMessage = "L'elemento ID: " + id + " è stato eliminato con successo";

                                            await SwalWithBootstrapButtons.fire('Eliminazione completata', confirmMessage, 'success');
                                        }
                                    }}>
                                        <TrashIcon className="icon icon-xs text-danger me-2" /> Rimuovi
                                    </Dropdown.Item>
                                    :
                                    null
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            );
        }
        else return null;
    };

    return (
        <Card border="0" className="table-wrapper table-responsive shadow">
            <Card.Body>
                {(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
                    <div className="d-flex mb-3">
                        <Form.Select className="fmxw-200" disabled={disabledBulkMenu} value={bulkOption} onChange={bulkActionChange}>
                            <option value="delete_user">Cancella Selezionati</option>
                        </Form.Select>
                        <Button variant="info" size="sm" className="ms-3" disabled={disabledBulkMenu} onClick={applyBulkAction}>
                            Applica
                        </Button>
                    </div>
                    :
                    null
                }
                <Table hover className="user-table align-items-left">
                    <thead>
                        <tr>
                            <th className="border-bottom">
                                <FormCheck type="checkbox" className="dashboard-check">
                                    <FormCheck.Input id="userCheckAll" checked={allSelected} onChange={selectAllUsers} />
                                    <FormCheck.Label htmlFor="userCheckAll" />
                                </FormCheck>
                            </th>
                            <th className="border-bottom">ID</th>
                            <th className="border-bottom">Nome</th>
                            <th className="border-bottom">Cognome</th>
                            <th className="border-bottom">Tipo</th>
                            <th className="border-bottom">Azione</th>
                        </tr>
                    </thead>
                    <tbody className="border-0">
                        {mydata.map(u => <TableRow key={`user-${u.id}`} {...u} />)}
                    </tbody>
                </Table>
                <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <Nav>
                        <Pagination className="mb-0">
                            <Pagination.Prev onClick={() => {
                                setCurrentPage(currentPage - 1);
                            }}
                                disabled={currentPage === 0}>
                                <ArrowLeftIcon className="icon icon-xs text-danger me-2" />
                            </Pagination.Prev>
                            <Pagination.Next onClick={() => {
                                setCurrentPage(currentPage + 1)
                            }}
                                disabled={
                                    (currentPage + 1) === (Math.ceil(quantiUsers() / rowsPerPage))
                                }>
                                <ArrowRightIcon className="icon icon-xs text-danger me-2" />
                            </Pagination.Next>
                        </Pagination>
                    </Nav>
                    <small className="fw-normal small mt-4 mt-lg-0">
                        Visualizzazione <b>{mydata.length + (currentPage * rowsPerPage)}</b> di <b>{quantiUsers()}</b> risultati
                    </small>
                </Card.Footer>
                <DetailsUtentiModal
                    edit={edit}
                    onAdd={onAddItem}
                    onUpdate={onUpdateItem}
                />
            </Card.Body>
        </Card>

    );
};