import { selector } from 'recoil';
import { anagraficaOrderItemAtom, anagraficaOrderItemTypeAtom, anagraficaRicercaObjAtom, anagraficaSearchAtom, anagraficheRefreshIncrementAtom, annoGestioneRefreshTokenAtom, camLocationDateRefreshTokenAtom, campLocalitaDateAtom, confermeOrderItemAtom, confermeOrderItemTypeAtom, confermeRefreshIncrementAtom, confermeRicercaObjAtom, confermeSearchAtom, confermeSetAtom, confermeStatoTypeAtom, currentPageAnagraficaAtom, currentPageConfermeAtom, currentPageDisponibilitaAtom, currentPageUtentiAtom, currentPageUtilityAtom, currentPageUtilityAtomCamp, disponibilitaAllAtom, disponibilitaCurrentItemAtom, disponibilitaOrderItemAtom, disponibilitaOrderItemTypeAtom, disponibilitaRefreshIncrementAtom, disponibilitaRicercaObjAtom, disponibilitaSearchAtom, gestioneCampLocationOrderItemAtom, gestioneCampOrderItemAtom, gestioneCampOrderItemTypeAtom, loginTokenAtom, loginUserAtom, myAnagraficaDataAtom, myConfermeDataAtom, myDisponibilitaDataAtom, myUtentiAtom, myUtilityAtom, myUtilityAtomCamp, refreshTokenAtom, rowsPerPageAnagraficaAtom, rowsPerPageConfermeAtom, rowsPerPageDisponibilitaAtom, rowsPerPageUtentiAtom, rowsPerPageUtilityAtom, rowsPerPageUtilityAtomCamp, selectedLocationCampAtom, selectedLocationDataCampAtom, selectedTipoTabellaTrasportiAtom, statoDisponibilitaAtom, tipoUtilityAtom, tipoUtilityAtomCamp, utentiRefreshAtom, utilityRefreshAtom, utilityRefreshAtomCamp, utilitySearchAtom, utilitySearchAtomCamp } from './atom';
import { estraiAnagrafiche, estraiAnnoGestione, estraiCamp, estraiConferme, estraiDateDisponibilita, estraiDiscipline, estraiDisponibilita, estraiListaCamp, estraiLivelloInglese, estraiLocation, estraiLocationBusById, estraiLocationByTrasporto, estraiLocationDateByCamp, estraiLocationDateById, estraiLocationSelect, estraiLocationTrenoById, estraiRuoli, estraiTipoContratto, estraiTitoliStudio, estraiTrasportiBus, estraiTrasportiTreno, estraiUtenti } from './integrations';
import moment from "moment-timezone";
import 'moment/locale/it';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ?
        (a, b) => descendingComparator(a, b, orderBy) :
        (a, b) => -descendingComparator(a, b, orderBy);
}

export const tokenSelector = selector(
    {
        key: 'tokenSelector',
        get: ({ get }) => {
            get(loginTokenAtom);
            get(refreshTokenAtom);
            const tokenString = sessionStorage.getItem('token');
            return tokenString;
        },
        set: ({ set }, newValue) => {
            set(loginUserAtom, JSON.parse(atob(newValue)));
            sessionStorage.setItem('token', newValue);
            set(loginTokenAtom, newValue);
        }
    }
)

export const loginUserSelector = selector(
    {
        key: 'loginUserSelector',
        get: ({ get }) => {
            get(loginUserAtom);
            const login = sessionStorage.getItem('token');
            if (login === null) {
                return {
                    'nome': '',
                    'cognome': '',
                    'tipo': '0',
                    'foto': 'https://www.reclutamentoxpcamp.it/iconaSidebar.png'
                }
            }
            else {
                return JSON.parse(atob(login));
            }
        },
        set: ({ set }, newValue) => {
            set(loginUserAtom, newValue);
            set(loginTokenAtom, btoa(JSON.stringify(newValue)));
        }
    }
)

export const listaRuoliSelector = selector(
    {
        key: 'listaRuoliSelector',
        get: async ({ get }) => {
            get(anagraficheRefreshIncrementAtom);
            let data = await estraiRuoli();

            return data.obj;
        }
    }
)

export const listaDisciplineSelector = selector(
    {
        key: 'listaDisciplineSelector',
        get: async ({ get }) => {
            get(anagraficheRefreshIncrementAtom);
            let data = await estraiDiscipline();

            return data.obj;
        }
    }
)

export const listaAnagraficheSelector = selector(
    {
        key: 'listaAnagraficheSelector',
        get: async ({ get }) => {
            get(anagraficheRefreshIncrementAtom);
            let data = await estraiAnagrafiche();

            return data.obj;
        }
    }
)

export const TitoliStudioListSelector = selector(
    {
        key: 'TitoliStudioListSelector',
        get: async ({ get }) => {
            var data = await estraiTitoliStudio();
            return data;
        }
    });

export const TipoContrattoListSelector = selector(
    {
        key: 'TipoContrattoListSelector',
        get: async ({ get }) => {
            var data = await estraiTipoContratto();
            return data;
        }
    });

export const LivelloIngleseListSelector = selector(
    {
        key: 'LivelloIngleseListSelector',
        get: async ({ get }) => {
            var data = await estraiLivelloInglese();
            return data;
        }
    });

export const TrasportoBusListSelector = selector(
    {
        key: 'TrasportoBusListSelector',
        get: async ({ get }) => {
            var data = await estraiTrasportiBus();
            return data;
        }
    });

export const TrasportoTrenoListSelector = selector(
    {
        key: 'TrasportoTrenoListSelector',
        get: async ({ get }) => {
            var data = await estraiTrasportiTreno();
            return data;
        }
    });

export const UtilityListSelector = selector(
    {
        key: 'UtilityListSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtom);
            let tipo = get(tipoUtilityAtom);
            let data = [];
            switch (tipo) {
                case 'livello_inglese':
                    data = await estraiLivelloInglese();
                    break;
                case 'titoli_studio':
                    data = await estraiTitoliStudio();
                    break;
                case 'tipo_contratto':
                    data = await estraiTipoContratto();
                    break;
                case 'ruoli':
                    data = await estraiRuoli();
                    break;
                case 'discipline':
                    data = await estraiDiscipline();
                    break;
                case 'trasporto_treno':
                    data = await estraiTrasportiTreno();
                    break;
                case 'trasporto_bus':
                    data = await estraiTrasportiBus();
                    break;
                default:
                    break;
            }
            return data;
        }
    }
)

export const titoloUtilitySelector = selector(
    {
        key: 'titoloUtilitySelector',
        get: ({ get }) => {
            get(utilityRefreshAtom);
            let tipo = get(tipoUtilityAtom);
            switch (tipo) {
                case 'livello_inglese':
                    return 'Livello Ingelse';
                case 'titoli_studio':
                    return 'Titolo di Studio';
                case 'tipo_contratto':
                    return 'Tipo di Contratto';
                case 'ruoli':
                    return 'Lista Ruoli';
                case 'discipline':
                    return 'Lista Discipline';
                case 'trasporto_treno':
                    return 'Lista Stazioni Trasporti Treno';
                case 'trasporto_bus':
                    return 'Lista Stazioni Trasporti Bus';
                default:
                    return '';
            }
        }
    }
)


export const usersSelector = selector(
    {
        key: 'usersSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtom);
            var data = get(myUtilityAtom);
            var page = get(currentPageUtilityAtom);
            var rowsPerPage = get(rowsPerPageUtilityAtom);
            var toMap = false;
            if (data === undefined) {
                toMap = true;
                data = await get(UtilityListSelector);
            }

            var newData = JSON.parse(JSON.stringify(data));

            var searchText = get(utilitySearchAtom);
            if (searchText.length > 0) {
                newData = newData.filter(u => (u.descrizione.toLowerCase().includes(searchText.toLowerCase())));
            }
            if (toMap === true) {
                return newData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(u => ({ ...u, isSelected: false, show: true }));
            }
            else {
                return newData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
            }
        },
        set: ({ set }, newValue) => {
            set(myUtilityAtom, newValue);
        }
    }
)

export const anagraficheSelector = selector(
    {
        key: 'anagraficheSelector',
        get: async ({ get }) => {
            get(anagraficheRefreshIncrementAtom);
            get(statoDisponibilitaAtom);
            var data = get(myAnagraficaDataAtom);
            var page = get(currentPageAnagraficaAtom);
            var rowsPerPage = get(rowsPerPageAnagraficaAtom);
            var toMap = false;
            if (data === undefined) {
                toMap = true;
                data = await get(listaAnagraficheSelector);
            }
            var searchText = get(anagraficaSearchAtom);

            var newData = JSON.parse(JSON.stringify(data));

            var orderBy = get(anagraficaOrderItemAtom);
            var orderType = get(anagraficaOrderItemTypeAtom);
            if (data.length > 0) {
                newData = newData.sort(getComparator(orderType, orderBy))
            }

            if (searchText.length > 0) {
                newData = newData.filter(u => u.nome.toLowerCase().includes(searchText.toLowerCase()) | u.cognome.toLowerCase().includes(searchText.toLowerCase()) | u.data_nascita.toLowerCase().includes(searchText.toLowerCase()) | u.email.toLowerCase().includes(searchText.toLowerCase()));
            }
            var itemSearch = get(anagraficaRicercaObjAtom);
            if(itemSearch['id'].length>0){
                newData = newData.filter(u => u.id.toLowerCase().includes(itemSearch['id'].toLowerCase()));
            }
            if(itemSearch['nome'].length>0){
                newData = newData.filter(u => u.nome.toLowerCase().includes(itemSearch['nome'].toLowerCase()));
            }
            if(itemSearch['cognome'].length>0){
                newData = newData.filter(u => u.cognome.toLowerCase().includes(itemSearch['cognome'].toLowerCase()));
            }
            if(itemSearch['email'].length>0){
                newData = newData.filter(u => u.email.toLowerCase().includes(itemSearch['email'].toLowerCase()));
            }
            if(itemSearch['data_nascita'].length>0){
                newData = newData.filter(u => u.data_nascita.toLowerCase().includes(itemSearch['data_nascita'].toLowerCase()));
            }

            if (toMap) {
                newData = newData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(u => ({ ...u, isSelected: false, show: true }));
            }
            else {
                newData = newData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
            }

            return newData;
        },
        set: ({ set }, newValue) => {
            set(myAnagraficaDataAtom, newValue);
        }
    }
)

export const annoGestioneSelector = selector(
    {
        key: 'annoGestioneSelector',
        get: async ({ get }) => {
            get(annoGestioneRefreshTokenAtom);
            var data = await estraiAnnoGestione();
            return data;
        }
    }
)


export const campAllItemsSelector = selector(
    {
        key: 'campAllItemsSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtomCamp);
            let tipo = get(tipoUtilityAtomCamp);
            let data = [];
            switch (tipo) {
                case 'location':
                    data = await estraiLocation();
                    break;
                case 'lista_camp':
                    data = await estraiListaCamp();
                    break;
                default:
                    break;
            }
            return data;
        }
    }
)

export const listaLocationSelector = selector(
    {
        key: 'listaLocationSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtomCamp);
            let data = [];

            data = await estraiLocation();

            return data;
        }
    }
)

export const locationSelectSelector = selector(
    {
        key: 'locationSelectSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtomCamp);
            let data = await estraiLocationSelect();
            let opts = [];
            if (data.length > 0) {
                data.forEach((element) => opts.push({ value: element.descrizione, label: element.descrizione }));
            }

            return opts;
        }
    }
)

export const locationDateSelectSelector = selector(
    {
        key: 'locationDateSelectSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtomCamp);
            let data = await estraiLocation();
            let opts = [];
            if (data.length > 0) {
                data.forEach((element) => opts.push({ value: element.id, label: element.descrizione }));
            }

            return opts;
        }
    }
)

export const campItemsSelector = selector(
    {
        key: 'campItemsSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtomCamp);
            var data = get(myUtilityAtomCamp);
            var page = get(currentPageUtilityAtomCamp);
            var rowsPerPage = get(rowsPerPageUtilityAtomCamp);
            var toMap = false;
            if (data === undefined) {
                toMap = true;
                data = await get(campAllItemsSelector);
            }

            var newData = JSON.parse(JSON.stringify(data));

            let tipo = get(tipoUtilityAtomCamp);

            var orderTypeLoc = get(gestioneCampLocationOrderItemAtom);
            var orderTypeCamp=get(gestioneCampOrderItemAtom);
            var orderBy = (tipo === 'location') ? orderTypeLoc : orderTypeCamp;
            var orderType = get(gestioneCampOrderItemTypeAtom);
            if (newData.length > 0) {
                
                if(tipo !== 'location') {
                    newData = newData.sort(getComparator(orderType, orderBy));
                }
            }

            var searchText = get(utilitySearchAtomCamp);
            if (searchText.length > 0) {
                newData = newData.filter(u => (u.descrizione.toLowerCase().includes(searchText.toLowerCase())));
            }

            if (toMap) {
                newData = newData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(u => ({ ...u, isSelected: false, show: true }));
            }
            else {
                newData = newData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
            }

            return newData;
        },
        set: ({ set }, newValue) => {
            set(myUtilityAtomCamp, newValue);
        }
    }
)

export const titoloUtilityCampSelector = selector(
    {
        key: 'titoloUtilityCampSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtomCamp);
            let tipo = get(tipoUtilityAtomCamp);
            let anno = get(annoGestioneSelector);
            switch (tipo) {
                case 'location':
                    return 'Location per Anno ' + anno;
                case 'lista_camp':
                    return 'Lista Camp per Anno ' + anno;
                case 'lista_trasporti':
                    return 'Tabella Trasporti ' + anno;
                default:
                    return '';
            }
        }
    }
);

export const locationDateSelectSelectorById = selector(
    {
        key: 'locationDateSelectSelectorById',
        get: async ({ get }) => {
            get(utilityRefreshAtomCamp);
            var loc = get(selectedLocationCampAtom);
            let data = await estraiLocationDateById(loc);
            let opts = [];
            if (data.length > 0) {
                data.forEach((element) => opts.push({ value: element.id, label: element.data_inizio + ' - ' + element.data_fine }));
            }

            return opts;
        }
    }
)

export const locationDisciplineSelector = selector(
    {
        key: 'locationDisciplineSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtomCamp);
            let data = await estraiListaCamp();
            let opts = [];
            if (data.length > 0) {
                data.forEach((element) => opts.push({ value: element.descrizione, label: element.descrizione }));
                opts = opts.sort(getComparator('asc', 'label'))
            }
            return opts;
        }
    }
)

export const listaDisponibilitaAllSelector = selector(
    {
        key: 'listaDisponibilitaAllSelector',
        get: async ({ get }) => {
            get(disponibilitaRefreshIncrementAtom);
            get(confermeRefreshIncrementAtom);
            var prop = get(statoDisponibilitaAtom);
            let data = await estraiDisponibilita(prop);

            data = data.obj;
            if (data.length > 0) {
                data = data.filter(u => (prop === 'N') ? u.proposta === 'N' : true)
            }
            return data;
        }
    }
)

export const listaDisponibilitaSelector = selector(
    {
        key: 'listaDisponibilitaSelector',
        get: async ({ get }) => {
            get(disponibilitaRefreshIncrementAtom);
            get(confermeRefreshIncrementAtom);
            var prop = get(statoDisponibilitaAtom);
            let data = await estraiDisponibilita(prop);

            data = data.obj;
            if (data.length > 0) {
                data = data.filter(u => (prop === 'N') ? u.proposta === 'N' : true)
            }
            return data;
        }
    }
)

export const disponibilitaSelector = selector(
    {
        key: 'disponibilitaSelector',
        get: async ({ get }) => {
            get(confermeRefreshIncrementAtom);
            get(currentPageDisponibilitaAtom);
            get(rowsPerPageDisponibilitaAtom);
            get(disponibilitaSearchAtom);

            var data = get(myDisponibilitaDataAtom);
            var toMap = false;
            if (data === undefined) {
                toMap = true;
                data = await get(listaDisponibilitaSelector)
            }

            var newData = JSON.parse(JSON.stringify(data));

            if (toMap) {
                newData = newData.map(u => ({ ...u, isSelected: false, show: true }));
            }

            return newData;
        },
        set: ({ get, set }, newValue) => {
            get(disponibilitaRefreshIncrementAtom);
            set(myDisponibilitaDataAtom, newValue);
        }
    }
)

export const listaAnagraficheDisponibilitaSelector = selector(
    {
        key: 'listaAnagraficheDisponibilitaSelector',
        get: async ({ get }) => {
            get(anagraficheRefreshIncrementAtom);
            let data = await estraiAnagrafiche();
            data = data.obj;

            let opts = [];
            if (data.length > 0) {
                data.forEach((element) => opts.push({ value: element.id, label: element.cognome + ' ' + element.nome }));
                opts = opts.sort(getComparator('asc', 'label'))
            }
            return opts;

        }
    }
)

function descendingDateComparator(a, b, orderBy1) {
    let date1 = moment(a[orderBy1]);
    let date2 = moment(b[orderBy1]);
    let difference = date1.diff(date2, "days");

    if (difference > 0) {
        return -1;
    }
    if (difference < 0) {
        return 1;
    }

    return 0;
}

function getDateComparator(order, orderBy1) {
    return order === 'desc' ?
        (a, b) => descendingDateComparator(a, b, orderBy1) :
        (a, b) => -descendingDateComparator(a, b, orderBy1);
}

export const listaDateDisponibilitaSelector = selector(
    {
        key: 'listaDateDisponibilitaSelector',
        get: async ({ get }) => {
            get(disponibilitaRefreshIncrementAtom);
            var anag = get(disponibilitaCurrentItemAtom);
            if (anag === undefined) {
                return [];
            }
            else {
                let data = await estraiDateDisponibilita(anag.value);
                data = data.obj;

                let opts = [];
                if (data.length > 0) {
                    data.forEach((element) => opts.push({ value: element.data_disponibilita, label: element.data_disponibilita, inizio: moment(element.data_disponibilita.split("-")[0].trim(), "DD/MM/YYY").format("YYYYMMDD") }));
                    opts = opts.sort(getComparator('asc', 'inizio'))
                }
                return opts;
            }
        }
    }
)

export const listaCollaborazioniSelector = selector(
    {
        key: 'listaCollaborazioniSelector',
        get: async ({ get }) => {
            let year = await get(annoGestioneSelector);
            if (year < 2024) {
                year = 2024;
            }

            let opts = [];

            for (let y = year; y >= 2024; y--) {
                opts.push({ value: y, label: y })
            }
            opts.push({ value: '2023 e prec.', label: '2023 e prec.' })
            return opts;

        }
    }
)

export const listaRuoliDisponibilitaSelector = selector(
    {
        key: 'listaRuoliDisponibilitaSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtom);
            let opts = [];

            opts.push({ value: 'Non selezionato', label: 'Non selezionato' })

            let data = await estraiRuoli();
            if (data.length > 0) {
                data.forEach((element) => opts.push({ value: element.descrizione, label: element.descrizione }));
            }
            return opts;

        }
    }
)

export const listaTipoDisciplinaDisponibilitaSelector = selector(
    {
        key: 'listaTipoDisciplinaDisponibilitaSelector',
        get: async ({ get }) => {
            let opts = [];
            get(utilityRefreshAtom);

            opts.push({ value: 'Sportiva', label: 'Sportiva' })
            opts.push({ value: 'Tematica', label: 'Tematica' })
            opts.push({ value: 'Sanitaria', label: 'Sanitaria' })
            opts.push({ value: 'Neutra', label: 'Neutra' })

            return opts;

        }
    }
)

export const listaDisciplineSportiveDisponibilitaSelector = selector(
    {
        key: 'listaDisciplineSportiveDisponibilitaSelector',
        get: async ({ get }) => {
            let opts = [];
            get(utilityRefreshAtom);

            opts.push({ value: 'Non selezionato', label: 'Non selezionato' })

            let data = await estraiDiscipline();
            data = data.filter(element => element.tipologia === 'Sportiva');
            if (data.length > 0) {
                data.forEach((element) => opts.push({ value: element.descrizione, label: element.descrizione }));
            }

            return opts;

        }
    }
)

export const listaDisciplineTematicheDisponibilitaSelector = selector(
    {
        key: 'listaDisciplineTematicheDisponibilitaSelector',
        get: async ({ get }) => {
            let opts = [];
            get(utilityRefreshAtom);

            opts.push({ value: 'Non selezionato', label: 'Non selezionato' })

            let data = await estraiDiscipline();
            data = data.filter(element => element.tipologia === 'Tematica');
            if (data.length > 0) {
                data.forEach((element) => opts.push({ value: element.descrizione, label: element.descrizione }));
            }

            return opts;

        }
    }
)

export const listaDisciplineMedicheDisponibilitaSelector = selector(
    {
        key: 'listaDisciplineMedicheDisponibilitaSelector',
        get: async ({ get }) => {
            let opts = [];
            get(utilityRefreshAtom);

            opts.push({ value: 'Non selezionato', label: 'Non selezionato' })

            let data = await estraiDiscipline();
            data = data.filter(element => element.tipologia === 'Sanitaria');
            if (data.length > 0) {
                data.forEach((element) => opts.push({ value: element.descrizione, label: element.descrizione }));
            }

            return opts;

        }
    }
)

export const listaDisciplineAltroDisponibilitaSelector = selector(
    {
        key: 'listaDisciplineAltroDisponibilitaSelector',
        get: async ({ get }) => {
            let opts = [];
            get(utilityRefreshAtom);

            opts.push({ value: 'Non selezionato', label: 'Non selezionato' })

            let data = await estraiDiscipline();
            data = data.filter(element => element.tipologia === 'Neutra');
            if (data.length > 0) {
                data.forEach((element) => opts.push({ value: element.descrizione, label: element.descrizione }));
            }

            return opts;

        }
    }
)

export const listaTreniDisponibilitaSelector = selector(
    {
        key: 'listaTreniDisponibilitaSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtom);
            let opts = [];

            opts.push({ value: 'Non selezionato', label: 'Non selezionato' })

            let data = await estraiTrasportiTreno();
            if (data.length > 0) {
                data.forEach((element) => opts.push({ value: element.descrizione, label: element.descrizione }));
            }
            return opts;

        }
    }
)

export const listaBusDisponibilitaSelector = selector(
    {
        key: 'listaBusDisponibilitaSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtom);
            let opts = [];

            opts.push({ value: 'Non selezionato', label: 'Non selezionato' })

            let data = await estraiTrasportiBus();
            if (data.length > 0) {
                data.forEach((element) => opts.push({ value: element.descrizione, label: element.descrizione }));
            }
            return opts;

        }
    }
)

export const listaConfermeSelector = selector(
    {
        key: 'listaConfermeSelector',
        get: async ({ get }) => {
            get(confermeRefreshIncrementAtom);
            get(disponibilitaRefreshIncrementAtom);
            let data = await estraiConferme();

            data = data.obj;
            return data;
        }
    }
)

export const confermeSelector = selector(
    {
        key: 'confermeSelector',
        get: async ({ get }) => {
            get(disponibilitaRefreshIncrementAtom);
            get(confermeRefreshIncrementAtom);
            get(confermeStatoTypeAtom);
            var data = get(myConfermeDataAtom);
            get(currentPageConfermeAtom);
            get(rowsPerPageConfermeAtom);
            get(confermeSearchAtom);
            var toMap = false;
            if (data === undefined) {
                toMap = true;
                data = await get(listaConfermeSelector);
            }

            var newData = JSON.parse(JSON.stringify(data));


            if (toMap) {
                newData = newData.map(u => ({ ...u, isSelected: false, show: true }));
            }

            return newData;
        },
        set: ({ get, set }, newValue) => {
            get(confermeRefreshIncrementAtom);
            get(confermeSetAtom);
            set(myConfermeDataAtom, newValue);
        }
    }
)

export const listaRuoliConfermeSelector = selector(
    {
        key: 'listaRuoliConfermeSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtom);
            let opts = [];

            let data = await estraiRuoli();
            if (data.length > 0) {
                data.forEach((element) => opts.push({ value: element.descrizione, label: element.descrizione }));
                opts = opts.sort(getComparator('asc', 'label'));
            }
            return opts;

        }
    }
)

export const listaStatiConfermeSelector = selector(
    {
        key: 'listaStatiConfermeSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtom);
            let opts = [];

            opts.push({ value: 0, label: 'Proposto' })
            opts.push({ value: 1, label: 'Accettato' })
            opts.push({ value: 2, label: 'Rifiutato' })
            opts.push({ value: 3, label: 'Stand-By' })


            return opts;

        }
    }
)

export const listaTrasportoTipoAndataConfermeSelector = selector(
    {
        key: 'listaTrasportoTipoAndataConfermeSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtom);
            let opts = [];

            opts.push({ value: 'Non selezionato', label: 'Non selezionato' })
            opts.push({ value: 'Bus', label: 'Bus' })
            opts.push({ value: 'Treno', label: 'Treno' })
            opts.push({ value: 'Autonomo', label: 'Autonomo' })

            opts.push({ value: 'Presente', label: 'Presente' });
            opts.push({ value: 'Trasf. Interno', label: 'Trasf. Interno' });

            return opts;

        }
    }
)

export const listaTrasportoTipoRitornoConfermeSelector = selector(
    {
        key: 'listaTrasportoTipoRitornoConfermeSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtom);
            let opts = [];

            opts.push({ value: 'Non selezionato', label: 'Non selezionato' })
            opts.push({ value: 'Bus', label: 'Bus' })
            opts.push({ value: 'Treno', label: 'Treno' })
            opts.push({ value: 'Autonomo', label: 'Autonomo' })

            opts.push({ value: 'Resta', label: 'Resta' });
            opts.push({ value: 'Trasf. Interno', label: 'Trasf. Interno' });

            return opts;

        }
    }
)

export const listaTreniConfermeSelector = selector(
    {
        key: 'listaTreniConfermeSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtom);
            let opts = [];

            opts.push({ value: 'Non selezionato', label: 'Non selezionato' })

            let data = await estraiTrasportiTreno();
            if (data.length > 0) {
                data.forEach((element) => opts.push({ value: element.descrizione, label: element.descrizione }));
                opts = opts.sort(getComparator('asc', 'label'));
            }

            return opts;

        }
    }
)

export const listaBusConfermeSelector = selector(
    {
        key: 'listaBusConfermeSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtom);
            let opts = [];

            opts.push({ value: 'Non selezionato', label: 'Non selezionato' })

            let data = await estraiTrasportiBus();
            if (data.length > 0) {
                data.forEach((element) => opts.push({ value: element.descrizione, label: element.descrizione }));
                opts = opts.sort(getComparator('asc', 'label'));
            }

            return opts;

        }
    }
)

export const listaDisciplineDisponibilitaSelector = selector(
    {
        key: 'listaDisciplineDisponibilitaSelector',
        get: async ({ get }) => {
            let opts = [];
            get(utilityRefreshAtom);

            opts.push({ value: 'Non selezionato', label: 'Non selezionato' })

            let data = await estraiDiscipline();
            if (data.length > 0) {
                data.forEach((element) => opts.push({ value: element.descrizione, label: element.descrizione }));
            }

            return opts;

        }
    }
)

export const UtentiListSelector = selector(
    {
        key: 'UtentiListSelector',
        get: async ({ get }) => {
            get(utentiRefreshAtom);
            let data = [];
            data = await estraiUtenti();
            return data;
        }
    }
)

export const utentiSelector = selector(
    {
        key: 'utentiSelector',
        get: async ({ get }) => {
            get(utentiRefreshAtom);
            var data = get(myUtentiAtom);
            var page = get(currentPageUtentiAtom);
            var rowsPerPage = get(rowsPerPageUtentiAtom);
            var toMap = false;
            if (data === undefined) {
                toMap = true;
                data = await get(UtentiListSelector);
            }

            var newData = JSON.parse(JSON.stringify(data));

            var searchText = get(utilitySearchAtom);
            if (searchText.length > 0) {
                newData = newData.filter(u => (u.nome.toLowerCase().includes(searchText.toLowerCase()) || u.cognome.toLowerCase().includes(searchText.toLowerCase())));
            }
            if (toMap === true) {
                return newData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(u => ({ ...u, isSelected: false, show: true }));
            }
            else {
                return newData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
            }
        },
        set: ({ set }, newValue) => {
            set(myUtentiAtom, newValue);
        }
    }
)

export const listaLocationdateByCampSelector = selector(
    {
        key: 'listaLocationdateByCampSelector',
        get: async ({ get }) => {
            get(camLocationDateRefreshTokenAtom);
            var camp_id=get(campLocalitaDateAtom);
            let opts = [];

            if(camp_id===-1){
                return opts;
            }

            let data = await estraiLocationDateByCamp(camp_id);
            return data;

        }
    }
)

export const listaTreniLocationSelector = selector(
    {
        key: 'listaTreniLocationSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtom);
            get(confermeRefreshIncrementAtom);
            get(annoGestioneRefreshTokenAtom);
            let opts = [];

            let data = await estraiTrasportiTreno();
            var trasporti = await get(locationTrenoSelectSelectorById);

            if (data.length > 0) {
                data.forEach((element) => {
                    var found = false;
                    for(var i=0;i<trasporti.length;i++){
                        if(trasporti[i].stazione===element.descrizione){
                            found=true;
                            break;
                        }
                    }
                    if(!found) opts.push({ value: element.descrizione, label: element.descrizione })
                });
            }
            return opts;

        }
    }
)

export const listaBusLocationSelector = selector(
    {
        key: 'listaBusLocationSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtom);
            get(confermeRefreshIncrementAtom);
            get(annoGestioneRefreshTokenAtom);
            let opts = [];

            let data = await estraiTrasportiBus();
            var trasporti = await get(locationBusSelectSelectorById);

            if (data.length > 0) {
                data.forEach((element) => {
                    var found = false;
                    for(var i=0;i<trasporti.length;i++){
                        if(trasporti[i].stazione===element.descrizione){
                            found=true;
                            break;
                        }
                    }
                    if(!found) opts.push({ value: element.descrizione, label: element.descrizione })
                });
            }
            return opts;

        }
    }
)

export const locationTrenoSelectSelectorById = selector(
    {
        key: 'locationTrenoSelectSelectorById',
        get: async ({ get }) => {
            get(utilityRefreshAtomCamp);
            get(confermeRefreshIncrementAtom);
            get(annoGestioneRefreshTokenAtom);
            var loc = get(selectedLocationCampAtom);
            let data = await estraiLocationTrenoById(loc);
            return data;
        }
    }
)

export const locationBusSelectSelectorById = selector(
    {
        key: 'locationBusSelectSelectorById',
        get: async ({ get }) => {
            get(utilityRefreshAtomCamp);
            get(confermeRefreshIncrementAtom);
            var loc = get(selectedLocationCampAtom);
            get(annoGestioneRefreshTokenAtom);
            let data = await estraiLocationBusById(loc);
            return data;
        }
    }
)

export const locationByTrasportoSelector = selector(
    {
        key: 'locationByTrasportoSelector',
        get: async ({ get }) => {
            get(utilityRefreshAtomCamp);
            get(confermeRefreshIncrementAtom);
            get(annoGestioneRefreshTokenAtom);
            let tipo = get(selectedTipoTabellaTrasportiAtom);
            let data = await estraiLocationByTrasporto(tipo);
            return data;
        }
    }
)