import { atom } from 'recoil';
import { listaDisponibilitaSelector } from './selectors';
import { findAllByTestId } from '@testing-library/react';

export const loginTokenAtom = atom({
    key: 'loginTokenAtom',
    default: undefined
});

export const refreshTokenAtom = atom({
    key: 'refreshTokenAtom',
    default: 0,
});

export const loginUserAtom = atom({
    key: 'loginUserAtom',
    default: undefined,
});

export const anagraficheRefreshIncrementAtom = atom({
    key: 'anagraficheRefreshIncrementAtom',
    default: 0,
});

export const statoAnagraficaAtom = atom({
    key: 'statoAnagraficaAtom',
    default: 'N',
});

export const currentPageAnagraficaAtom = atom({
    key: 'currentPageAnagraficaAtom',
    default: 0,
});

export const rowsPerPageAnagraficaAtom = atom({
    key: 'rowsPerPageAnagraficaAtom',
    default: 50,
});

export const anagraficaSearchAtom = atom({
    key: 'anagraficaSearchAtom',
    default: ''
});

export const myAnagraficaDataAtom = atom({
    key: 'myAnagraficaDataAtom',
    default: undefined
});

export const anagraficheCurrentItemAtom = atom({
    key: 'anagraficheCurrentItemAtom',
    default: undefined,
});

export const tipoUtilityAtom = atom(
    {
        key: 'tipoUtilityAtom',
        default: 'livello_inglese',
    }
);

export const utilityRefreshAtom = atom({
    key: 'utilityRefreshAtom',
    default: 0,
});

export const myUtilityAtom = atom({
    key: 'myUtilityAtom',
    default: undefined
});

export const utilitySearchAtom = atom({
    key: 'utilitySearchAtom',
    default: ''
});

export const currentPageUtilityAtom = atom({
    key: 'currentPageUtilityAtom',
    default: 0,
});

export const rowsPerPageUtilityAtom = atom({
    key: 'rowsPerPageUtilityAtom',
    default: 50,
});

export const showUtilityDetailModalAtom = atom({
    key: 'showUtilityDetailModalAtom',
    default: false,
});

export const editUtilityDetailModalAtom = atom({
    key: 'editUtilityDetailModalAtom',
    default: false,
});

export const utilityItemAtom = atom({
    key: 'utilityItemAtom',
    default: {'descrizione':''},
});

export const utilityItemAtomListaCamp = atom({
    key: 'utilityItemAtomListaCamp',
    default: {'descrizione':''},
});

export const utilityModalTitleAtom = atom({
    key: 'utilityModalTitleAtom',
    default: 'Aggiungi Nuovo Elemento',
});

export const anagraficaOrderItemAtom = atom({
    key: 'anagraficaOrderItemAtom',
    default: 'id',
});

export const anagraficaOrderItemTypeAtom = atom({
    key: 'anagraficaOrderItemTypeAtom',
    default: 'asc',
});

export const tipoUtilityAtomCamp = atom(
    {
        key: 'tipoUtilityAtomCamp',
        default: 'location',
    }
);

export const myUtilityAtomCamp = atom({
    key: 'myUtilityAtomCamp',
    default: undefined
});

export const utilitySearchAtomCamp = atom({
    key: 'utilitySearchAtomCamp',
    default: ''
});

export const utilityRefreshAtomCamp = atom({
    key: 'utilityRefreshAtomCamp',
    default: 0,
});

export const showUtilityDetailModalAtomListaCamp = atom({
    key: 'showUtilityDetailModalAtomListaCamp',
    default: false,
});

export const editUtilityDetailModalAtomListaCamp = atom({
    key: 'editUtilityDetailModalAtomListaCamp',
    default: false,
});

export const showUtilityDetailModalAtomCampLocation = atom({
    key: 'showUtilityDetailModalAtomCampLocation',
    default: false,
});

export const editUtilityDetailModalAtomCampLocation = atom({
    key: 'editUtilityDetailModalAtomCampLocation',
    default: false,
});

export const showUtilityDetailModalAtomCampLocationDate = atom({
    key: 'showUtilityDetailModalAtomCampLocationDate',
    default: false,
});

export const editUtilityDetailModalAtomCampLocationDate = atom({
    key: 'editUtilityDetailModalAtomCampLocationDate',
    default: false,
});

export const showUtilityDetailModalAtomCamp = atom({
    key: 'showUtilityDetailModalAtomCamp',
    default: false,
});

export const editUtilityDetailModalAtomCamp = atom({
    key: 'editUtilityDetailModalAtomCamp',
    default: false,
});

export const currentPageUtilityAtomCamp = atom({
    key: 'currentPageUtilityAtomCamp',
    default: 0,
});

export const rowsPerPageUtilityAtomCamp = atom({
    key: 'rowsPerPageUtilityAtomCamp',
    default: 50,
});

export const utilityModalTitleAtomCamp = atom({
    key: 'utilityModalTitleAtomCamp',
    default: 'Aggiungi Nuovo Elemento',
});

export const gestioneCampLocationOrderItemAtom = atom({
    key: 'gestioneCampLocationOrderItemAtom',
    default: 'descrizione',
});

export const gestioneCampOrderItemAtom = atom({
    key: 'gestioneCampOrderItemAtom',
    default: 'descrizione',
});

export const gestioneCampOrderItemTypeAtom = atom({
    key: 'gestioneCampOrderItemTypeAtom',
    default: 'asc',
});

export const utilityItemAtomCamp = atom({
    key: 'utilityItemAtomCamp',
    default: {'descrizione':''},
});

export const utilityItemAtomCampLocation = atom({
    key: 'utilityItemAtomCampLocation',
    default: {'descrizione':''},
});

export const utilityItemAtomCampLocationDate = atom({
    key: 'utilityItemAtomCampLocationDate',
    default: {},
});

const localStorageIsContracted = () => {
    return localStorage.getItem('sidebarContracted') === 'false' ? false : true
  }

export const sidebarContractedAtom = atom({
    key: 'sidebarContractedAtom',
    default: localStorageIsContracted(),
});

export const selectedLocationCampAtom = atom({
    key: 'selectedLocationCampAtom',
    default: 1,
});

export const selectedLocationDataCampAtom = atom({
    key: 'selectedLocationDataCampAtom',
    default: 1,
});

export const disponibilitaOrderItemAtom = atom({
    key: 'disponibilitaOrderItemAtom',
    default: 'nominativo',
});

export const disponibilitaOrderItemTypeAtom = atom({
    key: 'disponibilitaOrderItemTypeAtom',
    default: 'asc',
});

export const disponibilitaRefreshIncrementAtom = atom({
    key: 'disponibilitaRefreshIncrementAtom',
    default: 0,
});

export const currentPageDisponibilitaAtom = atom({
    key: 'currentPageDisponibilitaAtom',
    default: 0,
});

export const rowsPerPageDisponibilitaAtom = atom({
    key: 'rowsPerPageDisponibilitaAtom',
    default: 50,
});

export const disponibilitaSearchAtom = atom({
    key: 'disponibilitaSearchAtom',
    default: ''
});

export const myDisponibilitaDataAtom = atom({
    key: 'myDisponibilitaDataAtom',
    default: undefined
});

export const disponibilitaCurrentItemAtom = atom({
    key: 'disponibilitaCurrentItemAtom',
    default: undefined,
});

export const statoDisponibilitaAtom = atom({
    key: 'statoDisponibilitaAtom',
    default: 'N',
});

export const showDisponibilitaDetailModalAtom = atom({
    key: 'showDisponibilitaDetailModalAtom',
    default: false,
});

export const dateDisponibilitaCurrentItemAtom = atom({
    key: 'dateDisponibilitaCurrentItemAtom',
    default: undefined,
});

export const collaborazioneDisponibilitaCurrentItemAtom = atom({
    key: 'collaborazioneDisponibilitaCurrentItemAtom',
    default: {label: 2024, value: 2024},
});


export const showNoteDisponibilitaModalAtom = atom({
    key: 'showNoteDisponibilitaModalAtom',
    default: false,
});

export const disponibilitaNoteModalItemAtom = atom({
    key: 'disponibilitaNoteModalItemAtom',
    default: {
        item_id: 1,
        key: 'note',
        value: '',
        title: ''
    }
});

export const locationOrderItemAtom = atom({
    key: 'locationOrderItemAtom',
    default: 'id',
});

export const locationOrderItemTypeAtom = atom({
    key: 'locationOrderItemTypeAtom',
    default: 'asc',
});

export const campOrderItemAtom = atom({
    key: 'campOrderItemAtom',
    default: 'id',
});

export const campOrderItemTypeAtom = atom({
    key: 'campOrderItemTypeAtom',
    default: 'asc',
});

export const confermeRefreshIncrementAtom = atom({
    key: 'confermeRefreshIncrementAtom',
    default: 0,
});

export const currentPageConfermeAtom = atom({
    key: 'currentPageConfermeAtom',
    default: 0,
});

export const rowsPerPageConfermeAtom = atom({
    key: 'rowsPerPageConfermeAtom',
    default: 50,
});

export const confermeSearchAtom = atom({
    key: 'confermeSearchAtom',
    default: ''
});

export const myConfermeDataAtom = atom({
    key: 'myConfermeDataAtom',
    default: undefined
});

export const confermeOrderItemAtom = atom({
    key: 'confermeOrderItemAtom',
    default: 'nominativo',
});

export const confermeOrderItemTypeAtom = atom({
    key: 'confermeOrderItemTypeAtom',
    default: 'asc',
});

export const confermeStatoTypeAtom = atom({
    key: 'confermeStatoTypeAtom',
    default: 'T',
});

export const formAnagraficaProvenienza = atom({
    key: 'formAnagraficaProvenienza',
    default: 'A',
});

export const showDisponibilitaModalAtom = atom({
    key: 'showDisponibilitaModalAtom',
    default: false,
});

export const showDisponibilitaClonaModalAtom = atom({
    key: 'showDisponibilitaClonaModalAtom',
    default: false,
});

export const disponibilitaDettaglioModalAtom = atom({
    key: 'disponibilitaDettaglioModalAtom',
    default: {
        "id": -1,
        "id_anagrafica": -1,
        "proposta": "S",
        "ruolo": "",
        "collaborazione": "",
        "disciplina_tipo": "",
        "disciplina": [
            {
                "label": "Non selezionato",
                "value": "Non selezionato"
            }
        ],
        "date_disponibilita": "",
        "trasporto_bus": "Non selezionato",
        "trasporto_treno": "Non selezionato",
        "autonomo": "S",
        "note": "",
        "anno": "2024",
        "nominativo": "",
        "autonomo_testo": ""
    },
});

export const disponibilitaDettaglioClonaModalAtom = atom({
    key: 'disponibilitaDettaglioClonaModalAtom',
    default: {

    }
});

export const disponibilitaAllAtom = atom({
    key: 'disponibilitaAllAtom',
    default: undefined,
});

export const annoGestioneRefreshTokenAtom = atom({
    key: 'annoGestioneRefreshTokenAtom',
    default: 0,
});

export const utentiRefreshAtom = atom({
    key: 'utentiRefreshAtom',
    default: 0,
});

export const utentiSearchAtom = atom({
    key: 'utentiSearchAtom',
    default: ''
});

export const currentPageUtentiAtom = atom({
    key: 'currentPageUtentiAtom',
    default: 0,
});

export const rowsPerPageUtentiAtom = atom({
    key: 'rowsPerPageUtentiAtom',
    default: 50,
});

export const showUtentiDetailModalAtom = atom({
    key: 'showUtentiDetailModalAtom',
    default: false,
});

export const editUtentiDetailModalAtom = atom({
    key: 'editUtentiDetailModalAtom',
    default: false,
});

export const utentiItemAtom = atom({
    key: 'utentiItemAtom',
    default: {'nome':'','cognome':'','email':'','password':'','tipo':0},
});

export const myUtentiAtom = atom({
    key: 'myUtentiAtom',
    default: undefined
});

export const utentiModalTitleAtom = atom({
    key: 'utentiModalTitleAtom',
    default: 'Aggiungi Nuovo Utente',
});

export const campLocalitaDateAtom = atom({
    key: 'campLocalitaDateAtom',
    default: -1,
});

export const showDetailModalAtomCampLocationDate = atom({
    key: 'showDetailModalAtomCampLocationDate',
    default: false,
});

export const currentPageCampLocationDateAtom = atom({
    key: 'currentPageCampLocationDateAtom',
    default: 0,
});

export const camLocationDateRefreshTokenAtom = atom({
    key: 'camLocationDateRefreshTokenAtom',
    default: 0,
});

export const anagraficaRicercaObjAtom = atom(
    {
        key: 'anagraficaRicercaObjAtom',
        default : {
            "id": "",
            "nome": "",
            "cognome": "",
            "data_nascita": "",
            "email": ""
        }
    }
);

export const disponibilitaRicercaObjAtom = atom(
    {
        key: 'disponibilitaRicercaObjAtom',
        default : {
            "nominativo": "",
            "date_disponibilita": "",
            "collaborazione": "",
            "ruolo": "",
            "disciplina_tipo": "",
            "disciplina": "",
            "trasporto_bus": "",
            "trasporto_treno": "",
            "autonomo": ""
        }
    }
);

export const confermeRicercaObjAtom = atom(
    {
        key: 'confermeRicercaObjAtom',
        default : {
            "nominativo": "",
            "collaborazione": "",
            "ruolo": "",
            "date_disponibilita": "",
            "location": "",
            "camp": "",
            "trasporto_andata": "",
            "trasporto_ritorno": "",
        }
    }
);

export const showNoteConfermeModalAtom = atom({
    key: 'showNoteConfermeModalAtom',
    default: false,
});

export const confermeNoteModalItemAtom = atom({
    key: 'confermeNoteModalItemAtom',
    default: {
        item_id: 1,
        key: 'note',
        value: '',
        title: ''
    }
});

export const disponibilitaSetAtom = atom({
    key: 'disponibilitaSetAtom',
    default: findAllByTestId
});

export const confermeSetAtom = atom({
    key: 'confermeSetAtom',
    default: false
});

export const utilityItemAtomLocationTrasporto = atom({
    key: 'utilityItemAtomLocationTrasporto',
    default: {
        id_location: undefined,
        trasporto: []
    }
});

export const showUtilityDetailModalAtomLocationTrasportoTreno = atom({
    key: 'showUtilityDetailModalAtomLocationTrasportoTreno',
    default: false,
});

export const showUtilityDetailModalAtomLocationTrasportoBus = atom({
    key: 'showUtilityDetailModalAtomLocationTrasportoBus',
    default: false,
});

export const selectedLocationTrasportoTrenoAtom = atom({
    key: 'selectedLocationTrasportoTrenoAtom',
    default: [],
});

export const selectedLocationTrasportoBusAtom = atom({
    key: 'selectedLocationTrasportoBusAtom',
    default: [],
});

export const selectedTipoTabellaTrasportiAtom = atom({
    key: 'selectedTipoTabellaTrasportiAtom',
    default: "T",
});

export const selectedlocationTrasportiAtom = atom({
    key: 'selectedlocationTrasportiAtom',
    default: "0",
});

export const selectedlocationDataTrasportiAtom = atom({
    key: 'selectedlocationDataTrasportiAtom',
    default: "0",
});

export const selectedlocationStazioneTrasportiAtom = atom({
    key: 'selectedlocationStazioneTrasportiAtom',
    default: "0",
});