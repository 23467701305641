
import React, { useState } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AdjustmentsIcon, CheckIcon, CogIcon, HomeIcon, PlusIcon, RefreshIcon, SearchIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from 'react-bootstrap';

import { useRecoilState, useRecoilValue } from "recoil";
import { campAllItemsSelector, campItemsSelector, loginUserSelector, titoloUtilityCampSelector, titoloUtilitySelector, usersSelector } from "data/selectors";
import { currentPageUtilityAtomCamp, editUtilityDetailModalAtomCamp, editUtilityDetailModalAtomCampLocation, myUtilityAtomCamp, rowsPerPageUtilityAtomCamp, showUtilityDetailModalAtomCampLocation, tipoUtilityAtomCamp, utilityItemAtomCamp, utilityModalTitleAtomCamp, utilityRefreshAtomCamp, utilitySearchAtomCamp } from "data/atom";
import { LocationTable } from "./TablesLocation";
import { actionUtilityCamp } from "data/integrations";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));

export default () => {
  const myUsers = useRecoilValue(campAllItemsSelector);
  const [utilityRefresh, setRefreshUtility] = useRecoilState(utilityRefreshAtomCamp);

  const [searchValue, setSearchValue] = useRecoilState(utilitySearchAtomCamp);
  const titoloUtility = useRecoilValue(titoloUtilityCampSelector);
  const [tipoUtility, setTipoUtility] = useRecoilState(tipoUtilityAtomCamp);
  const [utilityAtom, setUtilityAtom] = useRecoilState(myUtilityAtomCamp);
  const [searchValueUtility, setSearchValueUtility] = useRecoilState(utilitySearchAtomCamp);

  const [show, setShowModal] = useRecoilState(showUtilityDetailModalAtomCampLocation);
  const [edit, setEditModal] = useRecoilState(editUtilityDetailModalAtomCampLocation);
  const [itemModal, setItemModal] = useRecoilState(utilityItemAtomCamp);
  const [titleModal, setTileModal] = useRecoilState(utilityModalTitleAtomCamp);

  let [users, setUsers] = useRecoilState(campItemsSelector);
  let [rowsPerPage, setRowsPerPage] = useRecoilState(rowsPerPageUtilityAtomCamp);
  const [currentPage, setCurrentPage] = useRecoilState(currentPageUtilityAtomCamp);
  const [myLoginUser, setLoginUser] = useRecoilState(loginUserSelector);

  let totalUsers = users.length;
  let selectedUsersIds = users.filter(u => u.isSelected).map(u => u.id);
  let allSelected = (totalUsers > 0) ? selectedUsersIds.length === totalUsers : false;


  const changeSearchValue = (e) => {
    setCurrentPage(0);
    const newSearchValue = e.target.value;
    const newUsers = users.map(u => ({ ...u, show: u.descrizione.toLowerCase().includes(newSearchValue.toLowerCase()) }));

    setSearchValue(newSearchValue);
    //setUsers(newUsers);
  };

  const selectAllUsers = () => {
    const newUsers = selectedUsersIds.length === totalUsers ?
      users.map(u => ({ ...u, isSelected: false })) :
      users.map(u => ({ ...u, isSelected: true }));

    setUsers(newUsers);
  };

  const selectUser = (id) => {
    const newUsers = users.map(u => u.id === id ? ({ ...u, isSelected: !u.isSelected }) : u);
    setUsers(newUsers);
  };

  const deleteUsers = async (ids) => {
    const usersToBeDeleted = ids ? ids : selectedUsersIds;
    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Conferma Eliminazione Massiva",
      text: "Sei sicuro di voler eliminare " + usersToBeDeleted.length + " elementi ?",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Annulla"
    });

    if (result.isConfirmed) {
      var myObj = {};
      myObj['action'] = 'delete_bulk';
      myObj['subject'] = tipoUtility;
      myObj['obj'] = {};
      myObj['obj']['ids'] = usersToBeDeleted;
      myObj['user'] = myLoginUser['username'];
      await actionUtilityCamp(myObj);
      var refs = utilityRefresh;
      refs++;
      setRefreshUtility(refs);
      setUtilityAtom(undefined);
      setSearchValueUtility("");
      const confirmMessage = "Elementi eliminati con successo";

      await SwalWithBootstrapButtons.fire('Eliminazione completata', confirmMessage, 'success');
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>{titoloUtility}</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button variant="info" size="sm" className="d-inline-flex align-items-center" onClick={() => {
            setTileModal("Aggiungi Nuovo Elemento");
            setEditModal(false);
            var myItemModal = { 'descrizione': '' };
            if (tipoUtility === 'discipline') {
              myItemModal['tipologia'] = 'Sportiva';
            }
            setItemModal(myItemModal);
            setShowModal(true);
          }}>
            <PlusIcon className="icon icon-xs me-2" /> Nuovo Elemento
          </Button>
          <Button variant="info" size="sm" className="ms-2 ms-lg-3 d-inline-flex align-items-center" onClick={() => {
            var refs = utilityRefresh;
            refs++;
            setRefreshUtility(refs);
            setUtilityAtom(undefined);
            setSearchValueUtility("");
          }}>
            <RefreshIcon className="icon icon-xs me-2" /> Aggiorna
          </Button>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-300">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Cerca elemento"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
          </Col>
          <Col xs={3} lg={4} className="d-flex justify-content-end">
            <ButtonGroup>
              <Dropdown className="me-1">
                <Dropdown.Toggle split as={Button} variant="info" className="text-dark m-0 p-1">
                  <AdjustmentsIcon className="icon icon-sm" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end pb-0">
                  <span className="small ps-3 fw-bold text-dark">
                    Righe per pagina
                  </span>
                  <Dropdown.Item className="d-flex align-items-center fw-bold" onClick={() => { setRowsPerPage(10) }}>
                    10 {
                      (rowsPerPage === 10) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                    }
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold" onClick={() => { setRowsPerPage(20) }}>20 {
                    (rowsPerPage === 20) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                  }</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom" onClick={() => { setRowsPerPage(50) }}>50 {
                    (rowsPerPage === 50) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                  }</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom" onClick={() => { setRowsPerPage(100) }}>100 {
                    (rowsPerPage === 100) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                  }</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom" onClick={() => { setRowsPerPage(200) }}>200 {
                    (rowsPerPage === 200) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                  }</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
          </Col>
        </Row>
      </div>

      <LocationTable
        mydata={users.filter(u => u.show)}
        allSelected={allSelected}
        selectUser={selectUser}
        deleteUsers={deleteUsers}
        selectAllUsers={selectAllUsers}
      />
    </>
  );
};
