/* eslint-disable no-loop-func */

import React, { useState } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AdjustmentsIcon, CheckIcon, CogIcon, DownloadIcon, HomeIcon, PlusIcon, RefreshIcon, SearchIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Table, Card } from 'react-bootstrap';

import { useRecoilState, useRecoilValue } from "recoil";
import { campAllItemsSelector, campItemsSelector, locationByTrasportoSelector, titoloUtilityCampSelector, titoloUtilitySelector, usersSelector } from "data/selectors";
import { currentPageUtilityAtomCamp, editUtilityDetailModalAtomCamp, editUtilityDetailModalAtomCampLocation, myUtilityAtomCamp, rowsPerPageUtilityAtomCamp, selectedTipoTabellaTrasportiAtom, selectedlocationDataTrasportiAtom, selectedlocationStazioneTrasportiAtom, selectedlocationTrasportiAtom, showUtilityDetailModalAtomCampLocation, tipoUtilityAtomCamp, utilityItemAtomCamp, utilityModalTitleAtomCamp, utilityRefreshAtomCamp, utilitySearchAtomCamp } from "data/atom";
import { LocationTable } from "./TablesLocation";
import { actionUtilityCamp, estraiNominativoTrasporti } from "data/integrations";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));

export default () => {
  const myUsers = useRecoilValue(campAllItemsSelector);
  const [utilityRefresh, setRefreshUtility] = useRecoilState(utilityRefreshAtomCamp);

  const titoloUtility = useRecoilValue(titoloUtilityCampSelector);

  const [show, setShowModal] = useRecoilState(showUtilityDetailModalAtomCampLocation);
  const [itemModal, setItemModal] = useRecoilState(utilityItemAtomCamp);
  const [titleModal, setTileModal] = useRecoilState(utilityModalTitleAtomCamp);

  const [tipoTabella, setTipoTabella] = useRecoilState(selectedTipoTabellaTrasportiAtom);
  const [selectedLocation, setSelectedLocation] = useRecoilState(selectedlocationTrasportiAtom);
  const [selectedData, setSelectedData] = useRecoilState(selectedlocationDataTrasportiAtom);
  const [selectedStazione, setSelectedStazione] = useRecoilState(selectedlocationStazioneTrasportiAtom);


  let locations = useRecoilValue(locationByTrasportoSelector);

  const generaListaLocations = () => {
    var options = [];
    options.push(<option value={"0"} key={'loc_' + 0}>{"Non selezionata"}</option>);

    if (locations.length > 0) {
      options.push(<option value={"99999999"} key={'loc_' + 99999999}>{"Tutte le Location"}</option>);

      for (var i = 0; i < locations.length; i++) {
        options.push(<option value={locations[i].id} key={'loc_' + locations[i].id}>{locations[i].location}</option>);
      }
    }
    return options;
  }

  const generaListaLocationsDate = () => {
    var options = [];
    options.push(<option value={"0"} key={'loc_data_' + 0}>{"Non selezionato"}</option>);
    if (locations.length > 0) {
      options.push(<option value={"99999999"} key={'loc_data_' + 99999999}>{"Tutte le date"}</option>);

      for (var j = 0; j < locations.length; j++) {
        if (parseInt(locations[j].id) === parseInt(selectedLocation)) {
          for (var i = 0; i < locations[j].date_location.length; i++) {
            options.push(<option value={locations[j].date_location[i]} key={'loc_data_' + (i + 1)}>{locations[j].date_location[i]}</option>);
          }
        }
      }
    }
    return options;
  }

  const generaListaLocationsStazioni = () => {
    var options = [];
    options.push(<option value={"0"} key={'loc_staz_' + 0}>{"Non selezionata"}</option>);
    if (locations.length > 0) {
      options.push(<option value={"99999999"} key={'loc_staz_' + 99999999}>{"Tutte le Stazioni"}</option>);

      for (var j = 0; j < locations.length; j++) {
        if (parseInt(locations[j].id) === parseInt(selectedLocation)) {
          for (var i = 0; i < locations[j].stazioni.length; i++) {
            options.push(<option value={locations[j].stazioni[i]} key={'loc_staz_' + (i + 1)}>{locations[j].stazioni[i]}</option>);
          }
        }
      }
    }
    return options;
  }

  const recuperaMaxDateLocation = () => {
    var max = 0;
    for (var j = 0; j < locations.length; j++) {
      if (parseInt(locations[j].id) === parseInt(selectedLocation) || selectedLocation === "99999999") {
        if (max < locations[j].date_location.length) max = locations[j].date_location.length;
      }
    }
    return max;
  }

  const generaTabellaTrasporti = () => {
    if (selectedLocation === "0") return null;
    var filteredLocation = locations.filter(l => (parseInt(l.id) === parseInt(selectedLocation) || selectedLocation === "99999999"));
    var maxDate = recuperaMaxDateLocation();
    var table = <Card border="0" className="table-wrapper table-responsive shadow">
      <Card.Body>
        <Table className="user-table align-items-left" id="tabellaTrasporti">
          <thead>
            <tr>
              {filteredLocation.map(u => <th className="border-bottom" style={{ textAlign: 'center' }}>{u.location}</th>)}
            </tr>
          </thead>
          <tbody className="border-0">
            <tr className="border-bottom">
              {filteredLocation.map(fl =>
                generaRigheDateLocation(fl)
              )
              }
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>;

    return table;
  }

  const generaRigheDateLocation = (fl) => {
    var righe = [];
    var filteredDate = fl.date_location.filter(l => (l === selectedData || selectedData === "99999999"));
    for (var i = 0; i < filteredDate.length; i++) {
      var riga =
        <table style={{ textAlign: 'center', width: '100%' }}>
          <tr style={{ textAlign: 'center' }}>
            <td style={{ textAlign: 'center' }}>
              {generaRigheStazioniLocation(fl, "Turno " + filteredDate[i])}
            </td>
          </tr>
        </table>
      righe.push(riga);
    }
    return <td style={{ textAlign: 'center' }}>
      {righe}
    </td>;
  }

  const generaRigheStazioniLocation = (fl, head) => {
    var righe = [];
    var filteredStazioni = fl.stazioni.filter(l => (l === selectedStazione || selectedStazione === "99999999"));
    for (var i = 0; i < filteredStazioni.length; i++) {
      var rigaTop =
        <tr style={{ textAlign: 'center' }}>
          <td className="border-1" style={{ textAlign: 'center', fontWeight: 'bold' }} colSpan={2}>
            {filteredStazioni[i]}
          </td>
        </tr>;
      righe.push(rigaTop);
      var turno = head.replace("Turno ", "");
      var rigaAR1 =
        <tr style={{ textAlign: 'center' }}>
          <td className="border-1" style={{ textAlign: 'center', fontWeight: 'bold', width: '50%' }}>
            Andata
          </td>
          <td className="border-1" style={{ textAlign: 'center', fontWeight: 'bold', width: '50%' }}>
            Ritorno
          </td>
        </tr>;
      righe.push(rigaAR1);
      var rigaAR2 =
        <tr style={{ textAlign: 'center' }}>
          <td className="border-1" style={{ textAlign: 'center', width: '50%' }}>
            {generaListaNominativi(fl, 'andata', turno, filteredStazioni[i])}
          </td>
          <td className="border-1" style={{ textAlign: 'center', width: '50%' }}>
            <ul>
              {generaListaNominativi(fl, 'ritorno', turno, filteredStazioni[i])}
            </ul>
          </td>
        </tr>;
      righe.push(rigaAR2);
    }
    return <table style={{ textAlign: 'center', width: '100%' }}>
      <thead className="border-1">
        <tr>
          <th className="border-1" style={{ textAlign: 'center' }} colSpan={2}>{head}</th>
        </tr>
      </thead>
      <tbody>
        {righe}
      </tbody>
    </table>;
  }

  const generaListaNominativi = (fl, dir, turno, stazione) => {
    var elenco = [];

    if (fl[dir][turno] !== undefined) {
      if (fl[dir][turno][stazione] !== undefined) {
        if (fl[dir][turno][stazione].length > 0) {
          fl[dir][turno][stazione].map((n) =>
            elenco.push(<li style={{ listStyle: 'none' }}>
              {(parseInt(n.stato) === 0) ? <mark>{n.nominativo}</mark> : n.nominativo}
            </li>)
          )
        }
        else {
          elenco.push(<li style={{ listStyle: 'none' }}>Nessun nominativo presente</li>);
        }
      }
      else {
        elenco.push(<li style={{ listStyle: 'none' }}>Nessun nominativo presente</li>);
      }
    }
    else {
      elenco.push(<li style={{ listStyle: 'none' }}>Nessun nominativo presente</li>);
    }
    /*
      
    */
    return <ul style={{ padding: '0' }}>
      {elenco}
    </ul>
  }

  const exportToXLSX = (jsonData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };

    saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
  };

  const generaXlsData = () => {
    var myData = [];
    for (var i = 0; i < locations.length; i++) {
      for (var i1 = 0; i1 < locations[i].date_location.length; i1++) {
        var turno = locations[i].date_location[i1];
        for (var i2 = 0; i2 < locations[i].stazioni.length; i2++) {
          var stazione = locations[i].stazioni[i2];
          for (var i3 = 0; i3 < 2; i3++) {
            var dir = (i3 === 0) ? 'andata' : 'ritorno';
            if (locations[i][dir][turno] !== undefined) {
              if (locations[i][dir][turno][stazione] !== undefined) {
                if (locations[i][dir][turno][stazione].length > 0) {
                  locations[i][dir][turno][stazione].map((n) => {
                    myData.push({
                      'Location': locations[i].location,
                      'Turno': turno,
                      'Stazione': stazione,
                      'Direzione': dir.toUpperCase(),
                      'Nominativo': n.nominativo,
                      'Stato': (parseInt(n.stato) === 0) ? 'Proposto' : 'Confermato'
                    })
                  }
                  )
                }
                else {
                  var obj = {};
                  obj.Location = locations[i].location;
                  obj.Turno = turno;
                  obj.Stazione = stazione;
                  obj.Direzione = dir.toUpperCase();
                  obj.Nominativo = 'Nessun nominativo presente';
                  obj.Stato = '-';
                  myData.push(obj);
                }
              }
              else {
                var obj = {};
                obj.Location = locations[i].location;
                obj.Turno = turno;
                obj.Stazione = stazione;
                obj.Direzione = dir.toUpperCase();
                obj.Nominativo = 'Nessun nominativo presente';
                obj.Stato = '-';
                myData.push(obj);
              }
            }
            else {
              var obj = {};
              obj.Location = locations[i].location;
              obj.Turno = turno;
              obj.Stazione = stazione;
              obj.Direzione = dir.toUpperCase();
              obj.Nominativo = 'Nessun nominativo presente';
              obj.Stato = '-';
              myData.push(obj);
            }
          }
        }
      }
    }
    /*
    for (const [key1, value1] of Object.entries(locations[i].andata)) {
      for (const [key2, value2] of Object.entries(value1)) {
        if (value2.length > 0) {
          for (var j = 0; j < value2.length; j++) {
            var obj = {};
            obj.Location = locations[i].location;
            obj.Turno = key1;
            obj.Stazione = key2;
            obj.Direzione = 'Andata';
            obj.Nominativo = value2[j].nominativo;
            obj.Stato = (parseInt(value2[j].stato) === 0) ? 'Proposto' : 'Confermato';
            myData.push(obj);
          }
        }
        else {
          var obj = {};
          obj.Location = locations[i].location;
          obj.Turno = key1;
          obj.Stazione = key2;
          obj.Direzione = 'Ritorno';
          obj.Nominativo = 'Nessun nominativo presente';
          obj.Stato = '-';
          myData.push(obj);
        }
      }
    }
  }

  for (var i = 0; i < locations.length; i++) {
    for (const [key1, value1] of Object.entries(locations[i].ritorno)) {
      for (const [key2, value2] of Object.entries(value1)) {
        if (value2.length > 0) {
          for (var j = 0; j < value2.length; j++) {
            var obj = {};
            obj.Location = locations[i].location;
            obj.Turno = key1;
            obj.Stazione = key2;
            obj.Direzione = 'Ritorno';
            obj.Nominativo = value2[j].nominativo;
            obj.Stato = (parseInt(value2[j].stato) === 0) ? 'Proposto' : 'Confermato';
            myData.push(obj);
          }
        }
        else {
          var obj = {};
          obj.Location = locations[i].location;
          obj.Turno = key1;
          obj.Stazione = key2;
          obj.Direzione = 'Ritorno';
          obj.Nominativo = 'Nessun nominativo presente';
          obj.Stato = '-';
          myData.push(obj);
        }
      }
    }
  }
  */
    return myData;
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>{titoloUtility}</h4>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center mb-4">
          <Col xs={3} lg={3} className="d-md-flex">
            <Form.Group style={{ minWidth: '250px' }}>
              <Form.Label>Seleziona Tabella Trasporti</Form.Label>
              <Form.Select value={tipoTabella} onChange={(e) => {
                setSelectedStazione("0");
                setSelectedData("0");
                setSelectedLocation("0");
                setTipoTabella(e.target.value);
              }}>
                <option value="T">Tabella per Trasporti Treno</option>
                <option value="B">Tabella per Trasporti Bus</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={3} lg={3} className="d-md-flex">
            <Form.Group style={{ minWidth: '250px' }}>
              <Form.Label>Seleziona Location</Form.Label>
              <Form.Select value={selectedLocation} onChange={(e) => {
                setSelectedStazione("0");
                setSelectedData("0");
                setSelectedLocation(e.target.value);
              }}>
                {generaListaLocations()}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={3} lg={3} className="d-md-flex">
            <Form.Group style={{ minWidth: '250px' }}>
              <Form.Label>Seleziona un Turno</Form.Label>
              <Form.Select value={selectedData} onChange={(e) => {
                setSelectedData(e.target.value);
              }}>
                {generaListaLocationsDate()}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={3} lg={3} className="d-md-flex">
            <Form.Group style={{ minWidth: '250px' }}>
              <Form.Label>Seleziona una Stazione</Form.Label>
              <Form.Select value={selectedStazione} onChange={(e) => {
                setSelectedStazione(e.target.value);
              }}>
                {generaListaLocationsStazioni()}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-between align-items-center">
          <Col xs={3} lg={3} className="d-md-flex">
            <Button variant="info" size="sm" className="d-inline-flex align-items-center" onClick={() => {
              exportToXLSX(generaXlsData(), 'TabellaTrasporti' + ((tipoTabella === 'T') ? 'Treno' : 'Bus'))
            }}>
              <DownloadIcon className="icon icon-xs me-2" /> Esporta Dati Trasporti {(tipoTabella === 'T') ? 'Treno' : 'Bus'}
            </Button>
          </Col>
        </Row>
      </div>
      {generaTabellaTrasporti()}
    </>
  );
};
