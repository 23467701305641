import React from "react";
import Axios from 'axios'

export const loginUser = async(email, password) => {
    // Sending the user Login request
    const login = await Axios.post('/backend/login.php', {
        username: email,
        passwd: password
    });
    return login.data;
};

export const estraiRuoli = async() => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiRuoli.php');
    return data.data.obj;
};

export const estraiDiscipline = async() => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiDiscipline.php');
    return data.data.obj;
};

export const estraiAnagrafiche = async() => {
    // Sending the user Login request
    const data = await Axios.get('/backend/listaAnagrafiche.php');
    return data.data;
};

export const estraiLivelloInglese = async() => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiLivelloInglese.php');
    return data.data.obj;
};

export const estraiTitoliStudio = async() => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiTitoliStudio.php');
    return data.data.obj;
};

export const estraiTipoContratto = async() => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiTipoContratto.php');
    return data.data.obj;
};

export const estraiTrasportiBus = async() => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiTrasportoBus.php');
    return data.data.obj;
};

export const estraiTrasportiTreno = async() => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiTrasportoTreno.php');
    return data.data.obj;
};

export const actionUtility = async(myObj) => {
    // Sending the user Login request
    const gara = await Axios.post('/backend/actionUtility.php', myObj);
    return gara.data;
};

export const recuperaTemplate = async(tipoTemplate) => {
    const data = await Axios.get('/backend/generaTemplate.php',{ params: { tipo: tipoTemplate }});
    return data.data.obj;
};

export const eliminaAnagrafiche = async(myObj) => {
    // Sending the user Login request
    const gara = await Axios.post('/backend/eliminaAnagrafiche.php', myObj);
    return gara.data;
};

export const estraiLocation = async() => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiLocation.php');
    return data.data.obj;
};

export const estraiLocationSelect = async() => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiLocationSelect.php');
    return data.data.obj;
};

export const estraiLocationDateById = async(loc) => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiLocationDateById.php?id_loc='+loc);
    return data.data.obj;
};

export const estraiCamp = async() => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiCamp.php');
    return data.data.obj;
};

export const estraiCampByData = async(id) => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiCamp.php?id_data='+id);
    return data.data.obj;
};

export const estraiCampByLocationAndData = async(id_loc,data_loc) => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiCampByLocationAndData.php?id_location='+id_loc+"&data_location="+data_loc);
    return data.data.obj;
};

export const estraiAnnoGestione = async() => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiAnnoGestione.php');
    return data.data.obj;
};

export const actionUtilityCamp = async(myObj) => {
    // Sending the user Login request
    const gara = await Axios.post('/backend/actionUtilityCamp.php', myObj);
    return gara.data;
};

export const estraiListaCamp = async() => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiListaCamp.php');
    return data.data.obj;
};

export const estraiDisponibilita = async(prop) => {
    // Sending the user Login request
    const data = await Axios.get('/backend/listaDisponibilita.php?proposta='+prop);
    return data.data;
};

export const estraiDateDisponibilita = async(anag) => {
    // Sending the user Login request
    const data = await Axios.get('/backend/listaDateDisponibilita.php?id_anagrafica='+anag);
    return data.data;
};

export const eliminaDisponibilita = async(myObj) => {
    // Sending the user Login request
    const gara = await Axios.post('/backend/eliminaDisponibilita.php', myObj);
    return gara.data;
};

export const actionUtilityDisponibilita = async(myObj) => {
    // Sending the user Login request
    const gara = await Axios.post('/backend/actionDisponibilita.php', myObj);
    return gara.data;
};

export const estraiConferme = async() => {
    // Sending the user Login request
    const data = await Axios.get('/backend/listaConferme.php');
    return data.data;
};

export const eliminaConferme = async(myObj) => {
    // Sending the user Login request
    const gara = await Axios.post('/backend/eliminaConferme.php', myObj);
    return gara.data;
};

export const actionUtilityConferme = async(myObj) => {
    // Sending the user Login request
    const gara = await Axios.post('/backend/actionConferme.php', myObj);
    return gara.data;
};

export const estraiUtenti = async() => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiUtenti.php');
    return data.data.obj;
};

export const actionUtenti = async(myObj) => {
    // Sending the user Login request
    const gara = await Axios.post('/backend/actionUtenti.php', myObj);
    return gara.data;
};

export const generaPDF = async(myObj) => {
    // Sending the user Login request
    const gara = await Axios.post('/backend/creaPdfConferme.php', myObj);
    return gara.data;
};

export const estraiLocationDateByCamp = async(id) => {
    // Sending the user Login request
    var idNome = encodeURIComponent(id);
    const data = await Axios.get('/backend/estraiLocationDateByCampId.php?id_camp='+idNome);
    return data.data.obj;
};

export const estraiLocationTrenoById = async(loc) => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiLocationTrenoById.php?id_loc='+loc);
    return data.data.obj;
};

export const estraiLocationBusById = async(loc) => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiLocationBusById.php?id_loc='+loc);
    return data.data.obj;
};

export const estraiLocationByTrasporto = async(tipo) => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiLocationByTrasporto.php?tipo='+tipo);
    return data.data.obj;
};

export const estraiNominativoTrasporti = async(tipo,loc,staz,turno,direzione) => {
    // Sending the user Login request
    const data = await Axios.get('/backend/estraiNominativoTrasporti.php?tipo='+tipo+'&loc='+loc+'&staz='+staz+'&turno='+turno+'&direzione='+direzione);
    return data.data.obj;
};