
import React, { Suspense, useState, useEffect } from 'react';
import { Route, Switch } from "react-router-dom";
import { Routes } from "routes";


// components

import Axios from "axios";
import { useSnackbar } from "notistack";
import { tokenSelector } from '../data/selectors';
import { useRecoilState } from 'recoil';
import { refreshTokenAtom, sidebarContractedAtom } from '../data/atom';
import Preloader from 'components/Preloader';
import Login from './login/Login';
import SidebarAdmin from 'components/SidebarAdmin';
import FormAnagrafica from './anagrafica/FormAnagrafica';
import Utility from './utility/Utility';
import Anagrafica from './anagrafica/Anagrafica';
import GestioneLocation from './camp/GestioneLocation';
import ListaCamp from './camp/ListaCamp';
import Disponibilita from './disponibilita/Disponibilita';
import Conferme from './conferme/Conferme';
import FormImpostazioniCamp from './utility/FormImpostazioniCamp';
import Utenti from './utenti/Utenti';
import GestioneTabellaTrasporti from './camp/GestioneTabellaTrasporti';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => (<> <Preloader show={loaded ? false : true} /> <Component {...props} /> </>)} />
  );
};


export default () => {
  const [token, setToken] = useRecoilState(tokenSelector);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenAtom);

  const RouteWithSidebar = ({ component: Component, ...rest }) => {
    const [loaded, setLoaded] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    var isLoadingKey;

    useEffect(() => {
      const timer = setTimeout(() => setLoaded(true), 1000);
      return () => clearTimeout(timer);
    }, []);

    const resize = () => {
      var resize = setInterval(() => {
        window.dispatchEvent(new Event('resize'));
      }, 10);
      setTimeout(function () {
        clearInterval(resize);
      }, 301);
    }

    const localStorageIsContracted = () => {
      return localStorage.getItem('sidebarContracted') === 'false' ? false : true
    }

    const [contracted, setContracted] = useState(localStorageIsContracted());
    const [contractSidebar, setContractSidebar] = useState(localStorageIsContracted());

    const toggleMouseOver = () => {
      if (contracted) {
        setContractSidebar(!contractSidebar);
      }
      resize();
    };

    const toggleContracted = () => {
      setContracted(!contracted);
      setContractSidebar(!contracted);
      localStorage.setItem('sidebarContracted', !contracted);
      resize();
    };

    const closeIfOpen = () => {
      if (!contractSidebar) {
        setContractSidebar(true);
        setContracted(true);
        localStorage.setItem('sidebarContracted', true);
        resize();
      }
    };

    Axios.interceptors.request.use(
      function (config) {
        isLoadingKey = enqueueSnackbar("Loading...", {
          variant: "default",
          persist: false
        });
        return config;
      },
      function (error) {
        closeSnackbar(isLoadingKey);
        return Promise.rejectAll(error);
      }
    );
    Axios.interceptors.response.use(
      function (response) {
        closeSnackbar(isLoadingKey);

        return response;
      },
      err => {
        const error = err.response;
        closeSnackbar(isLoadingKey);

        switch (error.status) {
          /*
          case 401:
            let _loc = window.location;
            window.location = _loc.protocol + "//" + _loc.host + "/" + _loc.pathname.split("/")[1] + "/login";
            break;
            */
          default:
            enqueueSnackbar(error.statusText, {
              variant: "error",
              autoHideDuration: 10000
            });
            break;
        }
        return Promise.rejectAll(error);
      }
    );

    return (
      <Route {...rest} render={props => (
        <>
          {/*<Preloader show={loaded ? false : true} />*/}
          <SidebarAdmin
            contracted={contractSidebar}
            onMouseEnter={toggleMouseOver}
            onMouseLeave={toggleMouseOver} />

          <main className="content" style={{ 'minHeight': 'calc(100vh - 50px)' }}
            onClick={(e)=>{
              closeIfOpen();
            }}>
            <Component {...props} />
          </main>
        </>
      )}
      />
    );
  };

  const drawHomePage = (token) => {
    if (!token) {
      return <Suspense fallback={"Attendi.."}>
        <Login />
      </Suspense>
    }
    else {
      return <Suspense fallback={"Attendi.."}>
        <Switch>
          <RouteWithLoader exact path={Routes.Login.path} component={Login} />
          <RouteWithLoader exact path={Routes.VoidPath.path} component={Login} />
          <RouteWithSidebar exact path={Routes.DashboardAnagrafica.path} component={Anagrafica} />
          <RouteWithSidebar exact path={Routes.DashboardDisponibilita.path} component={Disponibilita} />
          <RouteWithSidebar exact path={Routes.DashboardConferme.path} component={Conferme} />
          <RouteWithSidebar exact path={Routes.FormAnagrafica.path} component={FormAnagrafica} />
          <RouteWithSidebar exact path={Routes.ImpostazioniInglese.path} component={Utility} />
          <RouteWithSidebar exact path={Routes.ImpostazioniStudio.path} component={Utility} />
          <RouteWithSidebar exact path={Routes.ImpostazioniContratti.path} component={Utility} />
          <RouteWithSidebar exact path={Routes.ImpostazioniRuoli.path} component={Utility} />
          <RouteWithSidebar exact path={Routes.ImpostazioniDiscipline.path} component={Utility} />
          <RouteWithSidebar exact path={Routes.ImpostazioniTrasportoBus.path} component={Utility} />
          <RouteWithSidebar exact path={Routes.ImpostazioniTrasportoTreno.path} component={Utility} />
          <RouteWithSidebar exact path={Routes.GestioneLocation.path} component={GestioneLocation} />
          <RouteWithSidebar exact path={Routes.GestioneCamp.path} component={ListaCamp} />
          <RouteWithSidebar exact path={Routes.TabellaTrasporti.path} component={GestioneTabellaTrasporti} />
          <RouteWithSidebar exact path={Routes.ImpostazioniCamp.path} component={FormImpostazioniCamp} />
          <RouteWithSidebar exact path={Routes.GestioneUtenti.path} component={Utenti} />
        </Switch>
      </Suspense>
    }
  }

  return drawHomePage(token);
};
