
import React, { useState } from "react";
import { ArrowLeftIcon, ArrowNarrowDownIcon, ArrowNarrowUpIcon, ArrowRightIcon, CheckCircleIcon, ChevronDownIcon, ChevronUpIcon, DotsHorizontalIcon, ExternalLinkIcon, EyeIcon, InformationCircleIcon, PencilAltIcon, ShieldExclamationIcon, TrashIcon, UserRemoveIcon, XCircleIcon } from "@heroicons/react/solid";
import { Col, Row, Nav, Card, Form, Image, Button, Table, Dropdown, ProgressBar, Pagination, Tooltip, FormCheck, ButtonGroup, OverlayTrigger } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from "recoil";
import { loginUserSelector, UtilityListSelector } from "data/selectors";
import { currentPageUtilityAtom, editUtilityDetailModalAtom, myUtilityAtom, rowsPerPageUtilityAtom, showUtilityDetailModalAtom, tipoUtilityAtom, utilityItemAtom, utilityModalTitleAtom, utilityRefreshAtom, utilitySearchAtom } from "data/atom";
import Swal from 'sweetalert2';
import { actionUtility } from "data/integrations";
import withReactContent from "sweetalert2-react-content";
import { DetailsUtilityModal } from "./DetailsUtility";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary me-3',
        cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));


export const UtilityTable = (props) => {
    const { mydata = [], allSelected } = props;
    const [bulkOption, setBulkOption] = useState('delete_user');
    const disabledBulkMenu = mydata.filter(u => u.isSelected).length === 0;
    const myUsers = useRecoilValue(UtilityListSelector);
    const [tipoUtility, setTipoUtility] = useRecoilState(tipoUtilityAtom);
    const [currentPage, setCurrentPage] = useRecoilState(currentPageUtilityAtom);
    let [rowsPerPage, setRowsPerPage] = useRecoilState(rowsPerPageUtilityAtom);
    const [utilityRefresh, setRefreshUtility] = useRecoilState(utilityRefreshAtom);
    const [utilityAtom, setUtilityAtom] = useRecoilState(myUtilityAtom);
    const [searchValueUtility, setSearchValueUtility] = useRecoilState(utilitySearchAtom);

    const [show, setShowModal] = useRecoilState(showUtilityDetailModalAtom);
    const [edit, setEditModal] = useRecoilState(editUtilityDetailModalAtom);
    const [itemModal, setItemModal] = useRecoilState(utilityItemAtom);
    const [titleModal, setTileModal] = useRecoilState(utilityModalTitleAtom);
    const [myLoginUser, setLoginUser] = useRecoilState(loginUserSelector);

    function quantiUsers() {
        if (searchValueUtility.length === 0) {
            var quanti = myUsers.length;
            return quanti;
        }
        else {
            var newUsers = myUsers.filter(u => (u.descrizione.toLowerCase().includes(searchValueUtility.toLowerCase())));
            return newUsers.length;
        }
    }

    const onAddItem = async (obj) => {
        var myObj = {};
        myObj['action'] = 'add_item';
        myObj['subject'] = tipoUtility;
        myObj['obj'] = obj;
        myObj['user'] = myLoginUser['username'];

        await actionUtility(myObj);
        var refs = utilityRefresh;
        refs++;
        setRefreshUtility(refs);
        setUtilityAtom(undefined);
        setSearchValueUtility("");
        const confirmMessage = "L'elemento è stato aggiunto con successo";

        await SwalWithBootstrapButtons.fire('Inserimento completato', confirmMessage, 'success');
        setShowModal(false);
    };

    const onUpdateItem = async (obj) => {
        var myObj = {};
        myObj['action'] = 'update_item';
        myObj['subject'] = tipoUtility;
        myObj['obj'] = obj;
        myObj['user'] = myLoginUser['username'];

        await actionUtility(myObj);
        var refs = utilityRefresh;
        refs++;
        setRefreshUtility(refs);
        setUtilityAtom(undefined);
        setSearchValueUtility("");
        const confirmMessage = "L'elemento ID: " + obj['id'] + " è stato aggiornato con successo";

        await SwalWithBootstrapButtons.fire('Aggiornamento completato', confirmMessage, 'success');
        setShowModal(false);
    };

    const selectUser = (id) => {
        props.selectUser && props.selectUser(id);
    };

    const selectAllUsers = () => {
        props.selectAllUsers && props.selectAllUsers();
    };

    const bulkActionChange = (e) => {
        const newOption = e.target.value;
        setBulkOption(newOption);
    }

    const applyBulkAction = () => {
        if (bulkOption === "delete_user") deleteUsers();
    }

    const deleteUsers = (ids) => {
        props.deleteUsers && props.deleteUsers(ids)
    }

    const TableRow = (props) => {
        const { id, descrizione, tipologia, isSelected } = props;

        return (
            <tr className="border-bottom">
                <td>
                    <FormCheck type="checkbox" className="dashboard-check">
                        <FormCheck.Input id={`user-${id}`} checked={isSelected} onChange={() => selectUser(id)} />
                        <FormCheck.Label htmlFor={`user-${id}`} />
                    </FormCheck>
                </td>
                <td>
                    <span className="fw-normal">{id}</span>
                </td>
                <td>
                    <span className="fw-normal">{descrizione}</span>
                </td>
                {
                    (tipoUtility === 'discipline') ?
                        <td>
                            <span className="fw-normal">{tipologia}</span>
                        </td>
                        : <td>&nbsp;</td>
                }
                <td>
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                            <DotsHorizontalIcon className="icon icon-xs icon-dark" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="py-0">
                            <Dropdown.Item onClick={() => {
                                setTileModal("Modifica Elemento");
                                setEditModal(true);
                                var myItemModal = {};
                                myItemModal = mydata.filter(u => u.id === id)[0];
                                setItemModal(myItemModal);
                                setShowModal(true);
                            }}>
                                <PencilAltIcon className="icon icon-xs me-2" /> Modifica
                            </Dropdown.Item>
                            <Dropdown.Item onClick={async () => {
                                const result = await SwalWithBootstrapButtons.fire({
                                    icon: "error",
                                    title: "Conferma Eliminazione",
                                    text: "Sei sicuro di voler eliminare l'elemento con ID: " + id + " e Descrizione: " + descrizione,
                                    showCancelButton: true,
                                    confirmButtonText: "Si",
                                    cancelButtonText: "Annulla"
                                });

                                if (result.isConfirmed) {
                                    var myObj = {};
                                    myObj['action'] = 'delete_item';
                                    myObj['subject'] = tipoUtility;
                                    myObj['obj'] = {};
                                    myObj['obj']['id'] = id;
                                    await actionUtility(myObj);
                                    var refs = utilityRefresh;
                                    refs++;
                                    setRefreshUtility(refs);
                                    setUtilityAtom(undefined);
                                    setSearchValueUtility("");
                                    const confirmMessage = "L'elemento ID: " + id + " è stato eliminato con successo";

                                    await SwalWithBootstrapButtons.fire('Eliminazione completata', confirmMessage, 'success');
                                }
                            }}>
                                <TrashIcon className="icon icon-xs text-danger me-2" /> Rimuovi
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>
        );
    };

    const listPages = () => {
        var pageArrays = [];

        for (var i = 0; i < (Math.ceil(quantiUsers() / rowsPerPage)); i++) {
            pageArrays.push(<option value={i} key={'keyPage'+i}>{i + 1}</option>);
        }

        return pageArrays;
    }

    return (
        <Card border="0" className="table-wrapper table-responsive shadow">
            <Card.Body>
                <div className="d-flex mb-3">
                    <Form.Select className="fmxw-200" disabled={disabledBulkMenu} value={bulkOption} onChange={bulkActionChange}>
                        <option value="delete_user">Cancella Selezionati</option>
                    </Form.Select>
                    <Button variant="info" size="sm" className="ms-3" disabled={disabledBulkMenu} onClick={applyBulkAction}>
                        Applica
                    </Button>
                </div>
                <Table hover className="user-table align-items-left">
                    <thead>
                        <tr>
                            <th className="border-bottom">
                                <FormCheck type="checkbox" className="dashboard-check">
                                    <FormCheck.Input id="userCheckAll" checked={allSelected} onChange={selectAllUsers} />
                                    <FormCheck.Label htmlFor="userCheckAll" />
                                </FormCheck>
                            </th>
                            <th className="border-bottom">ID</th>
                            <th className="border-bottom">Descrizione</th>
                            {
                                (tipoUtility === 'discipline') ?
                                    <th className="border-bottom">Tipologia</th>
                                    :
                                    <th className="border-bottom">&nbsp;</th>
                            }
                            <th className="border-bottom">Azione</th>
                        </tr>
                    </thead>
                    <tbody className="border-0">
                        {mydata.map(u => <TableRow key={`user-${u.id}`} {...u} />)}
                    </tbody>
                </Table>
                <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <Form.Group id="listaPagine" className="mb-4">
                        <Form.Label>Seleziona Pagina</Form.Label>
                        <Form.Select id="pagine"
                            style={{ 'width': '80px' }}
                            value={
                                currentPage
                            }
                            onChange={(e) => {
                                setCurrentPage(e.target.value)
                            }}>
                            {listPages()}
                        </Form.Select>
                    </Form.Group>
                    <Nav>
                        <Pagination className="mb-0">
                            <Pagination.Prev onClick={() => {
                                setCurrentPage(currentPage - 1);
                            }}
                                disabled={currentPage === 0}>
                                <ArrowLeftIcon className="icon icon-xs text-danger me-2" />
                            </Pagination.Prev>
                            <Pagination.Next onClick={() => {
                                setCurrentPage(currentPage + 1)
                            }}
                                disabled={
                                    (currentPage + 1) === (Math.ceil(quantiUsers() / rowsPerPage))
                                }>
                                <ArrowRightIcon className="icon icon-xs text-danger me-2" />
                            </Pagination.Next>
                        </Pagination>
                    </Nav>
                    <small className="fw-normal small mt-4 mt-lg-0">
                        Visualizzazione <b>{mydata.length + (currentPage * rowsPerPage)}</b> di <b>{quantiUsers()}</b> risultati
                    </small>
                </Card.Footer>
                <DetailsUtilityModal
                    edit={edit}
                    title={titleModal}
                    onAdd={onAddItem}
                    onUpdate={onUpdateItem}
                />
            </Card.Body>
        </Card>

    );
};