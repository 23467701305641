
import React, { useState } from "react";
import Axios from 'axios'
import moment from "moment-timezone";
import 'moment/locale/it';
import Datetime from "react-datetime";
import { CalendarIcon, CheckCircleIcon, FolderOpenIcon, InformationCircleIcon, SaveIcon, UsersIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Form, Button, InputGroup, Tab, Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Routes } from "routes";
import { useRecoilState, useRecoilValue } from "recoil";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { annoGestioneRefreshTokenAtom } from "data/atom";
import { annoGestioneSelector } from "data/selectors";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));

export default () => {

  const [annoGestioneRefresh, setAnnoGestioneRefresh] = useRecoilState(annoGestioneRefreshTokenAtom);
  const annoGestione = useRecoilValue(annoGestioneSelector);
  const [currentAnnoGestione, setCurrentAnnoGestione]=useState(annoGestione);

  const salvaImpostazioni = async () => {

    let url = '/backend/salvaImpostazioniCamp.php';
    var data = {
      annoGestione: currentAnnoGestione
    };
    Axios.post(url, data)
      .then(async (res) => {
        console.log(res);
        if (res.data.error === false) {
          // then print response status
          SwalWithBootstrapButtons.fire('Salvataggio completato', "Il salvataggio dell'dell'anno di Gestione è stato completato con successo !", 'success');

          var refs = annoGestioneRefresh;
          refs++;
          setAnnoGestioneRefresh(refs);
        }
        else {
          alert(res.data.message);
        }

      }, error => {
        alert(error);
      });

  };


  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Impostazioni Gestione Camp</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button variant="info" size="sm" className="ms-2 ms-lg-3 d-inline-flex align-items-center"
            onClick={async () => {
              await salvaImpostazioni();
            }}>
            <SaveIcon className="icon icon-xs me-2" /> Salva Impostazioni
          </Button>
        </div>
      </div>

      <Card as="form" border="0" className="shadow pb-4 mb-4">
        <Card.Body className="p-0 p-md-4">
          <Row className="justify-content-center">
            <Col xs={12} lg={3}>
              <Form.Group className="mb-3">
                <Form.Label>Anno Gestione</Form.Label>
                <Form.Control type="number"
                  onChange={(e) => {
                    if(parseInt(e.target.value)<2024){
                      return;
                    }
                    setCurrentAnnoGestione(e.target.value);
                  }}
                  value={currentAnnoGestione} />
              </Form.Group>
            </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
  );
};