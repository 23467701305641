import React from "react";
import Select from "react-select";
import 'moment/locale/it';
import { Col, Row, Form, Modal, Button } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from "recoil";
import { selectedLocationDataCampAtom, showUtilityDetailModalAtomCamp, utilityItemAtomCamp } from "data/atom";
import { locationDateSelectSelectorById, locationDisciplineSelector } from "data/selectors";
import { estraiCampByData } from "data/integrations";


export const DetailsLocationCampModal = (props) => {
    const [item, setItem] = useRecoilState(utilityItemAtomCamp);
    const [show, setShowDefault] = useRecoilState(showUtilityDetailModalAtomCamp);
    const [dataLocation, setDataLocation] = useRecoilState(selectedLocationDataCampAtom);

    const locationsDateItem = useRecoilValue(locationDateSelectSelectorById);
    const disciplineSelector = useRecoilValue(locationDisciplineSelector);

    const { edit = false, title } = props;

    const onConfirm = () => {

        const payload = JSON.parse(JSON.stringify(item));

        return props.onUpdateLocationCamp && props.onUpdateLocationCamp(payload);

    }


    const handleClose = () => setShowDefault(false);

    const currentItemKey = (key) => {
        var value = (item !== undefined) ? (item[key] !== undefined) ? item[key] : undefined : undefined;
        return value;
    };



    return (
        <Modal as={Modal.Dialog} centered show={show} onHide={handleClose} size="lg">
            <Modal.Header>
                <Modal.Title className="h6">{title}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Form className="modal-content">
                <Modal.Body>
                    <Row className="justify-content-center">
                        <Col xs={12} lg={12}>
                            <Form.Group className="mb-4">
                                <Form.Label>Seleziona una Data</Form.Label>
                                <Select options={locationsDateItem}
                                    onChange={async (selectedOption) => {
                                        let objTemp = JSON.parse(JSON.stringify(item));
                                        objTemp['id_data'] = selectedOption.value;
                                        setDataLocation(selectedOption.value);
                                        var camp = await estraiCampByData(selectedOption.value);
                                        objTemp['camp'] = camp;
                                        setItem(objTemp);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {
                        (item['id_data'] !== undefined) ?
                            <Row className="justify-content-center">
                                <Col xs={12} lg={12}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Seleziona un Camp</Form.Label>
                                        <Select options={disciplineSelector} isMulti
                                            value={(item['camp'] !== undefined) ? item['camp'] : []}
                                            onChange={(selectedOption) => {
                                                let objTemp = JSON.parse(JSON.stringify(item));
                                                if (objTemp['camp'] === undefined) {
                                                    objTemp['camp'] = [];
                                                }
                                                objTemp['camp'] = selectedOption;
                                                setDataLocation(selectedOption.value);
                                                setItem(objTemp);
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            :
                            null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="me-2" onClick={onConfirm}
                        disabled={item['id_location'] === undefined || item['id_data'] === undefined || item['camp'] === undefined || (item['camp'] !== undefined && item['camp'].length === 0)}
                    >
                        Salva
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};