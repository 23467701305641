import React from "react";
import Select from "react-select";
import { Form, Modal, Button } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from "recoil";
import { showUtilityDetailModalAtomCampLocation, tipoUtilityAtomCamp, utilityItemAtomCampLocation } from "data/atom";
import { locationSelectSelector } from "data/selectors";


export const DetailsLocationModal = (props) => {
    const [item, setItem] = useRecoilState(utilityItemAtomCampLocation);
    const [show, setShowDefault] = useRecoilState(showUtilityDetailModalAtomCampLocation);

    const locationsItem = useRecoilValue(locationSelectSelector);

    const { edit = false, title } = props;
    const [tipoUtility, setTipoUtility] = useRecoilState(tipoUtilityAtomCamp);

    const onDescrizioneChange = (e) => {
        let objTemp = JSON.parse(JSON.stringify(item));
        objTemp['descrizione'] = e.target.value;
        setItem(objTemp);
    }

    const onConfirm = () => {

        const payload = JSON.parse(JSON.stringify(item));

        if (edit) {
            return props.onUpdateLocation && props.onUpdateLocation(payload);
        }

        return props.onAddLocation && props.onAddLocation(payload);
    }

    const handleClose = () => setShowDefault(false);


    return (
        <Modal as={Modal.Dialog} centered show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">{title}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Form className="modal-content">
                <Modal.Body>
                    {
                        (edit) ?
                            <Form.Group id="id_item" className="mb-4">
                                <Form.Label>ID</Form.Label>
                                <Form.Control
                                    required
                                    disabled
                                    type="text"
                                    value={item['id']} />
                            </Form.Group>
                            :
                            null
                    }
                    {
                        (locationsItem.length > 0) ?
                            <Form.Group className="mb-4">
                                <Form.Label>Seleziona una Location già censita</Form.Label>
                                <Select options={locationsItem}
                                    onChange={(selectedOption) => {
                                        let objTemp = JSON.parse(JSON.stringify(item));
                                        objTemp['descrizione'] = selectedOption.label;
                                        setItem(objTemp);
                                    }}
                                />
                            </Form.Group>
                            :
                            null
                    }
                    <Form.Group id="descrizione" className="mb-4">
                        <Form.Label>Nome Location</Form.Label>
                        <Form.Control
                            required
                            autoFocus
                            type="text"
                            value={item['descrizione']}
                            onChange={onDescrizioneChange} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="me-2" onClick={onConfirm}
                        disabled={item['descrizione'].length === 0}
                    >
                        Salva
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};