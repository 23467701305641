import React from "react";
import Select from "react-select";
import moment from "moment-timezone";
import 'moment/locale/it';
import Datetime from "react-datetime";
import { ArrowLeftIcon, ArrowRightIcon, CalendarIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Modal, Button, InputGroup, Table, Card, Nav, Pagination } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from "recoil";
import { campLocalitaDateAtom, currentPageCampLocationDateAtom, showDetailModalAtomCampLocationDate, showUtilityDetailModalAtomCampLocationDate, utilityItemAtomCampLocationDate } from "data/atom";
import { listaLocationdateByCampSelector, locationDateSelectSelector } from "data/selectors";


export const DetailsCampLocationDateModal = (props) => {
    const [show, setShowDefault] = useRecoilState(showDetailModalAtomCampLocationDate);
    const [campLocalitaDate, setCampLocalitaDate] = useRecoilState(campLocalitaDateAtom);
    const [currentPage, setCurrentPage] = useRecoilState(currentPageCampLocationDateAtom);

    const locationsItem = useRecoilValue(listaLocationdateByCampSelector);

    const handleClose = () => setShowDefault(false);

    const drawTableRow = (u) => {
        return <tr key={`local-${u.location}-${u.data_ordinamento}`} className="border-bottom">
            <td className="fw-normal pb-0 pt-0">{u.descrizione}</td>
            <td className="fw-normal pb-0 pt-0">{u.data_inizio}</td>
            <td className="fw-normal pb-0 pt-0">{u.data_fine}</td>
        </tr>;
    };

    return (
        <Modal as={Modal.Dialog} centered show={show} onHide={handleClose} size="lg">
            <Modal.Header>
                <Modal.Title className="h6">Localit&agrave; e Date per Camp {campLocalitaDate}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Form className="modal-content">
                <Modal.Body>
                    <Row className="justify-content-center">
                        <Col xs={12} lg={12}>
                            <Table hover className="user-table align-items-left">
                                <thead>
                                    <tr>
                                        <th className="border-bottom">Location</th>
                                        <th className="border-bottom">Data Inizio</th>
                                        <th className="border-bottom">Data Fine</th>
                                    </tr>
                                </thead>
                                <tbody className="border-0">
                                    {locationsItem.map(u => {
                                        return drawTableRow(u)
                                    })}
                                </tbody>
                            </Table>
                            <Card className="mb-0 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                                <Nav>
                                    <Pagination className="mb-0">
                                        <Pagination.Prev onClick={() => {
                                            setCurrentPage(currentPage - 1);
                                        }}
                                            disabled={currentPage === 0}>
                                            <ArrowLeftIcon className="icon icon-xs text-danger me-2" />
                                        </Pagination.Prev>
                                        <Pagination.Next onClick={() => {
                                            setCurrentPage(currentPage + 1)
                                        }}
                                            disabled={
                                                (currentPage + 1) === (Math.ceil(locationsItem.length / 10))
                                            }>
                                            <ArrowRightIcon className="icon icon-xs text-danger me-2" />
                                        </Pagination.Next>
                                    </Pagination>
                                </Nav>
                                <small className="fw-normal small mt-4 mt-lg-0">
                                    Visualizzazione <b>{locationsItem.length + (currentPage * 10)}</b> di <b>{locationsItem.length}</b> risultati
                                </small>
                            </Card>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="me-2" onClick={handleClose}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};