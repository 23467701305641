
import React, { useState } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AdjustmentsIcon, CheckIcon, DownloadIcon, PlusIcon, RefreshIcon, SearchIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, InputGroup, Dropdown } from 'react-bootstrap';

import { useRecoilState, useRecoilValue } from "recoil";
import { actionUtilityDisponibilita, eliminaAnagrafiche, eliminaDisponibilita } from "data/integrations";
import { Link } from "react-router-dom";
import { Routes } from "routes";
import { annoGestioneSelector, disponibilitaSelector, listaAnagraficheDisponibilitaSelector, listaDisponibilitaSelector, loginUserSelector } from "data/selectors";
import { collaborazioneDisponibilitaCurrentItemAtom, currentPageDisponibilitaAtom, dateDisponibilitaCurrentItemAtom, disponibilitaAllAtom, disponibilitaCurrentItemAtom, disponibilitaOrderItemAtom, disponibilitaOrderItemTypeAtom, disponibilitaRefreshIncrementAtom, disponibilitaRicercaObjAtom, disponibilitaSearchAtom, myDisponibilitaDataAtom, rowsPerPageDisponibilitaAtom, showDisponibilitaDetailModalAtom, statoDisponibilitaAtom } from "data/atom";
import { TableDisponibilita } from "./TablesDisponibilita";
import Select from "react-select";
import { CercaAnagraficaModal } from "./CercaAnagrafica";
import { exportToExcel } from 'react-json-to-excel';

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));

export default () => {
  const myUsers = useRecoilValue(listaDisponibilitaSelector);
  const anagraficheList = useRecoilValue(listaAnagraficheDisponibilitaSelector);
  const [disponibilitaRefresh, setDisponibilitaRefresh] = useRecoilState(disponibilitaRefreshIncrementAtom);

  const [orderOption, setOrderOption] = useRecoilState(disponibilitaOrderItemAtom);
    const [orderOptionType, setOrderOptionType] = useRecoilState(disponibilitaOrderItemTypeAtom);
  const [searchValue, setSearchValue] = useRecoilState(disponibilitaSearchAtom);
  const [myDataAtom, setMyDataAtom] = useRecoilState(myDisponibilitaDataAtom);

  const [itemModal, setItemModal] = useRecoilState(disponibilitaCurrentItemAtom);
  const [itemModalDate, setItemModalDate] = useRecoilState(dateDisponibilitaCurrentItemAtom);
  const [itemModalCollaborazione, setItemModalCollaborazione] = useRecoilState(collaborazioneDisponibilitaCurrentItemAtom);
  const [show, setShowDefault] = useRecoilState(showDisponibilitaDetailModalAtom);
  const annoGestione = useRecoilValue(annoGestioneSelector);

  let [users, setUsers] = useRecoilState(disponibilitaSelector);
  let [rowsPerPage, setRowsPerPage] = useRecoilState(rowsPerPageDisponibilitaAtom);
  const [propostaType, setPropostaType] = useRecoilState(statoDisponibilitaAtom);
  const [currentPage, setCurrentPage] = useRecoilState(currentPageDisponibilitaAtom);

  let totalUsers = users.length;
  let selectedUsersIds = users.filter(u => u.isSelected).map(u => u.id);

  const [myLoginUser, setLoginUser] = useRecoilState(loginUserSelector);
  const [disponibilitaRicercaObj, setDisponibilitaRicercaObj] = useRecoilState(disponibilitaRicercaObjAtom);

  const generaXlsData = () => {
    var myXlsData = [];
    myUsers.forEach(
      u => {
        var myItem = JSON.parse(JSON.stringify(u));
        myItem.disciplina = u.disciplina.map(u => u.value).join(',');
        myXlsData.push(myItem);
      }
    );

    return myXlsData;
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc' ?
        (a, b) => descendingComparator(a, b, orderBy) :
        (a, b) => -descendingComparator(a, b, orderBy);
}

const filterTableData = () => {
    var newData = JSON.parse(JSON.stringify(users));
    newData = newData.filter(u => (propostaType === 'N') ? (u.show && u.proposta === 'N') : u.show)
    
    var orderBy = orderOption;
    var orderType = orderOptionType;
    if (newData.length > 0) {
        newData = newData.sort(getComparator(orderType, orderBy))
    }

    if (searchValue.length > 0) {
        newData = newData.filter(u => (u.nominativo.toLowerCase().includes(searchValue.toLowerCase())));
    }

    var fieldArray =["nominativo","date_disponibilita","collaborazione","ruolo","disciplina_tipo","disciplina","trasporto_bus","trasporto_treno","autonomo"];
    var itemSearch = disponibilitaRicercaObj;

    for(var i=0;i<fieldArray.length;i++){
        var myField = fieldArray[i];
        if(fieldArray[i]==='disciplina'){
            // eslint-disable-next-line no-loop-func
            newData = newData.filter(u => ((u['disciplina'].map(ud => ud.value).join(',')).toLowerCase().includes(itemSearch[myField].toLowerCase())));
        }
        else {
            if(itemSearch[fieldArray[i]].length>0){
                // eslint-disable-next-line no-loop-func
                newData = newData.filter(u => (u[myField].toLowerCase().includes(itemSearch[myField].toLowerCase())));
            }
        }
    }

    newData = newData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);

    return newData;
  }

  let allSelected = (totalUsers > 0) ? selectedUsersIds.length === filterTableData().length : false;


  const onUpdateItem = async (obj) => {
    const newUsers = JSON.parse(JSON.stringify(users));
    const newMyUsers = JSON.parse(JSON.stringify(myUsers));
    let objIndex = newUsers.findIndex((element => element.id === obj.item_id));
    newUsers[objIndex][obj.key] = obj.value;
    if (obj.key === 'disciplina_tipo') {
      newUsers[objIndex]['disciplina'] = [{ label: 'Non selezionato', value: 'Non selezionato' }];
    }
    setUsers(newUsers);

    var myObj = {};
    myObj['action'] = 'update_item';
    myObj['subject'] = 'disponibilita';
    myObj['obj'] = obj;
    myObj['user'] = myLoginUser['username'];

    await actionUtilityDisponibilita(myObj);

    if (obj.key === 'disciplina_tipo') {
      obj.key = 'disciplina';
      obj.value = [{ label: 'Non selezionato', value: 'Non selezionato' }];
      myObj['action'] = 'update_item';
      myObj['subject'] = 'disponibilita';
      myObj['obj'] = obj;
      myObj['user'] = myLoginUser['username'];

      await actionUtilityDisponibilita(myObj);
    }
  };

  const changeSearchValue = (e) => {
    setCurrentPage(0);

    const newSearchValue = e.target.value;
  
    setSearchValue(newSearchValue);

    var newUsers = JSON.parse(JSON.stringify(users));
    newUsers = users.map(u => ({ ...u, isSelected: false }));
    setUsers(newUsers);
  };

  const selectAllUsers = () => {
    var newUsers = JSON.parse(JSON.stringify(users));
    var myData = filterTableData();

    /*
    selectedUsersIds.length === totalUsers ?
      users.map(u => ({ ...u, isSelected: false })) :
      users.map(u => ({ ...u, isSelected: true }));
    */
   for(var i=0;i<myData.length;i++){
      // eslint-disable-next-line no-loop-func
      newUsers = newUsers.map(u => u.id === myData[i].id ? ({ ...u, isSelected: !u.isSelected }) : u);
   }

    setUsers(newUsers);
  };

  const selectUser = (id) => {
    console.log(id);
    const newUsers = users.map(u => u.id === id ? ({ ...u, isSelected: !u.isSelected }) : u);
    setUsers(newUsers);
  };

  const deleteUsers = async (ids) => {
    const usersToBeDeleted = ids ? ids : selectedUsersIds;
    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Conferma Eliminazione Massiva",
      text: "Sei sicuro di voler eliminare " + usersToBeDeleted.length + " elementi ?",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Annulla"
    });

    if (result.isConfirmed) {
      var myObj = {};
      myObj['action'] = 'delete_bulk';
      myObj['obj'] = {};
      myObj['subject'] = 'disponibilita';
      myObj['obj']['ids'] = usersToBeDeleted;
      myObj['user'] = myLoginUser['username'];

      await eliminaDisponibilita(myObj);
      var refs = disponibilitaRefresh;
      refs++;
      setDisponibilitaRefresh(refs);
      setMyDataAtom(undefined);
      setSearchValue("");
      const confirmMessage = "Elementi eliminati con successo";

      await SwalWithBootstrapButtons.fire('Eliminazione completata', confirmMessage, 'success');
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Gestione Disponibilit&agrave; {annoGestione}</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          {(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
            <Button variant="info" size="sm" className="d-inline-flex align-items-center" onClick={() => {
              setItemModal(undefined);
              setItemModalDate(undefined);
              setItemModalCollaborazione({ label: annoGestione, value: annoGestione });
              setShowDefault(true);
            }}>
              <PlusIcon className="icon icon-xs me-2" /> Seleziona Anagrafica
            </Button>
            :
            null
          }
          <Button variant="info" size="sm" className="ms-2 ms-lg-3 d-inline-flex align-items-center" onClick={() => {
            var refs = disponibilitaRefresh;
            refs++;
            setDisponibilitaRefresh(refs);
            setMyDataAtom(undefined);
            setSearchValue("");
          }}>
            <RefreshIcon className="icon icon-xs me-2" /> Aggiorna
          </Button>
          <Button variant="info" size="sm" className="ms-2 ms-lg-3 d-inline-flex align-items-center" onClick={() => {
            exportToExcel(generaXlsData(), 'Disponibilità')
          }}>
            <DownloadIcon className="icon icon-xs me-2" /> Esporta Dati Disponibilit&agrave;
          </Button>
        </div>
      </div>

      <div className="table-settings mb-1">
        <Row className="justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-300">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Cerca Disponibilità"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
          </Col>
          <Col xs={3} lg={4} className="d-flex justify-content-end">
            <ButtonGroup>
              <Dropdown className="me-1">
                <Dropdown.Toggle split as={Button} variant="info" className="text-dark m-0 p-1">
                  <AdjustmentsIcon className="icon icon-sm" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end pb-0">
                  <span className="small ps-3 fw-bold text-dark">
                    Righe per pagina
                  </span>
                  <Dropdown.Item className="d-flex align-items-center fw-bold" onClick={() => { setRowsPerPage(10) }}>
                    10 {
                      (rowsPerPage === 10) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                    }
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold" onClick={() => { setRowsPerPage(20) }}>20 {
                    (rowsPerPage === 20) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                  }</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom" onClick={() => { setRowsPerPage(50) }}>50 {
                    (rowsPerPage === 50) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                  }</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom" onClick={() => { setRowsPerPage(100) }}>100 {
                    (rowsPerPage === 100) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                  }</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom" onClick={() => { setRowsPerPage(200) }}>200 {
                    (rowsPerPage === 200) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                  }</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
          </Col>
        </Row>
      </div>

      <TableDisponibilita
        mydata={filterTableData()}
        allSelected={allSelected}
        selectUser={selectUser}
        deleteUsers={deleteUsers}
        selectAllUsers={selectAllUsers}
        onUpdateItem={onUpdateItem}
      />
      <CercaAnagraficaModal />
    </>
  );
};
