import React from "react";
import Select from "react-select";
import moment from "moment-timezone";
import 'moment/locale/it';
import Datetime from "react-datetime";
import { CalendarIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Modal, Button, InputGroup } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from "recoil";
import { showUtilityDetailModalAtomCampLocationDate, utilityItemAtomCampLocationDate } from "data/atom";
import { locationDateSelectSelector } from "data/selectors";


export const DetailsLocationDateModal = (props) => {
    const [item, setItem] = useRecoilState(utilityItemAtomCampLocationDate);
    const [show, setShowDefault] = useRecoilState(showUtilityDetailModalAtomCampLocationDate);

    const locationsItem = useRecoilValue(locationDateSelectSelector);

    const { edit = false, title } = props;

    const onConfirm = () => {

        const payload = JSON.parse(JSON.stringify(item));

        if (edit) {
            return props.onUpdateLocationData && props.onUpdateLocationData(payload);
        }

        return props.onAddLocationData && props.onAddLocationData(payload);
    }

    const isDataValida = () => {
        var myDateStr = currentItemKey('data_inizio') ? currentItemKey('data_inizio') : "";
        //var myDate = (myDateStr === "") ? moment() : moment(myDateStr, "DD/MM/YYYY");
        var dataValidaInizio = moment(myDateStr, "DD/MM/YYYY").format("DD/MM/YYYY") === myDateStr;

        myDateStr = currentItemKey('data_fine') ? currentItemKey('data_fine') : "";
        //var myDate = (myDateStr === "") ? moment() : moment(myDateStr, "DD/MM/YYYY");
        var dataValidaFine = moment(myDateStr, "DD/MM/YYYY").format("DD/MM/YYYY") === myDateStr;

        return (dataValidaInizio & dataValidaFine);
    }

    const handleClose = () => setShowDefault(false);

    const currentItemKey = (key) => {
        var value = (item !== undefined) ? (item[key] !== undefined) ? item[key] : undefined : undefined;
        return value;
    };

    function getDataInizio() {
        var myDateStr = currentItemKey('data_inizio') ? currentItemKey('data_inizio') : "";
        return myDateStr;
    }

    function getDataFine() {
        var myDateStr = currentItemKey('data_fine') ? currentItemKey('data_fine') : "";
        return myDateStr;
    }

    function valid(current) {

        var myDateStrInizio = currentItemKey('data_inizio') ? currentItemKey('data_inizio') : "";
        //var myDate = (myDateStr === "") ? moment() : moment(myDateStr, "DD/MM/YYYY");
        var dataValidaInizio = moment(myDateStrInizio, "DD/MM/YYYY")

        
        var difference = current.diff(dataValidaInizio, "days");

        return (difference>0 && difference<=6);
      }

    return (
        <Modal as={Modal.Dialog} centered show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">{title}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Form className="modal-content">
                <Modal.Body>
                    <Row className="justify-content-center">
                        <Col xs={12} lg={12}>
                            <Form.Group className="mb-4">
                                <Form.Label>Seleziona una Location </Form.Label>
                                <Select options={locationsItem}
                                    onChange={(selectedOption) => {
                                        let objTemp = JSON.parse(JSON.stringify(item));
                                        objTemp['id_location'] = selectedOption.value;
                                        setItem(objTemp);
                                        console.log(objTemp);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {(item['id_location'] !== undefined && item['id_location'] !== '') ?
                        <Row className="justify-content-center">
                            <Col xs={12} lg={(item['data_inizio'] === undefined || item['data_inizio'] === '')? 12 :6}>
                                <Form.Group id="data_inizio" className="mb-4">
                                    <Form.Label>Data Inizio</Form.Label>
                                    <Datetime
                                        locale="it"
                                        timeFormat={false}
                                        dateFormat={"DD/MM/YYYY"}
                                        inputProps={{
                                            disabled: false
                                        }}
                                        onChange={(e) => {
                                            console.log(e.format("DD-MM-YYYY"));
                                            var objTemp = (item === undefined) ? {} : JSON.parse(JSON.stringify(item));
                                            objTemp['data_inizio'] = e.format("DD/MM/YYYY");
                                            setItem(objTemp);
                                            console.log(objTemp);
                                        }}
                                        renderInput={(props, openCalendar) => (
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <CalendarIcon className="icon icon-xs" />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    value={getDataInizio()}
                                                    placeholder="dd/mm/yyyy"
                                                    onFocus={openCalendar}
                                                    onChange={(e) => {
                                                        var objTemp = (item === undefined) ? {} : JSON.parse(JSON.stringify(item));
                                                        objTemp['data_inizio'] = e.target.value;
                                                        setItem(objTemp);
                                                        console.log(objTemp);
                                                    }} />
                                            </InputGroup>
                                        )} />
                                </Form.Group>
                            </Col>
                            {(item['data_inizio'] !== undefined && item['data_inizio'] !== '') ?
                                <Col xs={12} lg={6}>
                                    <Form.Group id="data_fine" className="mb-4">
                                        <Form.Label>Data Fine</Form.Label>
                                        <Datetime
                                            isValidDate={valid}
                                            locale="it"
                                            timeFormat={false}
                                            dateFormat={"DD/MM/YYYY"}
                                            inputProps={{
                                                disabled: false
                                            }}
                                            onChange={(e) => {
                                                console.log(e.format("DD-MM-YYYY"));
                                                var objTemp = (item === undefined) ? {} : JSON.parse(JSON.stringify(item));
                                                objTemp['data_fine'] = e.format("DD/MM/YYYY");
                                                setItem(objTemp);
                                                console.log(objTemp);
                                            }}
                                            renderInput={(props, openCalendar) => (
                                                <InputGroup>
                                                    <InputGroup.Text>
                                                        <CalendarIcon className="icon icon-xs" />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        value={getDataFine()}
                                                        placeholder="dd/mm/yyyy"
                                                        onFocus={openCalendar}
                                                        onChange={(e) => {
                                                            var objTemp = (item === undefined) ? {} : JSON.parse(JSON.stringify(item));
                                                            objTemp['data_fine'] = e.target.value;
                                                            setItem(objTemp);
                                                            console.log(objTemp);
                                                        }} />
                                                </InputGroup>
                                            )} />
                                    </Form.Group>
                                </Col>
                                :
                                null
                            }
                        </Row>
                        :
                        null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="me-2" onClick={onConfirm}
                        disabled={item['id_location'] === undefined || item['data_inizio'] === undefined || item['data_fine'] === undefined || !isDataValida()}
                    >
                        Salva
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};