import React, { useState } from "react";
import Select from "react-select";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { ArchiveIcon, CalendarIcon, CameraIcon, CheckIcon, ClipboardCheckIcon, ClockIcon, EyeIcon, PaperClipIcon, PlusIcon, SelectorIcon, ShareIcon, TagIcon, UserGroupIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Modal, Button, InputGroup, Image, Badge, FloatingLabel, Card } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from "recoil";
import { disponibilitaDettaglioClonaModalAtom, disponibilitaRefreshIncrementAtom, disponibilitaSearchAtom, myDisponibilitaDataAtom, showDisponibilitaClonaModalAtom, } from "data/atom";
import { listaDateDisponibilitaSelector } from "data/selectors";
import { actionUtilityDisponibilita } from "data/integrations";


export const DetailsDisponibilitaClonaModal = (props) => {
    const [item, setItem] = useRecoilState(disponibilitaDettaglioClonaModalAtom);
    const [show, setShowDefault] = useRecoilState(showDisponibilitaClonaModalAtom);
    const dateDisponibilitaList = useRecoilValue(listaDateDisponibilitaSelector);
    const handleClose = () => setShowDefault(false);
    const [searchValue, setSearchValue] = useRecoilState(disponibilitaSearchAtom);
    const [disponibilitaRefresh, setDisponibilitaRefresh] = useRecoilState(disponibilitaRefreshIncrementAtom);
    const [myDataAtom, setMyDataAtom] = useRecoilState(myDisponibilitaDataAtom);

    const onConfirm = async () => {
        var myObj = {};
        myObj['action'] = 'add_item';
        myObj['subject'] = 'disponibilita';
        var obj = JSON.parse(JSON.stringify(item));
        obj['disciplina'] =   obj['disciplina'].map(u => u.value).join(',');
        myObj['obj'] = obj;

        console.log(myObj);

        await actionUtilityDisponibilita(myObj);

        if(props.onUpdateItem){
            props.onUpdateItem();
        } 

        var refs = disponibilitaRefresh;
        refs++;
        setDisponibilitaRefresh(refs);
        //setSearchValue("");
        setMyDataAtom(undefined);
        setShowDefault(false);
        refs++;
        setDisponibilitaRefresh(refs);

    }

    return (
        <Modal as={Modal.Dialog} centered show={show} onHide={handleClose} size="lg">
            <Modal.Header>
                <Modal.Title className="h6">Clona Disponibilit&agrave; {(item !== undefined)? item['nominativo']:""}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
                <Card as="form" border="0" className="shadow pb-4 mb-4">
                    <Card.Body className="p-0 p-md-4">
                        <Row className="justify-content-center">
                            <Col xs={12} lg={12}>
                                {(dateDisponibilitaList.length > 0) ?
                                    <Form.Group id="date_disponibilita" className="mb-4">
                                        <Form.Label>Seleziona Periodo</Form.Label>
                                        <Select options={dateDisponibilitaList} isMulti
                                            onChange={(selectedOptions) => {
                                                var newItem = JSON.parse(JSON.stringify(item));
                                                if (selectedOptions.length > 0) {
                                                    newItem['date_disponibilita'] = selectedOptions;
                                                }
                                                else {
                                                    newItem['date_disponibilita'] = undefined;
                                                }
                                                setItem(newItem);
                                            }}
                                        />
                                    </Form.Group>
                                    :
                                    null
                                }
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Modal.Body>
            <Modal.Footer>
                {(item !== undefined) ?
                    <Button variant="primary" className="me-2" onClick={onConfirm}
                        disabled={item['date_disponibilita'] === undefined}
                    >
                        Clona Disponibilit&agrave;
                    </Button>
                    :
                    null
                }
                <Button variant="primary" className="me-2" onClick={handleClose}
                >
                    Chiudi
                </Button>
            </Modal.Footer>
        </Modal>
    );
};