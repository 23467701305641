
import React, { useState } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AdjustmentsIcon, CheckIcon, DownloadIcon, PlusIcon, RefreshIcon, SearchIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, InputGroup, Dropdown } from 'react-bootstrap';

import { useRecoilState, useRecoilValue } from "recoil";
import { anagraficaSearchAtom, anagraficheCurrentItemAtom, anagraficheRefreshIncrementAtom, currentPageAnagraficaAtom, editUtilityDetailModalAtom, formAnagraficaProvenienza, myAnagraficaDataAtom, rowsPerPageAnagraficaAtom, utilityItemAtom } from "data/atom";
import { eliminaAnagrafiche } from "data/integrations";
import { Link } from "react-router-dom";
import { Routes } from "routes";
import { TableAnagrafica } from "./TablesAnagrafica";
import { anagraficheSelector, listaAnagraficheSelector, loginUserSelector } from "data/selectors";

import { exportToExcel } from 'react-json-to-excel';


const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));

export default () => {
  const myUsers = useRecoilValue(listaAnagraficheSelector);
  const [anagraficheRefresh, setAnagraficheRefresh] = useRecoilState(anagraficheRefreshIncrementAtom);

  const [searchValue, setSearchValue] = useRecoilState(anagraficaSearchAtom);
  const [myDataAtom, setMyDataAtom] = useRecoilState(myAnagraficaDataAtom);

  const [edit, setEditModal] = useRecoilState(editUtilityDetailModalAtom);
  const [itemModal, setItemModal] = useRecoilState(anagraficheCurrentItemAtom);
  const [provenienza, setProvenienza] = useRecoilState(formAnagraficaProvenienza);
  const [currentPage, setCurrentPage] = useRecoilState(currentPageAnagraficaAtom);

  let [users, setUsers] = useRecoilState(anagraficheSelector);
  let [rowsPerPage, setRowsPerPage] = useRecoilState(rowsPerPageAnagraficaAtom);

  let totalUsers = users.length;
  let selectedUsersIds = users.filter(u => u.isSelected).map(u => u.id);
  let allSelected = (totalUsers > 0) ? selectedUsersIds.length === totalUsers : false;

  const [myLoginUser, setLoginUser] = useRecoilState(loginUserSelector);

  const generaXlsData = () => {
    var myXlsData=[];
    myUsers.forEach(
      u => {
        var myItem = JSON.parse(JSON.stringify(u));
        myItem['blsd']=(u.blsd===undefined) ? 'NO':u.blsd;
        myItem['assistente_bagnante']=(u.assistente_bagnante===undefined) ? 'NO':u.assistente_bagnante;
        myItem['cv']=(u.cv!==undefined && u.cv.length > 0) ? "https://www.reclutamentoxpcamp.it/backend/" + u.cv : "NON CARICATO";
        myXlsData.push(myItem);
      }
    );

    return myXlsData;
  }

  const changeSearchValue = (e) => {
    setCurrentPage(0);

    const newSearchValue = e.target.value;
    const newUsers = users.map(u => ({ ...u, isSelected: false, show: ((u.nome.toLowerCase().includes(newSearchValue.toLowerCase())) | (u.cognome.toLowerCase().includes(newSearchValue.toLowerCase())) | (u.data_nascita.toLowerCase().includes(newSearchValue.toLowerCase())) | (u.email.toLowerCase().includes(newSearchValue.toLowerCase()))) }));

    setSearchValue(newSearchValue);
    if(newSearchValue===""){
      setMyDataAtom(undefined);
    }
    //setUsers(newUsers);
  };

  const selectAllUsers = () => {
    const newUsers = selectedUsersIds.length === totalUsers ?
      users.map(u => ({ ...u, isSelected: false })) :
      users.map(u => ({ ...u, isSelected: true }));

    setUsers(newUsers);
  };

  const selectUser = (id) => {
    console.log(id);
    const newUsers = users.map(u => u.id === id ? ({ ...u, isSelected: !u.isSelected }) : u);
    setUsers(newUsers);
  };

  const deleteUsers = async (ids) => {
    const usersToBeDeleted = ids ? ids : selectedUsersIds;
    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Conferma Eliminazione Massiva",
      text: "Sei sicuro di voler eliminare " + usersToBeDeleted.length + " elementi ?",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Annulla"
    });

    if (result.isConfirmed) {
      var myObj = {};
      myObj['action'] = 'delete_bulk';
      myObj['obj'] = {};
      myObj['obj']['ids'] = usersToBeDeleted;
      myObj['user'] = myLoginUser['username'];
      await eliminaAnagrafiche(myObj);
      var refs = anagraficheRefresh;
      refs++;
      setAnagraficheRefresh(refs);
      setMyDataAtom(undefined);
      setSearchValue("");
      const confirmMessage = "Elementi eliminati con successo";

      await SwalWithBootstrapButtons.fire('Eliminazione completata', confirmMessage, 'success');
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Gestione Anagrafiche</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
        {(myLoginUser['tipo'] === 0 || myLoginUser['tipo'] === '0') ?
          <Button as={Link} to={Routes.FormAnagrafica.path} variant="info" size="sm" className="d-inline-flex align-items-center" onClick={() => {
            setProvenienza("A");
            setItemModal(undefined);
          }}>
            <PlusIcon className="icon icon-xs me-2" /> Nuova Anagrafica
          </Button>
          :
          null
          }
          <Button variant="info" size="sm" className="ms-2 ms-lg-3 d-inline-flex align-items-center" onClick={() => {
            var refs = anagraficheRefresh;
            refs++;
            setAnagraficheRefresh(refs);
            setMyDataAtom(undefined);
            setSearchValue("");
          }}>
            <RefreshIcon className="icon icon-xs me-2" /> Aggiorna
          </Button>
          <Button variant="info" size="sm" className="ms-2 ms-lg-3 d-inline-flex align-items-center" onClick={() => {
            exportToExcel(generaXlsData(), 'Anagrafiche')
          }}>
            <DownloadIcon className="icon icon-xs me-2" /> Esporta Dati Anagrafica
          </Button>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-300">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Cerca Anagrafica"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
          </Col>
          <Col xs={3} lg={4} className="d-flex justify-content-end">
            <ButtonGroup>
              <Dropdown className="me-1">
                <Dropdown.Toggle split as={Button} variant="info" className="text-dark m-0 p-1">
                  <AdjustmentsIcon className="icon icon-sm" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end pb-0">
                  <span className="small ps-3 fw-bold text-dark">
                    Righe per pagina
                  </span>
                  <Dropdown.Item className="d-flex align-items-center fw-bold" onClick={() => { setRowsPerPage(10) }}>
                    10 {
                      (rowsPerPage === 10) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                    }
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold" onClick={() => { setRowsPerPage(20) }}>20 {
                    (rowsPerPage === 20) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                  }</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom" onClick={() => { setRowsPerPage(50) }}>50 {
                    (rowsPerPage === 50) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                  }</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom" onClick={() => { setRowsPerPage(100) }}>100 {
                    (rowsPerPage === 100) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                  }</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom" onClick={() => { setRowsPerPage(200) }}>200 {
                    (rowsPerPage === 200) ? <CheckIcon className="icon icon-xxs ms-auto" /> : <span>&nbsp;</span>
                  }</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
          </Col>
        </Row>
      </div>

      <TableAnagrafica
        mydata={users.filter(u => u.show)}
        allSelected={allSelected}
        selectUser={selectUser}
        deleteUsers={deleteUsers}
        selectAllUsers={selectAllUsers}
      />
    </>
  );
};
