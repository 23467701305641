import React, { useState } from "react";
import { Form, Modal, Button } from 'react-bootstrap';
import { useRecoilState } from "recoil";
import { confermeNoteModalItemAtom, showNoteConfermeModalAtom } from "data/atom";


export const NoteConfermeModal = (props) => {
    const [show, setShowDefault] = useRecoilState(showNoteConfermeModalAtom);
    const [noteDisp, setNoteDisp] = useState('');
    const [itemNoteModal, setItemNoteModal] = useRecoilState(confermeNoteModalItemAtom);

    const onDescrizioneChange = (e) => {
        setNoteDisp(e.target.value)
    }

    const onConfirm = () => {
        var myNote = noteDisp;
        if(noteDisp.length<=0){
            myNote=itemNoteModal['value'];
        }
        const payload = {item_id: props.id,key:'note', value:myNote};
        setNoteDisp('');

        if (props.onUpdateItem) props.onUpdateItem(payload);
        setShowDefault(false);
    }

    const handleClose = () => setShowDefault(false);


    return (
        <Modal as={Modal.Dialog} centered show={show} onHide={handleClose} size="lg">
            <Modal.Header>
                <Modal.Title className="h6">{props.title}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Form className="modal-content">
                <Modal.Body>
                    <Form.Group id="note" className="mb-4">
                        <Form.Label>Note</Form.Label>
                        <Form.Control
                            as="textarea" rows="5"
                            required
                            autoFocus
                            value={(noteDisp.length>0) ? noteDisp : itemNoteModal['value']}
                            onChange={onDescrizioneChange} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="me-2" onClick={onConfirm} 
                    >
                        Salva Note
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};