
import React, { useState } from "react";
import moment from "moment-timezone";
import 'moment/locale/it';
import { ArrowLeftIcon, ArrowNarrowDownIcon, ArrowNarrowUpIcon, ArrowRightIcon, CheckCircleIcon, ChevronDownIcon, ChevronUpIcon, DotsHorizontalIcon, ExternalLinkIcon, EyeIcon, InformationCircleIcon, PencilAltIcon, ShieldExclamationIcon, TrashIcon, UserAddIcon, UserRemoveIcon, ViewGridAddIcon, XCircleIcon } from "@heroicons/react/solid";
import { Col, Row, Nav, Card, Form, Image, Button, Table, Dropdown, ProgressBar, Pagination, Tooltip, FormCheck, ButtonGroup, OverlayTrigger } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from "recoil";
import { UtilityListSelector, campAllItemsSelector } from "data/selectors";
import { currentPageUtilityAtomCamp, editUtilityDetailModalAtomCamp, editUtilityDetailModalAtomCampLocation, editUtilityDetailModalAtomCampLocationDate, gestioneCampLocationOrderItemAtom, gestioneCampOrderItemTypeAtom, myUtilityAtomCamp, rowsPerPageUtilityAtomCamp, selectedLocationCampAtom, selectedLocationTrasportoAtom, selectedLocationTrasportoBusAtom, selectedLocationTrasportoTrenoAtom, showUtilityDetailModalAtomCamp, showUtilityDetailModalAtomCampLocation, showUtilityDetailModalAtomCampLocationDate, showUtilityDetailModalAtomLocationTrasporto, showUtilityDetailModalAtomLocationTrasportoBus, showUtilityDetailModalAtomLocationTrasportoTreno, tipoUtilityAtomCamp, utilityItemAtomCamp, utilityItemAtomCampLocation, utilityItemAtomCampLocationDate, utilityItemAtomLocationTrasporto, utilityModalTitleAtomCamp, utilityRefreshAtomCamp, utilitySearchAtomCamp } from "data/atom";
import Swal from 'sweetalert2';
import { actionUtilityCamp, estraiLocationBusById, estraiLocationTrenoById } from "data/integrations";
import withReactContent from "sweetalert2-react-content";
import { DetailsLocationModal } from "./DetailsLocation";
import { DetailsLocationDateModal } from "./DetailsLocationDate";
import { DetailsLocationCampModal } from "./DetailsLocationCamp";
import { DetailsLocationTreno } from "./DetailsLocationTreno";
import { DetailsLocationBus } from "./DetailsLocationBus";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary me-3',
        cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));


export const LocationTable = (props) => {
    const { mydata = [], allSelected } = props;
    const [bulkOption, setBulkOption] = useState('delete_user');
    const disabledBulkMenu = mydata.filter(u => u.isSelected).length === 0;
    const myUsers = useRecoilValue(campAllItemsSelector);
    const [tipoUtility, setTipoUtility] = useRecoilState(tipoUtilityAtomCamp);
    const [currentPage, setCurrentPage] = useRecoilState(currentPageUtilityAtomCamp);
    let [rowsPerPage, setRowsPerPage] = useRecoilState(rowsPerPageUtilityAtomCamp);
    const [utilityRefresh, setRefreshUtility] = useRecoilState(utilityRefreshAtomCamp);
    const [utilityAtom, setUtilityAtom] = useRecoilState(myUtilityAtomCamp);
    const [searchValueUtility, setSearchValueUtility] = useRecoilState(utilitySearchAtomCamp);

    const [show, setShowModal] = useRecoilState(showUtilityDetailModalAtomCampLocation);
    const [edit, setEditModal] = useRecoilState(editUtilityDetailModalAtomCampLocation);
    const [showDate, setShowModalDate] = useRecoilState(showUtilityDetailModalAtomCampLocationDate);
    const [editDate, setEditModalDate] = useRecoilState(editUtilityDetailModalAtomCampLocationDate);
    const [showCamp, setShowModalCamp] = useRecoilState(showUtilityDetailModalAtomCamp);
    const [editCamp, setEditModalCamp] = useRecoilState(editUtilityDetailModalAtomCamp);
    const [itemModal, setItemModal] = useRecoilState(utilityItemAtomCampLocation);
    const [itemModalDate, setItemModalDate] = useRecoilState(utilityItemAtomCampLocationDate);
    const [itemModalCamp, setItemModalCamp] = useRecoilState(utilityItemAtomCamp);
    const [titleModal, setTileModal] = useRecoilState(utilityModalTitleAtomCamp);
    const [idLocation, setIdLocation] = useRecoilState(selectedLocationCampAtom);

    const [orderOption, setOrderOption] = useRecoilState(gestioneCampLocationOrderItemAtom);
    const [orderOptionType, setOrderOptionType] = useRecoilState(gestioneCampOrderItemTypeAtom);

    const [itemLocationTrasporto, setItemLocationTrasporto] = useRecoilState(utilityItemAtomLocationTrasporto);
    const [showLocationTrasportoTreno, setShowLocationTrasportoTreno] = useRecoilState(showUtilityDetailModalAtomLocationTrasportoTreno);
    const [showLocationTrasportoBus, setShowLocationTrasportoBus] = useRecoilState(showUtilityDetailModalAtomLocationTrasportoBus);
    const [trasportoLocationTreno, setTrasportoLocationTreno] = useRecoilState(selectedLocationTrasportoTrenoAtom);
    const [trasportoLocationBus, setTrasportoLocationBus] = useRecoilState(selectedLocationTrasportoBusAtom);

    function quantiUsers() {
        if (searchValueUtility.length === 0) {
            var quanti = myUsers.length;
            return quanti;
        }
        else {
            var newUsers = myUsers.filter(u => (u.descrizione.toLowerCase().includes(searchValueUtility.toLowerCase())));
            return newUsers.length;
        }
    }

    const orderActionChange = (e) => {
        const newOption = e.target.value;
        setOrderOption(newOption);
    }

    const onAddItemLocation = async (obj) => {
        var myObj = {};
        myObj['action'] = 'add_item_location';
        myObj['subject'] = tipoUtility;
        myObj['obj'] = obj;

        await actionUtilityCamp(myObj);
        var refs = utilityRefresh;
        refs++;
        setRefreshUtility(refs);
        setUtilityAtom(undefined);
        setSearchValueUtility("");
        const confirmMessage = "L'elemento è stato aggiunto con successo";

        await SwalWithBootstrapButtons.fire('Inserimento completato', confirmMessage, 'success');
        setShowModal(false);
        setShowModalDate(false);
        setShowModalCamp(false);
        setShowLocationTrasportoTreno(false);
        setShowLocationTrasportoBus(false);
    };

    const onAddItemLocationData = async (obj) => {
        var myObj = {};
        myObj['action'] = 'add_item_location_data';
        myObj['subject'] = tipoUtility + '_date';
        myObj['obj'] = obj;

        await actionUtilityCamp(myObj);
        var refs = utilityRefresh;
        refs++;
        setRefreshUtility(refs);
        setUtilityAtom(undefined);
        setSearchValueUtility("");
        const confirmMessage = "Le date sono state aggiunte con successo";

        await SwalWithBootstrapButtons.fire('Inserimento completato', confirmMessage, 'success');
        setShowModal(false);
        setShowModalCamp(false);
        setShowLocationTrasportoTreno(false);
        setShowLocationTrasportoBus(false);
    };

    const onUpdateItemLocation = async (obj) => {
        var myObj = {};
        myObj['action'] = 'update_item_location';
        myObj['subject'] = tipoUtility;
        myObj['obj'] = obj;

        await actionUtilityCamp(myObj);
        var refs = utilityRefresh;
        refs++;
        setRefreshUtility(refs);
        setUtilityAtom(undefined);
        setSearchValueUtility("");
        const confirmMessage = "L'elemento " + obj['descrizione'] + " è stato aggiornato con successo";

        await SwalWithBootstrapButtons.fire('Aggiornamento completato', confirmMessage, 'success');
        setShowModal(false);
        setShowModalDate(false);
        setShowModalCamp(false);
        setShowLocationTrasportoTreno(false);
        setShowLocationTrasportoBus(false);
    };

    const onUpdateItemLocationData = async (obj) => {
        var myObj = {};
        myObj['action'] = 'update_item_location_data';
        myObj['subject'] = tipoUtility + '_date';
        myObj['obj'] = obj;

        await actionUtilityCamp(myObj);
        var refs = utilityRefresh;
        refs++;
        setRefreshUtility(refs);
        setUtilityAtom(undefined);
        setUtilityAtom(undefined);
        setSearchValueUtility("");
        const confirmMessage = "Le date sono state aggiornate con successo";

        await SwalWithBootstrapButtons.fire('Aggiornamento completato', confirmMessage, 'success');
        setShowModal(false);
        setShowModalDate(false);
        setShowModalCamp(false);
        setShowLocationTrasportoTreno(false);
        setShowLocationTrasportoBus(false);
    };

    const onUpdateItemLocationCamp = async (obj) => {
        var myObj = {};
        myObj['action'] = 'update_item_location_camp';
        myObj['subject'] = 'camp';
        myObj['obj'] = obj;

        await actionUtilityCamp(myObj);
        var refs = utilityRefresh;
        refs++;
        setRefreshUtility(refs);
        setUtilityAtom(undefined);
        setSearchValueUtility("");
        const confirmMessage = "I camp sono state aggiornati con successo";

        await SwalWithBootstrapButtons.fire('Aggiornamento completato', confirmMessage, 'success');
        setShowModal(false);
        setShowModalDate(false);
        setShowLocationTrasportoTreno(false);
        setShowLocationTrasportoBus(false);
    };

    const onUpdateLocationTreno = async (obj) => {
        var myObj = {};
        myObj['action'] = 'update_item_location_trasporto';
        myObj['subject'] = 'location_trasporto_treno';
        myObj['obj'] = obj;

        await actionUtilityCamp(myObj);
        var refs = utilityRefresh;
        refs++;
        setRefreshUtility(refs);
        setItemLocationTrasporto({
            id_location: undefined,
            trasporto: []
        });
        setSearchValueUtility("");
        const confirmMessage = "Lista stazioni aggiornate con successo";

        await SwalWithBootstrapButtons.fire('Aggiornamento completato', confirmMessage, 'success');
        setShowModal(false);
        setShowModalDate(false);
        setShowLocationTrasportoTreno(false);
        setShowLocationTrasportoBus(false);
    };

    const onUpdateLocationBus = async (obj) => {
        var myObj = {};
        myObj['action'] = 'update_item_location_trasporto';
        myObj['subject'] = 'location_trasporto_bus';
        myObj['obj'] = obj;

        await actionUtilityCamp(myObj);
        var refs = utilityRefresh;
        refs++;
        setRefreshUtility(refs);
        setItemLocationTrasporto({
            id_location: undefined,
            trasporto: []
        });
        setSearchValueUtility("");
        const confirmMessage = "Lista stazioni aggiornate con successo";

        await SwalWithBootstrapButtons.fire('Aggiornamento completato', confirmMessage, 'success');
        setShowModal(false);
        setShowModalDate(false);
        setShowLocationTrasportoTreno(false);
        setShowLocationTrasportoBus(false);
    };

    const selectUser = (id) => {
        props.selectUser && props.selectUser(id);
    };

    const selectAllUsers = () => {
        props.selectAllUsers && props.selectAllUsers();
    };

    const bulkActionChange = (e) => {
        const newOption = e.target.value;
        setBulkOption(newOption);
    }

    const applyBulkAction = () => {
        if (bulkOption === "delete_user") deleteUsers();
    }

    const deleteUsers = (ids) => {
        props.deleteUsers && props.deleteUsers(ids)
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc' ?
            (a, b) => descendingComparator(a, b, orderBy) :
            (a, b) => -descendingComparator(a, b, orderBy);
    }

    function descendingDateComparator(a, b, orderBy1, orderBy2) {
        let date1 = moment(a[orderBy1]);
        let date2 = moment(b[orderBy1]);
        let difference = date1.diff(date2, "seconds");

        if (difference > 0) {
            return -1;
        }
        if (difference < 0) {
            return 1;
        }

        date1 = moment(a[orderBy2]);
        date2 = moment(b[orderBy2]);
        difference = date1.diff(date2, "seconds");

        if (difference > 0) {
            return -1;
        }
        if (difference < 0) {
            return 1;
        }

        return 0;
    }

    function getDateComparator(order, orderBy1, orderBy2) {
        return order === 'desc' ?
            (a, b) => descendingDateComparator(a, b, orderBy1, orderBy2) :
            (a, b) => -descendingDateComparator(a, b, orderBy1, orderBy2);
    }


    const generaTabellaDate = (date_loc) => {
        var myDateArr = [];
        date_loc.forEach((element) => {
            var myElement = JSON.parse(JSON.stringify(element));
            myElement.data_stringa = moment(element.inizio, "DD/MM/YYYY").format("YYYYMMDD");
            myDateArr.push(myElement);
        });

        myDateArr = myDateArr.sort(getComparator('asc', 'data_stringa'));

        var trArray = [];
        myDateArr.forEach((element) => {
            trArray.push(<tr className="border-0" key={element.id + '-' + element.inizio + '-' + element.fine}>
                <td className="border-0 p-0 m-0" style={{ 'fontSize': 'small' }}>{element.inizio} - {element.fine} &nbsp;
                    <Button variant="soft" size="xs" onClick={async () => {
                        var myObj = {};
                        var obj = { id: element.id }
                        myObj['action'] = 'delete_item_location_data';
                        myObj['subject'] = tipoUtility + '_date';
                        myObj['obj'] = obj;

                        await actionUtilityCamp(myObj);
                        var refs = utilityRefresh;
                        refs++;
                        setRefreshUtility(refs);
                        setUtilityAtom(undefined);
                        setUtilityAtom(undefined);
                        setSearchValueUtility("");
                        const confirmMessage = "Le date sono state aggiornate con successo";

                        await SwalWithBootstrapButtons.fire('Aggiornamento completato', confirmMessage, 'success');
                        setShowModal(false);
                        setShowModalDate(false);
                    }}>
                        <TrashIcon variant="info" className="icon icon-xs text-danger" style={{ "cursor": "pointer" }} />
                    </Button>
                </td>
            </tr>);
        });
        return <Table ><tbody className="border-0">{trArray}</tbody></Table>;
    }
    const TableRow = (props) => {
        const { id, descrizione, date_location, isSelected } = props;

        return (
            <tr className="border-bottom">
                <td>
                    <FormCheck type="checkbox" className="dashboard-check">
                        <FormCheck.Input id={`user-${id}`} checked={isSelected} onChange={() => selectUser(id)} />
                        <FormCheck.Label htmlFor={`user-${id}`} />
                    </FormCheck>
                </td>
                <td>
                    <span className="fw-normal">{descrizione}</span>
                </td>
                <td>
                    {(date_location.length > 0) ?
                        generaTabellaDate(date_location)
                        :
                        <span>&nbsp;</span>
                    }
                </td>
                <td>
                    {(date_location.length > 0) ?
                        <Button variant="info" size="s" onClick={() => {
                            setTileModal("Modifica Camp per Location " + descrizione);
                            setEditModalCamp(true);
                            setIdLocation(id);
                            var myItemModal = { id_location: id };
                            setItemModal({ 'descrizione': '' });
                            setItemModalDate({});
                            setItemModalCamp(myItemModal);
                            setShowModalCamp(true);
                        }}>
                            <PencilAltIcon variant="info" className="icon icon-xs" style={{ "cursor": "pointer" }} />
                            Assegna Camp
                        </Button>
                        :
                        <span className="fw-normal">&nbsp;</span>
                    }
                </td>
                <td>
                    <Button variant="info" size="s" onClick={async () => {
                        setTileModal("Stazioni Treno per Location " + descrizione);
                        setIdLocation(id);
                        var trasporti = await estraiLocationTrenoById(id);
                        let opts = [];
                        if (trasporti.length > 0) {
                            trasporti.forEach((element) => opts.push({ value: element.stazione, label: element.stazione }));
                        }
                        var myItemModal = { id_location: id, trasporto: opts };
                        setTrasportoLocationTreno([]);
                        setItemLocationTrasporto(myItemModal);
                        setShowLocationTrasportoTreno(true);
                    }}>
                        <PencilAltIcon variant="info" className="icon icon-xs" style={{ "cursor": "pointer" }} />
                        Stazioni Treno
                    </Button>
                </td>
                <td>
                    <Button variant="info" size="s" onClick={async () => {
                        setTileModal("Stazioni Bus per Location " + descrizione);
                        setIdLocation(id);
                        var trasporti = await estraiLocationBusById(id);
                        let opts = [];
                        if (trasporti.length > 0) {
                            trasporti.forEach((element) => opts.push({ value: element.stazione, label: element.stazione }));
                        }
                        var myItemModal = { id_location: id, trasporto: opts };
                        setTrasportoLocationBus([]);
                        setItemLocationTrasporto(myItemModal);
                        setShowLocationTrasportoBus(true);
                    }}>
                        <PencilAltIcon variant="info" className="icon icon-xs" style={{ "cursor": "pointer" }} />
                        Stazioni Bus
                    </Button>
                </td>
                <td>
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                            <DotsHorizontalIcon className="icon icon-xs icon-dark" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="py-0">
                            <Dropdown.Item onClick={() => {
                                setTileModal("Modifica Location");
                                setEditModal(true);
                                var myItemModal = {};
                                myItemModal = mydata.filter(u => u.id === id)[0];
                                setItemModal(myItemModal);
                                setShowModal(true);
                            }}>
                                <PencilAltIcon className="icon icon-xs me-2" /> Modifica
                            </Dropdown.Item>
                            <Dropdown.Item onClick={async () => {
                                const result = await SwalWithBootstrapButtons.fire({
                                    icon: "error",
                                    title: "Conferma Eliminazione",
                                    text: "Sei sicuro di voler eliminare l'elemento con ID: " + id + " e Descrizione: " + descrizione,
                                    showCancelButton: true,
                                    confirmButtonText: "Si",
                                    cancelButtonText: "Annulla"
                                });

                                if (result.isConfirmed) {
                                    var myObj = {};
                                    myObj['action'] = 'delete_item';
                                    myObj['subject'] = tipoUtility;
                                    myObj['obj'] = {};
                                    myObj['obj']['id'] = id;
                                    await actionUtilityCamp(myObj);
                                    var refs = utilityRefresh;
                                    refs++;
                                    setRefreshUtility(refs);
                                    setUtilityAtom(undefined);
                                    setSearchValueUtility("");
                                    const confirmMessage = "L'elemento ID: " + id + " è stato eliminato con successo";

                                    await SwalWithBootstrapButtons.fire('Eliminazione completata', confirmMessage, 'success');
                                }
                            }}>
                                <TrashIcon className="icon icon-xs text-danger me-2" /> Rimuovi
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>
        );
    };

    return (
        <Card border="0" className="table-wrapper table-responsive shadow">
            <Card.Body>
                <div className="d-flex mb-3">
                    <Form.Select className="fmxw-200" disabled={disabledBulkMenu} value={bulkOption} onChange={bulkActionChange}>
                        <option value="delete_user">Cancella Location</option>
                    </Form.Select>
                    <Button variant="info" size="sm" className="ms-3" disabled={disabledBulkMenu} onClick={applyBulkAction}>
                        Applica
                    </Button>
                </div>
                <Table hover className="user-table align-items-left">
                    <thead>
                        <tr>
                            <th className="border-bottom">
                                <FormCheck type="checkbox" className="dashboard-check">
                                    <FormCheck.Input id="userCheckAll" checked={allSelected} onChange={selectAllUsers} />
                                    <FormCheck.Label htmlFor="userCheckAll" />
                                </FormCheck>
                            </th>
                            <th className="border-bottom">Nome Location</th>
                            <th className="border-bottom">Date
                                <Button className="mx-2" disabled={myUsers.length === 0}
                                    variant="info" size="xs" onClick={() => {
                                        setTileModal("Aggiungi Data a Location");
                                        setEditModalDate(false);
                                        var myItemModal = {};
                                        setItemModal({ 'descrizione': '' });
                                        setItemModalDate(myItemModal);
                                        setShowModalDate(true);
                                    }}>
                                    <ViewGridAddIcon variant="info" className="icon icon-xs" style={{ "cursor": "pointer" }} />
                                    Aggiungi Data
                                </Button></th>
                            <th className="border-bottom">Camp</th>
                            <th className="border-bottom">Treno</th>
                            <th className="border-bottom">Bus</th>
                            <th className="border-bottom">Azione</th>
                        </tr>
                    </thead>
                    <tbody className="border-0">
                        {mydata.map(u => <TableRow key={`user-${u.id}`} {...u} />)}
                    </tbody>
                </Table>
                <Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <Nav>
                        <Pagination className="mb-0">
                            <Pagination.Prev onClick={() => {
                                setCurrentPage(currentPage - 1);
                            }}
                                disabled={currentPage === 0}>
                                <ArrowLeftIcon className="icon icon-xs text-danger me-2" />
                            </Pagination.Prev>
                            <Pagination.Next onClick={() => {
                                setCurrentPage(currentPage + 1)
                            }}
                                disabled={
                                    (currentPage + 1) === (Math.ceil(quantiUsers() / rowsPerPage))
                                }>
                                <ArrowRightIcon className="icon icon-xs text-danger me-2" />
                            </Pagination.Next>
                        </Pagination>
                    </Nav>
                    <small className="fw-normal small mt-4 mt-lg-0">
                        Visualizzazione <b>{mydata.length + (currentPage * rowsPerPage)}</b> di <b>{quantiUsers()}</b> risultati
                    </small>
                </Card.Footer>
                <DetailsLocationModal
                    edit={edit}
                    title={titleModal}
                    onAddLocation={onAddItemLocation}
                    onUpdateLocation={onUpdateItemLocation}
                />
                <DetailsLocationDateModal
                    edit={editDate}
                    title={titleModal}
                    onAddLocationData={onAddItemLocationData}
                    onUpdateLocationData={onUpdateItemLocationData}
                />
                <DetailsLocationCampModal
                    edit={editCamp}
                    title={titleModal}
                    onUpdateLocationCamp={onUpdateItemLocationCamp}
                />
                <DetailsLocationTreno
                    edit={editCamp}
                    title={titleModal}
                    onUpdateLocationTreno={onUpdateLocationTreno}
                />
                <DetailsLocationBus
                    edit={editCamp}
                    title={titleModal}
                    onUpdateLocationBus={onUpdateLocationBus}
                />
            </Card.Body>
        </Card>

    );
};